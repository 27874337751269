// import React, { useState } from 'react';

// import Sidebar from '../Sidebar/Sidebar';
// import Add from './Add';
// import { useLocation } from 'react-router-dom';

// function Edit(props) {

//   const { state } = useLocation();

//   console.log("props" , state);

//   return (
//     <>
//       <div className="wrapper">
//         <Sidebar />

//         <Add data={state}/>

//       </div>

//     </>
//   )
// }

// export default Edit

import React, { useEffect, useState } from "react";

// import { CKEditor } from 'ckeditor4-react';
import CKEditor from "react-ckeditor-component";
import { ReactComponent as CloseSvg } from "../../Assets/Images/icons8-no.svg";

import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import swal from "sweetalert";
import Loader from "../Loader/Loader";
import Sidebar from "../Sidebar/Sidebar";
import {
  addTestimonials_API,
  dispatchLoadingStatus,
  editTestimonial_API,
  environmentalSectionedit_API,
  homepagesAdd_API,
  homepagesEdit_API,
  pagesEdit_API,
} from "../../API/Action/globalAction";

function Edit(props) {
  console.log("Datas",props);
  const { state } = useLocation();
  console.log("stateee", state);

  const dispatch = useDispatch({});
  let history = useNavigate();

  // let statelist = useSelector(state => state.globalReducer.getstatecityReg);

  let htmlin = `<p className="text-black " >Experience the ultimate luxury and comfort with our premium linen bedsheet. Crafted from 100% pure linen, it's the perfect addition to elevate your sleep experience. Experience the ultimate luxury and comfort with our premium linen bedsheet. </p>
  `;

  
  const [propsValue, setPropsValue] = useState();

  const [fname, setFname] = useState("");

  const [content, setContent] = useState("");

  const [role, setRole] = useState("");

  const [roleError, setRoleError] = useState(false);
  const [roleError2, setRoleError2] = useState("");

  const [linkError, setLinkError] = useState(false);
  const [linkError2, setLinkError2] = useState("");

  const [nameError, setNameError] = useState(false);
  const [nameError2, setNameError2] = useState("");

  const [contentError, setContentError] = useState(false);
  const [contentError2, setContentError2] = useState("");

  function handleMessage() {
    swal({
      text: "Are you sure you want to leave this page?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        window.location.href = window.location.origin + "/HomePageHighlightsList";
      } else {
        console.log("Cancel press");
      }
    });
  }

  useEffect(() => {
    if (state) {
    
      setFname(state.Title);
      setContent(state.Description);
      setRole(state.Xnumber);

    }
    
  }, [state]);

  let nameRe = /^[A-Za-z]( ?[A-Za-z] ?)*$/;

  function handleFname(e) {
    const result = /^[A-Za-z]( ?[A-Za-z] ?)*$/;

    if (e.target.value) {
      if (e.target.value === "" || result.test(e.target.value)) {
        setFname(e.target.value);
        setNameError2("");
        setNameError(false);
      } else {
        setNameError2("pls enter Name");
        setNameError(true);
      }
    } else {
      setFname("");
      setNameError(true);
      setNameError2("pls enter");
    }
  }

  function handleRole(e) {
    let data2 = e.target.value.trim() == "" ? "" : e.target.value;
    setRole(data2);
    
    if (data2) {
      setRoleError(false);
      setRoleError2("");
    } else {
      setRoleError(true);
      setRoleError2("Please enter Xnumber");
    }
  }

  const handleChange = (evt) => {
    let data2 = evt.target.value.trim() == "" ? "" : evt.target.value;
    setContent(data2);

    if (data2) {
      setContentError(false);
      setContentError2("");
    } else {
      setContentError(true);
      setContentError2("Please enter description");
    }
  };

  function handleSubmit() {
 
    if (fname) {
      if (fname.length >= 2) {
        // setNameError2("xa");
        if (!nameRe.test(fname)) {
          setNameError2("Please enter valid  name");
        } else {
          setNameError(false);
          setNameError2("");
        }
      } else {
        setNameError(true);
        setNameError2("Please enter valid  name");
      }
    } else {
      setNameError(true);
      setNameError2("Please enter  name");
    }

    if (content) {
      setContentError2("");
    } else {
      setContentError2("Please enter description");
    }

    if (role) {
      setRoleError2("")
      setRoleError(false)
    }
    else{
      setRoleError2("pls enter valid XNumber")
    }

    if (
      nameError == false &&
      contentError == false &&
      roleError == false &&
      fname &&
      content &&
      role
    ) {
      let formData = new FormData();

      formData.append("Description", content ? content : "");
      formData.append("Title", fname);
      formData.append("Xnumber", role); 

      let data = state.id
      
        console.log("dataaaaaaaaaaa", data);

        formData.append("environmental_id", data);

        dispatch(environmentalSectionedit_API(formData));
        dispatch(dispatchLoadingStatus(true));
      
    }
  }
  return (
    <>
      <div className="wrapper">
        <Loader />
        <Sidebar />

        <div id="content-page" className="content-page">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="iq-edit-list-data">
                  <div className="tab-content">
                    <div
                      className="tab-pane fade active show"
                      id="personal-information"
                      role="tabpanel"
                    >
                      <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between">
                          <div className="iq-header-title">
                            <h4 className="card-title">
                              {" "}
                              {state.id
                                ? state.id
                                  ? "Home Page Highlights Edit"
                                  : "Home Page Highlights Edit"
                                : "Home Page Highlights Edit"}
                            </h4>
                          </div>

                          <ul class="d-flex mt-3">
                            <li class="breadcrumb-item">
                              <a href="/HomePageHighlightsList">
                              Home Page Highlights List
                              </a>
                            </li>
                            <li
                              class="breadcrumb-item active"
                              aria-current="page"
                            >
                              {state.id
                                ? state.id
                                  ? "Home Page Highlights Edit"
                                  : "Home Page Highlights Edit"
                                : "Home Page Highlights Edit"}
                            </li>
                          </ul>
                        </div>
                        <div className="iq-card-body">
                          <div className=" row">
                            <div className="form-group col-sm-6">
                              <label htmlFor="fname">Title:</label>
                              <input
                                type="text"
                                className="form-control"
                                value={fname}
                                maxLength={100}
                                onChange={(e) => handleFname(e)}
                              />
                              <p className="errorr">{nameError2}</p>
                            </div>

                            <div className="form-group col-sm-6">
                              <label htmlFor="fname">Xnumber:</label>
                              <input
                                type="text"
                                className="form-control"
                                value={role}
                                onChange={(e) => handleRole(e)}
                              />
                              <p className="errorr">{roleError2}</p>
                            </div>

                            <div class="form-group col-sm-12">
                              <label>Description:</label>
                              <textarea
                                class="form-control"
                                rows="5"
                                value={content}
                                maxLength={100}
                                onChange={(e) => handleChange(e)}
                                style={{ lineHeight: "22px" }}
                              ></textarea>
                              <p className="errorr">{contentError2}</p>
                            </div>
                          </div>
                          <button
                            className="btn btn-primary mr-2"
                            onClick={() => handleSubmit()}
                          >
                            Submit
                          </button>
                          <button
                            className="btn iq-bg-danger"
                            onClick={() => handleMessage()}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Edit;
