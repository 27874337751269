import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader/Loader";
import {
  dispatchLoadingStatus,
  forgotPasswordOtpSendInEmail_API,
} from "../../API/Action/globalAction";

function ForgetPassword() {
  const [email, setEmail] = useState("");

  const [emailError, setEmailError] = useState(false);

  const dispatch = useDispatch({});

  let history = useNavigate();

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const handleEmail = (e) => {
    setEmail(e.target.value.trim() == "" ? "" : e.target.value);
    if (e.target.value) {
      if (!isValidEmail(email)) {
        setEmailError(<p className="errorr">Email is invalid</p>);
      } else {
        setEmailError("");
      }
    }
  };

  const Otp = (e) => {
    if (email == "") {
      setEmailError(<p className="errorr">Email is required</p>);
    } else if (!isValidEmail(email)) {
      setEmailError(<p className="errorr">Email is invalid</p>);
    } else {
      setEmailError("");
      let formData = new FormData();
      formData.append("email", email);
      localStorage.setItem("sendEmail", email);
      localStorage.setItem("emailAdmin", email);

      dispatch(dispatchLoadingStatus(true));
      dispatch(forgotPasswordOtpSendInEmail_API(formData));
    }
  };

  const forgotPasswordOtpSendInEmailReducer = useSelector(
    (state) => state.globalReducer.forgotPasswordOtpSendInEmailReducer
  );
  console.log("myres", forgotPasswordOtpSendInEmailReducer);

  useEffect(() => {
    localStorage.removeItem("resendValue");
    
    localStorage.removeItem("otp_id");
    localStorage.removeItem("admin_id");
    localStorage.removeItem("user_id");
  }, []);

  useEffect(() => {
    if (forgotPasswordOtpSendInEmailReducer) {
      
      localStorage.setItem(
        "user_id",
        forgotPasswordOtpSendInEmailReducer
          ? forgotPasswordOtpSendInEmailReducer.user_id
          : ""
      );

      history("/Otp");
    }
  }, [forgotPasswordOtpSendInEmailReducer]);

  return (
    <section className="main-forget-pw sign-in-page bg-white">
      <div className="login-container">
        <Loader />

        <form class="login-form"   onSubmit={(e)=>e.preventDefault()}>
          <h1>Forget Password</h1>
          <p>Please Fill the details to forget your password</p>
          <div className="input-group">
            <label htmlFor="username" className="label">
              Email ID
            </label>
            <input
              type="text"                
              id="email"
              placeholder=" Email"
              value={email}
              onChange={handleEmail}
            />
            {emailError}
          </div>

          

          <button type="submit"  onClick={Otp} class="btn btn-primary float-right">Submit</button>

          <div class="bottom-text">
          <p>Don't have an account? <a href="/Login">Sign Up</a></p>

        </div>
        </form>
      </div>
    </section>
  );
}

export default ForgetPassword;
