import React, { useEffect, useState } from 'react';
import Sidebar from '../Sidebar/Sidebar';
// import { CKEditor } from 'ckeditor4-react';
import CKEditor from 'react-ckeditor-component';
import { ReactComponent as CloseSvg } from '../../Assets/Images/icons8-no.svg';
import { dispatchLoadingStatus, pagesAdd_API, pagesEdit_API, pagesSequencelist_API } from '../../API/Action/globalAction';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom"
import Loader from '../Loader/Loader';
import swal from 'sweetalert';



function Add(props) {
  const [fname, setFname] = useState('');
  const [content, setContent] = useState("");

  const [nameError, setNameError] = useState(false);
  const [nameError2, setNameError2] = useState("");

  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);

  const [selectedFileError, setSelectedFileError] = useState(false);
  const [selectedFileError2, setSelectedFileError2] = useState("");

  const [contentError, setContentError] = useState(false);
  const [contentError2, setContentError2] = useState("");

   useEffect(() => {

    if (fname.length > 0 || fname == "") {
      let formData = new FormData();

      formData.append('page_title', fname);
      dispatch(pagesSequencelist_API(formData));

    }

  }, [fname])
  let pages_sequence_listReducer = useSelector(state => state.globalReducer.pages_sequence_listReducer);

  const [sequnceList, setSequnceList] = useState();

  useEffect(() => {
    if (pages_sequence_listReducer) {
      debugger
      setSequnceList(pages_sequence_listReducer.data);


    }
    else {
      setSequnceList([]);

    }

  }, [pages_sequence_listReducer])

  let phoneRe = /^[1-9\b]/;

  const [sequnce, setSequnce] = useState("");
  const [sequnceError2, setSequnceError2] = useState("");
  const [sequnceError, setSequnceError] = useState("");

  function handleSequnce(e) {
    let data = e.target.value;
    let data2 = e.target.value.trim() == "" ? "" : e.target.value;

    setSequnce(phoneRe.test(data2) ? data2 : "");

    if (data2) {
      setSequnceError(false);
      setSequnceError2("");

      if (!phoneRe.test(data)) {
        setSequnceError(true);
        setSequnceError2("Please enter valid sequence ");


      }
      else {
        setSequnceError(false);
        setSequnceError2("");


      }

    }
    else {
      setSequnceError(true);
      setSequnceError2("Please select sequence");

    }
  }
  const [propsValue, setPropsValue] = useState();


  const dispatch = useDispatch({

  });
  let history = useNavigate();

  // let statelist = useSelector(state => state.globalReducer.getstatecityReg);

  let htmlin = `<div class="privacy-inner">
  <h1 class="title-h1">Terms & Conditions</h1>
  <p>Last Updated: December 28, 2022</p>
  <p>Experience the ultimate luxury and comfort with our premium linen bedsheet. Crafted from 100%
    pure linen, it's the perfect addition to elevate your sleep experience.Experience the
    ultimate luxury and comfort with our premium linen bedsheet. Experience the ultimate luxury
    and comfort with our premium linen bedsheet. Crafted from 100% pure linen, it's the perfect
    addition to elevate your sleep experience.Experience the ultimate luxury and comfort with
    our premium linen bedsheet. </p>
  <p>Experience the ultimate luxury and comfort with our premium linen bedsheet. Crafted from 100%
    pure linen, it's the perfect addition to elevate your sleep experience.Experience the
    ultimate luxury and comfort with our premium linen bedsheet. Experience the ultimate luxury
    and comfort with our premium linen bedsheet. Crafted from 100% pure linen, it's the perfect
    addition to elevate your sleep experience.Experience the ultimate luxury and comfort with
    our premium linen bedsheet. </p>
</div>
<div class="privacy-inner py-3">
  <h3 class="title-h1">Cookies and Similar Technologies</h3>
  <p>Experience the ultimate luxury and comfort with our premium linen bedsheet. Crafted from 100%
    pure linen, it's the perfect addition to elevate your sleep experience.Experience the
    ultimate luxury and comfort with our premium linen bedsheet. Experience the ultimate luxury
    and comfort with our premium linen bedsheet. Crafted from 100% pure linen, it's the perfect
    addition to elevate your sleep experience.Experience the ultimate luxury and comfort with
    our premium linen bedsheet. </p>
</div>

<div class="privacy-inner py-3">
  <h3 class="title-h1">Order Placement and Other Ways You May Provide Personal Information</h3>
  <p>Experience the ultimate luxury and comfort with our premium linen bedsheet. Crafted from 100%
    pure linen, it's the perfect addition to elevate your sleep experience.Experience the
    ultimate luxury and comfort with our premium linen bedsheet. Experience the ultimate luxury
    and comfort with our premium linen bedsheet. Crafted from 100% pure linen, it's the perfect
    addition to elevate your sleep experience.Experience the ultimate luxury and comfort with
    our premium linen bedsheet. </p>
</div>

<div class="privacy-inner py-3">
  <h3 class="title-h1">CONSENT</h3>
  <p>Experience the ultimate luxury and comfort with our premium linen bedsheet. Crafted from 100%
    pure linen, it's the perfect addition to elevate your sleep experience.Experience the
    ultimate luxury and comfort with our premium linen bedsheet. Experience the ultimate luxury
    and comfort with our premium linen bedsheet. Crafted from 100% pure linen, it's the perfect
    addition to elevate your sleep experience.Experience the ultimate luxury and comfort with
    our premium linen bedsheet. </p>
</div>

<div class="privacy-inner py-3">
  <h3 class="title-h1">HOW WE KEEP YOUR INFORMATION</h3>
  <p>Experience the ultimate luxury and comfort with our premium linen bedsheet. Crafted from 100%
    pure linen, it's the perfect addition to elevate your sleep experience.Experience the
    ultimate luxury and comfort with our premium linen bedsheet. Experience the ultimate luxury
    and comfort with our premium linen bedsheet. Crafted from 100% pure linen, it's the perfect
    addition to elevate your sleep experience.Experience the ultimate luxury and comfort with
    our premium linen bedsheet. </p>
</div>

<div class="privacy-inner py-3">
  <h3 class="title-h1">HOW WE KEEP YOUR INFORMATION SAFE</h3>
  <p>Experience the ultimate luxury and comfort with our premium linen bedsheet. Crafted from 100%
    pure linen, it's the perfect addition to elevate your sleep experience.Experience the
    ultimate luxury and comfort with our premium linen bedsheet. Experience the ultimate luxury
    and comfort with our premium linen bedsheet. Crafted from 100% pure linen, it's the perfect
    addition to elevate your sleep experience.Experience the ultimate luxury and comfort with
    our premium linen bedsheet. </p>
</div> `;


  

  function handleMessage() {
        swal({
            text: "Are you sure you want to leave this page?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {

                  window.location.href = window.location.origin + "/PagesList";

                } else {
                    console.log('Cancel press')


                }
            });

}


  useEffect(() => {
    if (props.data) {
      setPropsValue(props.data);
      setFname(props.data.page_title);
      setSequnce(props.data.sequence_No);
      setSelectedFile(props.data.image);
      setContent(props.data.description);
    }
  }, [props])
  const handleChange = (evt) => {
    const newContent = evt.editor.getData();

    setContent(newContent);

    if (newContent) {
      setContentError(false);
      setContentError2("");

    }
    else {
      setContentError(true);
      setContentError2("Please enter description");


    }
  };


  const handleFileChange = (event) => {
    const file = event.target.files[0];
   
    setSelectedFile2(null);

    if (file) {
      if (
        event.target.files[0].name.toLowerCase().includes("jpg") ||
        event.target.files[0].name.toLowerCase().includes("png") ||
        event.target.files[0].name.toLowerCase().includes("jpeg")) {

          setSelectedFile(file);
          setSelectedFileError2("");
      }
      else {
        setSelectedFileError2("The image must be a file of type: jpg, png, jpeg");

      }
    }
    else {
      setSelectedFileError2("");
    }


  };


  const handleRemoveFile = () => {
    document.getElementById("image").value = "";

    setSelectedFile(null);
    setSelectedFile2(null);
  };

  let nameRe = /^[a-zA-Z\s]*$/;

  function handleFname(e) {
    let data2 = e.trim() == "" ? "" : e;

    if (nameRe.test(data2)) {
      setFname(data2);
  }

    if (e) {

      if (e.length >= 2) {
        setNameError(false);
        setNameError2("");
        if (!nameRe.test(e)) {
          setNameError(true);
          setNameError2("Please enter valid title");

        }
        else {
          setNameError(false);
          setNameError2("");

        }
      }
      else {
        setNameError(true);
        setNameError2("Please enter valid title");

      }
    }
    else {
      setNameError(true);
      setNameError2("Please enter title");


    }

  }


  function handleSubmit() {

    if (sequnce) {

      setSequnceError(false);
      setSequnceError2("");
      if (!phoneRe.test(sequnce)) {
        setSequnceError(true);
        setSequnceError2("Please enter valid sequence ");


      }
      else {
        setSequnceError(false);
        setSequnceError2("");


      }

    }
    else {
      setSequnceError(true);
      setSequnceError2("Please enter sequence");

    }

    if (fname) {

      if (fname.length >= 2) {
        setNameError2("");
        if (!nameRe.test(fname)) {
          setNameError2("Please enter valid title name");

        }
        else {
          setNameError2("");

        }
      }
      else {
        setNameError2("Please enter title name");

      }
    }
    else {
      setNameError2("Please enter title");


    }
    if (content) {
      setContentError2("");

    }
    else {
      setContentError2("Please enter description");
    }

    if (selectedFile) {
      setSelectedFileError2("");

    }
    else {
      setSelectedFileError2("Please select image");


    }

    console.log("send image" , selectedFile&& selectedFile);




    if (nameError == false && selectedFileError == false && contentError == false && sequnceError == false && sequnce && fname && content && selectedFile) {
      
      
      let formData = new FormData();
      formData.append('page_image', selectedFile ? selectedFile : "");
      formData.append('description', content);
      formData.append('page_title', fname);

      formData.append('sequence_No', sequnce);


      let data = propsValue ? propsValue.pages_id ? propsValue.pages_id :  "" : ""

      if(data){
        dispatch(pagesEdit_API(formData));
        dispatch(dispatchLoadingStatus(true));

      }

      else{
        dispatch(pagesAdd_API(formData));
      dispatch(dispatchLoadingStatus(true));
      }

      


    }
  }
  return (
    <>

    {/* added by parth roka*/}
      <div className="wrapper">
        <Loader />
        <Sidebar />


        <div id="content-page" className="content-page">
          <div className="container-fluid">
            <div className="row">

              <div className="col-lg-12">
                <div className="iq-edit-list-data">
                  <div className="tab-content">
                    <div className="tab-pane fade active show" id="personal-information" role="tabpanel">
                      <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between">
                          <div className="iq-header-title">
                            <h4 className="card-title"> {propsValue ? propsValue.pages_id ? "Pages Edit" : "Pages Add" : "Pages Add"}</h4>
                          </div>

                          <ul class="d-flex mt-3">
                        <li class="breadcrumb-item"><a href="/PagesList">Pages List</a></li>
                        <li class="breadcrumb-item active" aria-current="page">{propsValue ? propsValue.pages_id ? "Pages Edit" : "Pages Add" : "Pages Add"}</li>
                      </ul>
                        </div>
                        <div className="iq-card-body">

                          <div className=" row">
                            <div className="form-group col-sm-6">
                              <label htmlFor="fname">Title:</label>
                              <input type="text" className="form-control" maxLength={100} value={fname}  onChange={(e) => handleFname(e.target.value)} />
                              <p className='errorr'>{nameError2}</p>
                            </div>

                            {/* <div className="form-group col-sm-6">
                                <label htmlFor="fname">Image</label>
                                <input type="file" className="form-control" onChange={handleFileChange} />
                              </div> */}

                            {/* <div>
                                    {selectedFile && (
                                      <div>
                                        <p>Selected File: {selectedFile.name}</p>
                                        <img
                                          src={URL.createObjectURL(selectedFile)}
                                          alt="Selected Preview"
                                          style={{ maxWidth: '100%', maxHeight: '200px' }}
                                        />
                                        <button onClick={handleRemoveFile}>Remove</button>
                                      </div>
                                    )}
                                  </div> */}


                            <div className="form-group col-sm-6">
                              <div className="wrap">
                                <label className="form-label">Upload Image:</label>
                                <form >
                                  <div className="file">
                                    <div className="file__input" id="file__input">
                                      <input className="file__input--file point"
                                        type="file"
                                        id="image"
                                        onChange={handleFileChange}
                                      />
                                      <label className="file__input--label" accept='.png, .jpg .jpeg' htmlFor="customFile" data-text-btn="Choose Files">Add image:</label>
                                    </div>
                                  </div>

                                </form>
                                <p className='errorr'>{selectedFileError2}</p>


                                {selectedFile && (
                                  <div className="choosed-files" style={{position:'relative'}}>
                                    {/* <p>Selected File: {selectedFile.name}</p> */}
                                    <img
                                      src={typeof selectedFile === 'string' ? selectedFile :  URL.createObjectURL(selectedFile)  }
                                      alt="Selected Preview"
                                      style={{ maxWidth: '100%', maxHeight: '200px' }}
                                    />

                                    <CloseSvg className='point' width={18} height={18} onClick={() => handleRemoveFile()}  style={{zIndex:'99', position:'absolute', right:'8px', top:'8px'}}/>

                                  </div>
                                )}

                              </div>

                            </div>

                            <div className="form-group col-sm-6">
                              <label htmlFor="fname">Sequence:</label>
                              <input type="text" className="form-control" value={sequnce} onChange={(e) => handleSequnce(e)} maxLength={2} />
                            
                              <p className='errorr'>{sequnceError2}</p>
                            </div>

                              <div className="form-group col-sm-6">
                                <label className="form-label">Sequence :</label>
                                <div className='d-flex Sequence-main-div'>
                              { 
                                sequnceList && sequnceList.length > 0 ? sequnceList.map((i) =>

                                  <p> {i.sequence_No}</p>
                                ) : "No Sequence "
                              }
                              </div>
                            </div>


                            <div className="form-group col-sm-12">
                              <label>Long Description:</label>
                              <CKEditor
                                activeclassName="p10"
                                content={content}
                                events={{
                                  change: handleChange,
                                }}
                                config={{ allowedContent: true }}
                              />


                              <p className='errorr'>{contentError2}</p>


                              {/* <textarea className="form-control" name="address" rows="5" value={longDes} onChange={(e) => {console.log("textarea:-" , e.target.value);  setLongDes(e.target.value) }} style={{lineHeight : '22px'}}/> */}



                            </div>
                          </div>
                          <button className="btn btn-primary mr-2" onClick={() => handleSubmit()} >Submit</button>
                          <button className="btn iq-bg-danger" onClick={() => handleMessage()}>Cancel</button>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>

    </>
  )
}

export default Add