import React, { useEffect, useState } from "react";

// import { CKEditor } from 'ckeditor4-react';
import CKEditor from "react-ckeditor-component";
// import { ReactComponent as CloseSvg } from "../../Assets/Images/icons8-no.svg";
import { ReactComponent as CloseSvg } from "../../../Assets/Images/icons8-no.svg";
// import {
//   dispatchLoadingStatus,
//   pagesAdd_API,
//   productAdd_API,
//   getAllCategoriesTitle_API,
//   getfabricCategory_API,
//   getDeals_API,
//   getPriceRange_API,
//   productEdit_API,
// } from "../../API/Action/globalAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import swal from "sweetalert";
import Select from "react-select";
import Loader from "../../Loader/Loader";
import Sidebar from "../../Sidebar/Sidebar";
import {
  dispatchLoadingStatus,
  fabricCategoryEdit_API,
  febricCategoryAdd_API,
  getAllCategoriesTitle_API,
  getDeals_API,
  getPriceRange_API,
  getfabricCategory_API,
  priceRangeAdd_API,
  priceRangeEdit_API,
  productAdd_API,
  productEdit_API,
} from "../../../API/Action/globalAction";
import { getpriceRangeListReducer } from "../../../OtherFiles/Constant";

function PriceRangeAdd(props) {
  const [propsValue, setPropsValue] = useState();

  const dispatch = useDispatch({});
  let history = useNavigate();

  const [allCategory, setAllCategory] = useState();
  const [febricCategory, setFebricCategory] = useState();
  const [dealCategory, setDealCategory] = useState();
  const [rangeCategory, setRangeCategory] = useState();

  const [productName, setProductName] = useState();
  const [price, setPrice] = useState();
  const [link, setLink] = useState();
  const [sendFebric, setSendFebric] = useState();
  const [sendDeals, setSendDeals] = useState();
  const [sendRange, setSendRange] = useState();
  const [sendDes, setSendDes] = useState();

  const [fname, setFname] = useState("");
  const [content, setContent] = useState("");
  const [profuctError, setProductError] = useState(false);
  const [productError2, setProductError2] = useState("");

  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);

  const [selectedFileError, setSelectedFileError] = useState(false);
  const [selectedFileError2, setSelectedFileError2] = useState("");

  const [priceError, setPriceError] = useState(false);
  const [priceError2, setPriceError2] = useState("");

  const [linkError, setLinkError] = useState(false);
  const [linkError2, setLinkError2] = useState("");

  const [febricError, setFebricError] = useState(false);
  const [febricError2, setFebricError2] = useState("");

  const [dealError, setDealError] = useState(false);
  const [dealError2, setDealError2] = useState("");

  const [rangeError, setRangeError] = useState(false);
  const [rangeError2, setRangeError2] = useState("");

  const [desError, setDesError] = useState(false);
  const [desError2, setDesError2] = useState("");

  let getAllCategoriesTitleReducer = useSelector(
    (state) => state.globalReducer.getAllCategoriesTitleReducer
  );
  let getfabricCategoryReducer = useSelector(
    (state) => state.globalReducer.getfabricCategoryReducer
  );
  let getDealsReducer = useSelector(
    (state) => state.globalReducer.getDealsReducer
  );

  let priceRangeAddReducer = useSelector(
    (state) => state.globalReducer.priceRangeAddReducer
  );
  // let data = allCategory ? allCategory : [];

  // let data2 = febricCategory ? febricCategory : [];

  // const indexOfTypeOfFabric = data.findIndex(
  //   (item) => item.categoryTitle === "Type of Fabric"
  // );

  // if (indexOfTypeOfFabric !== -1) {
  //   data[indexOfTypeOfFabric].data2 = data2;
  // }

  // console.log(data);

  // useEffect(() => {
  //   if (getAllCategoriesTitleReducer) {
  //     if (getAllCategoriesTitleReducer.success == true) {
  //       setAllCategory(getAllCategoriesTitleReducer.data);
  //     } else {
  //       setAllCategory([]);
  //     }
  //   }
  // }, [getAllCategoriesTitleReducer]);

  // useEffect(() => {
  //   if (getfabricCategoryReducer) {
  //     if (getfabricCategoryReducer.errorcode == 0) {
  //       setFebricCategory(getfabricCategoryReducer.data);
  //     } else {
  //       setFebricCategory([]);
  //     }
  //   }
  // }, [getfabricCategoryReducer]);

  useEffect(() => {
    if (getpriceRangeListReducer) {
      if (getpriceRangeListReducer.errorcode == 0) {
        setRangeCategory(getpriceRangeListReducer.data);
      } else {
        setRangeCategory([]);
      }
    }
  }, [getpriceRangeListReducer]);

  // useEffect(() => {
  //   if (febricCategoryListReducer) {
  //     if (getDealsReducer.errorcode == 0) {
  //       setDealCategory(febricCategoryListReducer.data);
  //     } else {
  //       setDealCategory([]);
  //     }
  //   }
  // }, [febricCategoryListReducer]);

  function handleMessage() {
    swal({
      text: "Are you sure you want to leave this page?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        window.location.href = window.location.origin + "/PriceRangeList";
      }
    });
  }

  // useEffect(() => {
  //   // dispatch(getAllCategoriesTitle_API());
  //   dispatch(priceRangeAdd_API());
  // }, []);

  useEffect(() => {
    if (props.data) {
      console.log("hii", props.data);
      setPropsValue(props.data);
      setProductName(props.data.range_of_price);
      // setFname(props.data.name);
      // setSelectedFile(props.data.image);
      // setSendDes(props.data.description);
      // setDepartment(props.data.department);
      // setRole(props.data.role)
    }
  }, [props]);

  const handleRemoveFile = () => {
    document.getElementById("image").value = "";

    setSelectedFile(null);
    setSelectedFile2(null);
  };

  let nameRe = /^[a-zA-Z\s]*$/;
  let phoneRe = /\$\d+/;
  function handleProductName(e) {
    let data = e.target.value;
    setProductName(e.target.value.trim() == "" ? "" : e.target.value);

    if (data) {
      // setProductError(false);

      if (data.length >= 2) {
        setProductError(false);
    
      } else {
        setProductError(true);
      }
    } else {
      setProductError(true);
    }
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    setSelectedFile2(null);

    if (file) {
      if (
        event.target.files[0].name.toLowerCase().includes("jpg") ||
        event.target.files[0].name.toLowerCase().includes("png") ||
        event.target.files[0].name.toLowerCase().includes("jpeg")
      ) {
        setSelectedFile(file);
        setSelectedFileError2("");
      } else {
        setSelectedFileError2(
          "The image must be a file of type: jpg, png, jpeg"
        );
      }
    } else {
      setSelectedFileError2("");
    }
  };

  function handleDes(e) {
    let data = e.target.value;
    setSendDes(e.target.value.trim() == "" ? "" : e.target.value);

    if (data) {
      setDesError(false);
    } else {
      setDesError(true);
    }
  }

  function handleSubmit() {
    if (productName) {
      if (productName.length >= 2) {
        setProductError2("");
  
      } else {
        setProductError2("Please enter valid price");
      }
    } else {
      setProductError2("Please enter price");
    }

    if (!profuctError && productName) {
      let formData = new FormData();

      formData.append("range_of_price", productName);
      // formData.append("category_image", selectedFile ? selectedFile : "");
      // formData.append("description", sendDes);

      if (propsValue) {
        if (propsValue.pricerange_id) {
          let formData2 = new FormData();

          formData2.append("range_of_price", productName);
          // formData2.append("category_image", selectedFile ? selectedFile : "");

          formData2.append(
            "pricerange_id",
            props ? props.data.pricerange_id : ""
          );
          // formData2.append("description", sendDes);
          dispatch(priceRangeEdit_API(formData2));

          dispatch(dispatchLoadingStatus(true));
        } else {
          dispatch(priceRangeAdd_API(formData));
          dispatch(dispatchLoadingStatus(true));
        }
      } else {
        dispatch(priceRangeAdd_API(formData));
        dispatch(dispatchLoadingStatus(true));
      }
    }
  }
  return (
    <>
      <div className="wrapper">
        <Loader />
        <Sidebar />

        <div id="content-page" className="content-page">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="iq-edit-list-data">
                  <div className="tab-content">
                    <div
                      className="tab-pane fade active show"
                      id="personal-information"
                      role="tabpanel"
                    >
                      <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between">
                          <div className="iq-header-title">
                            <h4 className="card-title">
                              {" "}
                              {propsValue
                                ? propsValue.pricerange_id
                                  ? "Price Range Edit"
                                  : "Price Range Add"
                                : "Price Range Add"}
                            </h4>
                          </div>

                          <ul class="d-flex mt-3">
                            <li class="breadcrumb-item">
                              <a href="/PriceRangeList">Price Range List</a>
                            </li>
                            <li
                              class="breadcrumb-item active"
                              aria-current="page"
                            >
                              {propsValue
                                ? propsValue.pricerange_id
                                  ? "Price Range Edit"
                                  : "Price Range Add"
                                : "Price Range Add"}
                            </li>
                          </ul>
                        </div>
                        <div className="iq-card-body">
                          <div className="row">
                            <div className="form-group col-sm-6">
                              <label htmlFor="fname">Price:</label>
                              <input
                                type="text"
                                className="form-control"
                                value={productName}
                                onChange={(e) => handleProductName(e)}
                              />
                              <p className="errorr">{productError2}</p>
                            </div>
                          </div>
                          <button
                            className="btn btn-primary mr-2"
                            onClick={() => handleSubmit()}
                          >
                            Submit
                          </button>
                          <button
                            className="btn iq-bg-danger"
                            onClick={() => handleMessage()}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PriceRangeAdd;
