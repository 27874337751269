import React, { useState, useEffect } from 'react';
import Sidebar from '../Sidebar/Sidebar';
import { InqueryList_API, dispatchLoadingStatus, downloadInquiries_API, pagesList_API, pagesStatus_API } from '../../API/Action/globalAction';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import swal from 'sweetalert';
import Loader from '../Loader/Loader';
import moment from "moment";
import Select from 'react-select';




function List() {
  let InqueryListReducer = useSelector(state => state.globalReducer.InqueryListReducer);

  let range = [{ label: "5", value: "5" }, { label: "10", value: "10" }, { label: "25", value: "25" }, { label: "50", value: "50" }, { label: "100", value: "100" },];
  const [sendRange, setSendRange] = useState({ label: "5", value: "5" });

  let pagesStatusReducer = useSelector(state => state.globalReducer.pagesStatusReducer);
  let token = localStorage.getItem("token");
  const [page, setpage] = useState(0);
  const [indexCh, setIndexCh] = useState(0);
  const totalCount = InqueryListReducer ? InqueryListReducer.count : "";
  const [itemsPerPage, setItemsPerPage] = useState(5);


  // const itemsPerPage = sendRange && sendRange.length > 0 ?  sendRange[0].value : "";
  const totalPages = Math.ceil(totalCount / itemsPerPage);

  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {

    dispatch(dispatchLoadingStatus(true))
    let formData = new FormData();

    formData.append("start", 0);
    formData.append("limit", sendRange && sendRange.value);
    dispatch(InqueryList_API(formData))


  }, [])

  function handleRange(e) {
    let data = e.value;
    setSendRange(e);

    if (data) {
      Apicall(0, data);
      setItemsPerPage(data)
      debugger
      setCurrentPage(1);

    }


  }

  const handlePageChange = (page, count) => {
    setCurrentPage(page);

    Apicall((page - 1) * itemsPerPage, sendRange && sendRange.value)


    // You can implement fetching data for the new page here if needed
  };

  const renderPagination = () => {
    const pagesToShow = 3;
    const startPage = Math.max(1, currentPage - Math.floor(pagesToShow / 2));
    const endPage = Math.min(totalPages, startPage + pagesToShow - 1);
    const paginationItems = [];
    for (let i = startPage; i <= endPage; i++) {
      paginationItems.push(
        <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
          <a className="page-link point" onClick={() => handlePageChange(i)}>
            {i}
          </a>
        </li>
      );
    }

    return paginationItems.length > 0 ? paginationItems : <li className="page-item disabled"><span className="page-link">No pages</span></li>;
  };




  let history = useNavigate();
  const dispatch = useDispatch({

  });




  function Apicall(start, data) {
    let formData = new FormData();

    formData.append("start", start);
    formData.append("limit", data ? data : "5");
    debugger

    dispatch(dispatchLoadingStatus(true))
    dispatch(InqueryList_API(formData));


  }

  function handleDownload() {
    dispatch(dispatchLoadingStatus(true))
    dispatch(downloadInquiries_API());


  }





  return (
    <>
      <div className="wrapper">

        <Sidebar />
        <Loader />

        <div id="content-page" className="content-page">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="iq-card">
                  <div className="iq-card-header d-flex justify-content-between">
                    <div className="iq-header-title">
                      <h4 className="card-title">Inquiry List</h4>
                    </div>
                    {/* <ul class="d-flex mt-3">
                        <li class="breadcrumb-item"><a href="#">Home</a></li>
                        <li class="breadcrumb-item active" aria-current="page">User List</li>
                      </ul> */}
                  </div>
                  <div className="iq-card-body">
                    <div className="table-responsive">
                      <div className="row justify-content-between">
                        <div className="col-sm-12">
                          <div className="user-list-files d-flex float-right">
                            <span className="table-add float-right mb-3 mr-2">
                              <button className="btn w-100 btn-primary" onClick={() => handleDownload()}>

                                Download File
                              </button>

                            </span>
                          </div>
                        </div>

                      </div>

                      <div className='col-md-4'>
                        <div className="form-group mb-0">
                          <label htmlFor="fname">Showing Inquiries </label>
                          <Select className='w-100'
                            options={range ? range.map((dataItem) => ({
                              value: dataItem.value,
                              label: dataItem.label,
                            })) : [{ label: "Select", value: "" }]}
                            placeholder="Select"
                            isClearable={false}
                            value={sendRange}
                            onChange={(e) => handleRange(e)}
                          />
                        </div>
                      </div>
                      <table id="user-list-table" className="table table-striped table-bordered mt-4" role="grid" aria-describedby="user-list-page-info">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Message</th>

                            <th>Create at</th>
                          </tr>
                        </thead>
                        <tbody>

                          {InqueryListReducer && InqueryListReducer.data.length > 0 ? InqueryListReducer.data.map((i) =>
                            <tr>
                              <td>{i.full_name}</td>
                              <td>{i.email}</td>
                              <td>{i.message}</td>
                              <td>
                                {i.created_at
                                  ? moment(i.created_at).format("MM/DD/YYYY")
                                  : "-"}
                              </td>


                            </tr>

                          ) : <tr className='bg-white border-0'> <td colSpan={4} className='border-0'> <div className='col-md-12 w-100 mt-4'><p className='text-center'>No Data Found</p> </div></td></tr>}


                        </tbody>
                      </table>
                    </div>

                    {/* {(() => {
                                const pages = [];

                                for (let i = 1; i < (InqueryListReducer.count / 20) + 1; i++) {
                                  pages.push(<li className={page == i ? "page-item active" : "page-item"} onClick={() => { Apicall((i - 1) * 20); setpage(i); setIndexCh(indexCh + 1) }}><a className="page-link" >{i}  </a>  </li>)
                                }

                                return pages;
                              })()} */}
                    {/* {InqueryListReducer ? InqueryListReducer.count > 0 ? */}

                    <div className="row justify-content-between mt-3">
                      <div id="user-list-page-info" className="col-md-6">
                        {
                          InqueryListReducer && InqueryListReducer.count ?

                            <span>Showing {page <= 1 ? 1 : (page - 1) * 5 + 1} to {page * 5 <= InqueryListReducer ? InqueryListReducer.count : "" ? page * 5 : InqueryListReducer ? InqueryListReducer.count : ""}  of {InqueryListReducer ? InqueryListReducer.count : ""} entries</span>
                            : ""}
                      </div>
                      {/* <div className="col-md-6">
                          <nav aria-label="Page navigation example">
                            <ul className="pagination justify-content-end mb-0">
                              <li className="page-item disabled">
                                <a className="page-link" href="#" tabindex="-1" aria-disabled="true">Previous</a>
                              </li>
                          
                              <li className={"page-item active"} ><a className="page-link" >1  </a>  </li>
                             

                              <li className="page-item">
                                <a className="page-link" href="#">Next</a>
                              </li>
                            </ul>
                          </nav>
                        </div> */}

                      {/* {InqueryListReducer && InqueryListReducer.length > 5 ? */}
                      <div className="col-md-6">
                        <nav aria-label="Page navigation example">
                          <ul className="pagination justify-content-end mb-0">
                            <li className={`page-item point ${currentPage === 1 ? 'disabled' : ''}`}>
                              <a className="page-link" onClick={() => handlePageChange(currentPage - 1, "less")} tabIndex="-1" aria-disabled="true">
                                Previous
                              </a>
                            </li>

                            {renderPagination()}

                            <li className={`page-item point ${currentPage === totalPages ? 'disabled' : ''}`}>
                              <a className="page-link" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                                Next
                              </a>
                            </li>
                          </ul>
                        </nav>
                      </div>

                      {/* : ""} */}


                    </div>
                    {/* : null : null} */}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>



    </>
  )
}

export default List