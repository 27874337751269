import React, { useState, useRef, useEffect } from "react";
import Loader from "../Loader/Loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "react-datepicker/dist/react-datepicker.css";
// import { dispatchLoadingStatus, OtpVerifyForEmailAPI, sendOtpInEmailAPI } from '../API/Action/globalAction'
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  OtpMatch_API,
  dispatchLoadingStatus,
  forgotPasswordOtpSendInEmail_API,
} from "../../API/Action/globalAction";

function Otp() {
  const [counter, setTimer] = useState("00:00");
  const [One, setOne] = useState("");
  const [Two, setTwo] = useState("");
  const [Three, setThree] = useState("");
  const [Four, setFour] = useState("");
  const [Click, setClick] = useState(1);

  function Move(e, p, c, n) {
    var length = document.getElementById(c).value.length;
    var maxLength = document.getElementById(c).getAttribute("maxLength");
    if (length == maxLength) {
      if (n !== "") {
        document.getElementById(n).focus();
      }
    }
    if (e.key == "Backspace") {
      document.getElementById(p)?.focus();
    }
  }

  let history = useNavigate();
  const resendValue = localStorage.getItem("resendValue");

  var number = localStorage.getItem("sendEmail");

  var a = number ? number.split("@") : "";
  var b = a[0];
  var newstr = "";
  for (var i in b) {
    if (i > 0 && i < b.length - 1) newstr += "*";
    else newstr += b[i];
  }

  const Ref = useRef(null);
  const dispatch = useDispatch({});

  let errorsObj = { Otp: "" };
  const [error, setError] = useState(errorsObj);

  const PhoneNumber = (e) => {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };

    if (!One) {
      errorObj.Otp = "Please enter OTP";
      error = true;
    } else if (!Two) {
      errorObj.Otp = "Please enter OTP";
      error = true;
    } else if (!Three) {
      errorObj.Otp = "Please enter OTP";
      error = true;
    } else if (!Four) {
      errorObj.Otp = "Please enter OTP";
      error = true;
    } else if (One && Two && Three && Four) {
      let combinee2 = One + Two + Three + Four;
      let otp_id = localStorage.getItem("otp_id");
      let admin_id = localStorage.getItem("admin_id");
      let user_id = localStorage.getItem("user_id");

      let formData = new FormData();

      formData.append("otp", combinee2);
      formData.append("user_id", user_id);

      // formData.append("otp_id", otp_id);
      // formData.append("admin_id", admin_id);
      if (combinee2) {
        dispatch(dispatchLoadingStatus(true));
        dispatch(OtpMatch_API(formData));
      }
    }

    setError(errorObj);
  };

  const OtpMatchReducer = useSelector(
    (state) => state.globalReducer.OtpMatchReducer
  );
  useEffect(() => {
    if (OtpMatchReducer) {
      history("/ResetPassword");
    }
  }, [OtpMatchReducer]);

  const NumValid = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setOne(e.target.value);
    }
  };

  const NumValid2 = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setTwo(e.target.value);
    }
  };
  const NumValid3 = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setThree(e.target.value);
    }
  };
  const NumValid4 = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setFour(e.target.value);
    }
  };

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);

    //  console.log("sec",seconds);
    return {
      total,
      minutes,
      seconds,
    };
  };

  const startTimer = (e) => {
    let { total, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      // setTimer(minutes>1?minutes:"0"+minutes)
      // setTimer((minutes > 9 ? minutes : "0" + minutes) + ":" + (seconds > 9 ? seconds : "0" + seconds));
      setTimer(
        (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
    }
  };

  const clearTimer = (e) => {
    setTimer("05:00");

    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + 13);
    return deadline;
  };

  const onClickReset = (e) => {
    e.preventDefault();
    clearTimer(getDeadTime());
    setClick(Click + 1);
    localStorage.setItem("resendValue", Click);
    let formData = new FormData();
    formData.append("email", number);
    dispatch(dispatchLoadingStatus(true));
    dispatch(forgotPasswordOtpSendInEmail_API(formData));
  };

  //   const forgotPasswordOtpSendInEmailReducer = useSelector((state) => state.globalReducer.forgotPasswordOtpSendInEmailReducer);
  // console.log("this",forgotPasswordOtpSendInEmailReducer);
  //   useEffect(() => {
  //     if (forgotPasswordOtpSendInEmailReducer) {
  //       // localStorage.setItem("otp_id", forgotPasswordOtpSendInEmailReducer ? forgotPasswordOtpSendInEmailReducer.data.otp_id : "");
  //       // localStorage.setItem("admin_id", forgotPasswordOtpSendInEmailReducer ? forgotPasswordOtpSendInEmailReducer.data.admin_id : "");
  //       // localStorage.setItem("adminemail", forgotPasswordOtpSendInEmailReducer ? forgotPasswordOtpSendInEmailReducer.data.adminemail : "");
  //     }
  //   }, [forgotPasswordOtpSendInEmailReducer]);

  useEffect(() => {
    const token = localStorage.getItem("sendEmail");
    if (token) {
      if (!window.location.href.includes("Otp")) {
        window.location.href = window.location.origin + "/Otp";
      }
    } else {
      if (!window.location.href.includes("/Login")) {
        window.location.href = window.location.origin + "/Login";
      }
    }
    clearTimer(getDeadTime());
  }, []);

  return (
    <section className="main-forget-pw sign-in-page bg-white">
      <Loader />
      <div className="login-container">
        <form className="form-horizontal" onSubmit={(e) => e.preventDefault()}>
          <h1>OTP</h1>

          <p>Enter Verification Code that we have sent</p>

          <div class="input-group">
            <div class="d-flex input-otp">
              <input
                type="text"
                onKeyUp={(event) => Move(event, "", "tx1", "tx2")}
                id="tx1"
                value={One}
                onChange={NumValid}
                maxLength="1"
                placeholder="-"
              />
              <input
                type="text"
                onKeyUp={(event) => Move(event, "tx1", "tx2", "tx3")}
                id="tx2"
                value={Two}
                onChange={NumValid2}
                maxLength="1"
                placeholder="-"
              />
              <input
                type="text"
                onKeyUp={(event) => Move(event, "tx2", "tx3", "tx4")}
                id="tx3"
                value={Three}
                onChange={NumValid3}
                maxLength="1"
                placeholder="-"
              />
              <input
                type="text"
                onKeyUp={(event) => Move(event, "tx3", "tx4", "")}
                id="tx4"
                value={Four}
                onChange={NumValid4}
                maxLength="1"
                placeholder="-"
              />
            </div>
          </div>

          <button
            type="submit"
            class="btn btn-primary float-right"
            onClick={PhoneNumber}
          >
            Submit
          </button>

          {error.Otp && <div className="error">{error.Otp} </div>}

          <div className="bottom-text">
            {resendValue == 2 ? (
              ""
            ) : counter === "00:00" ? (
              <div>
                {resendValue >= 2 ? null : (
                  <a
                    href=""
                    className="text-muted text-underlined"
                    onClick={onClickReset}
                    cursor="not-allowed"
                  >
                    {" "}
                    Resend
                  </a>
                )}
              </div>
            ) : (
              // <div className="bottom-text">
              <p id="couter"> Resend OTP in {counter} </p>
              // </div>
            )}
          </div>
          <ToastContainer />
        </form>
      </div>
    </section>
  );
}

export default Otp;
