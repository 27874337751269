import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import {
  dispatchLoadingStatus,
  userEdit_API,
  userRegister_API,
} from "../../API/Action/globalAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader/Loader";
import { FaEye, FaEyeSlash } from 'react-icons/fa';


function Add(props) {
  const [eyes, setEyes] = useState(false);
  const toggleBtn = () => {

    setEyes(preState => !preState);
  }
  const [eyes2, setEyes2] = useState(false);
  const toggleBtn2 = () => {

    setEyes2(preState => !preState);
  }



  const history = useNavigate();
  const dispatch = useDispatch();

  const [fname, setFname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");

  const [confirmPassword, setConfirmPassword] = useState("");

  const [propsValue, setPropsValue] = useState();

  const [passwordError2, setPasswordError2] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const [confirmPasswordError2, setConfirmPasswordError2] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const [nameError, setNameError] = useState(false);
  const [nameError2, setNameError2] = useState("");

  const [emailError, setEmailError] = useState(false);
  const [emailError2, setEmailError2] = useState("");

  const [phoneError, setPhoneError] = useState(false);
  const [phoneError2, setPhoneError2] = useState("");

  let userRegisterReducer = useSelector(
    (state) => state.globalReducer.userRegisterReducer
  );
  console.log(userRegisterReducer, "my checkingggggg");

  let props22 = props ? props.state : "";

  const [selectedSections, setSelectedSections] = useState({
    Home: { HomeView: false, HomeAdd: false, HomeEdit: false, HomeAll: false },
    Pages: { PagesView: false, PagesAdd: false, PagesEdit: false, PagesAll: false },
    Products: { ProductsView: false, ProductsAdd: false, ProductsEdit: false, ProductsAll: false },
    "OurTeam": { OurTeamView: false, OurTeamAdd: false, OurTeamEdit: false, OurTeamAll: false },
    "Fabrics Category": { FabricsCategoryView: false, FabricsCategoryAdd: false, FabricsCategoryEdit: false, FabricsCategoryAll: false },
    Deals: { DealsView: false, DealsAdd: false, DealsEdit: false, DealsAll: false },
    "Price Range": { PriceRangeView: false, PriceRangeAdd: false, PriceRangeEdit: false, PriceRangeAll: false },
    Contact: { ContactView: false, LocationAdd: false, ContactEdit: false, ContactAll: false },
    Inquiry: { InquiryView: false },
    Testimonials: { TestimonialsView: false, TestimonialsAdd: false, TestimonialsEdit: false, TestimonialsAll: false },
    "Admin User Management": { AdminUserManagementView: false, AdminUserManagementAdd: false, AdminUserManagementEdit: false, AdminUserManagementAll: false },
    "Environment Selection": { EnvironmentSelectionView: false, EnvironmentSelectionAdd: false, EnvironmentSelectionEdit: false, EnvironmentSelectionAll: false }
  });


  const actionDisplayNames = {
    HomeAdd: 'Add',
    HomeEdit: 'Edit',
    HomeView: 'View',
    HomeAll: 'All',


    PagesAdd: 'Add',
    PagesEdit: 'Edit',
    PagesView: 'View',
    PagesAll: 'All',


    ProductsAdd: 'Add',
    ProductsEdit: 'Edit',
    ProductsView: 'View',
    ProductsAll: 'All',


    OurTeamAdd: 'Add',
    OurTeamEdit: 'Edit',
    OurTeamView: 'View',
    OurTeamAll: 'All',


    FabricsCategoryAdd: 'Add',
    FabricsCategoryEdit: 'Edit',
    FabricsCategoryView: 'View',
    FabricsCategoryAll: 'All',


    DealsAdd: 'Add',
    DealsEdit: 'Edit',
    DealsView: 'View',
    DealsAll: 'All',


    PriceRangeAdd: 'Add',
    PriceRangeEdit: 'Edit',
    PriceRangeView: 'View',
    PriceRangeAll: 'All',


    LocationAdd: 'Add',
    ContactEdit: 'Edit',
    ContactView: 'View',
    ContactAll: 'All',

    InquiryView: 'View',


    TestimonialsAdd: 'Add',
    TestimonialsEdit: 'Edit',
    TestimonialsView: 'View',
    TestimonialsAll: 'All',


    AdminUserManagementAdd: 'Add',
    AdminUserManagementEdit: 'Edit',
    AdminUserManagementView: 'View',
    AdminUserManagementAll: 'All',

    EnvironmentSelectionAdd: 'Add',
    EnvironmentSelectionEdit: 'Edit',
    EnvironmentSelectionView: 'View',
    EnvironmentSelectionAll: 'All'




  };

  const [error, setError] = useState('');
  const [error2, setError2] = useState('');


  // const handleCheckboxChange = (section, action) => {
  //   setSelectedSections((prevSelected) => {
  //     const updatedSection = {
  //       ...prevSelected[section],
  //       [action]: !prevSelected[section][action],
  //     };

  //     const allChecked = Object.values(updatedSection).every((value) => value);
  //     updatedSection.All = allChecked;

  //     return {
  //       ...prevSelected,
  //       [section]: updatedSection,
  //     };
  //   });
  // };


  const handleCheckboxChange = (section, action) => {
    console.log("action", action);
    setSelectedSections((prevSelected) => {
      const updatedSection = {
        ...prevSelected[section],
        [action]: !prevSelected[section][action],
      };

      // Check if both "Add" and "Edit" are selected, then set "List" to true


      if (action.endsWith('All')) {
        // Set all other checkboxes in the section to the value of the "All" checkbox
        const allValue = updatedSection[action];
        Object.keys(updatedSection).forEach((key) => {
          if (!key.endsWith('All')) {
            updatedSection[key] = allValue;
          }
        });
      } else {
        if (action === 'HomeAdd' || action === 'HomeEdit') {
          updatedSection.HomeView = updatedSection.HomeAdd || updatedSection.HomeEdit;
          updatedSection.HomeAll = updatedSection.HomeAdd && updatedSection.HomeEdit;


        }

        else if (action === 'HomeView') {
          if (!updatedSection.HomeView) {
            updatedSection.HomeAdd = false;
            updatedSection.HomeEdit = false;
            updatedSection.HomeAll = false;
          }
        }


        else if (action === 'PagesAdd' || action === 'PagesEdit') {
          updatedSection.PagesView = updatedSection.PagesAdd || updatedSection.PagesEdit;
          updatedSection.PagesAll = updatedSection.PagesAdd && updatedSection.PagesEdit;

        }
        else if (action === 'PagesView') {
          if (!updatedSection.PagesView) {
            updatedSection.PagesAdd = false;
            updatedSection.PagesEdit = false;
            updatedSection.PagesAll = false;
          }
        }

        else if (action === 'ProductsAdd' || action === 'ProductsEdit') {
          updatedSection.ProductsView = updatedSection.ProductsAdd || updatedSection.ProductsEdit;
          updatedSection.ProductsAll = updatedSection.ProductsAdd && updatedSection.ProductsEdit;

        }
        else if (action === 'ProductsView') {
          if (!updatedSection.ProductsView) {
            updatedSection.ProductsAdd = false;
            updatedSection.ProductsEdit = false;
            updatedSection.ProductsAll = false;
          }
        }
        else if (action === 'OurTeamAdd' || action === 'OurTeamEdit') {
          updatedSection.OurTeamView = updatedSection.OurTeamAdd || updatedSection.OurTeamEdit;
          updatedSection.OurTeamAll = updatedSection.OurTeamAdd && updatedSection.OurTeamEdit;

        }
        else if (action === 'OurTeamView') {
          if (!updatedSection.OurTeamView) {
            updatedSection.OurTeamAdd = false;
            updatedSection.OurTeamEdit = false;
            updatedSection.OurTeamAll = false;
          }
        }
        else if (action === 'FabricsCategoryAdd' || action === 'FabricsCategoryEdit') {
          updatedSection.FabricsCategoryView = updatedSection.FabricsCategoryAdd || updatedSection.FabricsCategoryEdit;
          updatedSection.FabricsCategoryAll = updatedSection.FabricsCategoryAdd && updatedSection.FabricsCategoryEdit;

        }
        else if (action === 'FabricsCategoryView') {
          if (!updatedSection.FabricsCategoryView) {
            updatedSection.FabricsCategoryAdd = false;
            updatedSection.FabricsCategoryEdit = false;
            updatedSection.FabricsCategoryAll = false;
          }
        }
        else if (action === 'DealsAdd' || action === 'DealsEdit') {
          updatedSection.DealsView = updatedSection.DealsAdd || updatedSection.DealsEdit;
          updatedSection.DealsAll = updatedSection.DealsAdd && updatedSection.DealsEdit;

        }
        else if (action === 'DealsView') {
          if (!updatedSection.DealsView) {
            updatedSection.DealsAdd = false;
            updatedSection.DealsEdit = false;
            updatedSection.DealsAll = false;
          }
        }
        else if (action === 'PriceRangeAdd' || action === 'PriceRangeEdit') {
          updatedSection.PriceRangeView = updatedSection.PriceRangeAdd || updatedSection.PriceRangeEdit;
          updatedSection.PriceRangeAll = updatedSection.PriceRangeAdd && updatedSection.PriceRangeEdit;

        }
        else if (action === 'PriceRangeView') {
          if (!updatedSection.PriceRangeView) {
            updatedSection.PriceRangeAdd = false;
            updatedSection.PriceRangeEdit = false;
            updatedSection.PriceRangeAll = false;
          }
        }
        else if (action === 'LocationAdd' || action === 'ContactEdit') {
          updatedSection.ContactView = updatedSection.LocationAdd || updatedSection.ContactEdit;
          updatedSection.ContactAll = updatedSection.LocationAdd && updatedSection.ContactEdit;

        }
        else if (action === 'ContactView') {
          if (!updatedSection.ContactView) {
            updatedSection.LocationAdd = false;
            updatedSection.ContactEdit = false;
            updatedSection.ContactAll = false;
          }
        }
        else if (action === 'TestimonialsAdd' || action === 'TestimonialsEdit') {
          updatedSection.TestimonialsView = updatedSection.TestimonialsAdd || updatedSection.TestimonialsEdit;
          updatedSection.TestimonialsAll = updatedSection.TestimonialsAdd && updatedSection.TestimonialsEdit;

        }
        else if (action === 'TestimonialsView') {
          if (!updatedSection.TestimonialsView) {
            updatedSection.TestimonialsAdd = false;
            updatedSection.TestimonialsEdit = false;
            updatedSection.TestimonialsAll = false;
          }
        }
        else if (action === 'AdminUserManagementAdd' || action === 'AdminUserManagementEdit') {
          updatedSection.AdminUserManagementView = updatedSection.AdminUserManagementAdd || updatedSection.AdminUserManagementEdit;
          updatedSection.AdminUserManagementAll = updatedSection.AdminUserManagementAdd && updatedSection.AdminUserManagementEdit;

        }
        else if (action === 'AdminUserManagementView') {
          if (!updatedSection.AdminUserManagementView) {
            updatedSection.AdminUserManagementAdd = false;
            updatedSection.AdminUserManagementEdit = false;
            updatedSection.AdminUserManagementAll = false;
          }
        }
        else if (action === 'EnvironmentSelectionAdd' || action === 'EnvironmentSelectionEdit') {
          updatedSection.EnvironmentSelectionView = updatedSection.EnvironmentSelectionAdd || updatedSection.EnvironmentSelectionEdit;
          updatedSection.EnvironmentSelectionAll = updatedSection.EnvironmentSelectionAdd && updatedSection.EnvironmentSelectionEdit;

        }
        else if (action === 'EnvironmentSelectionView') {
          if (!updatedSection.EnvironmentSelectionView) {
            updatedSection.EnvironmentSelectionAdd = false;
            updatedSection.EnvironmentSelectionEdit = false;
            updatedSection.EnvironmentSelectionAll = false;
          }
        }



        // const allChecked = Object.values(updatedSection)
        //   .filter((value, key) => !String(key).endsWith('All'))
        //   .every((value) => value);
        // updatedSection[`${section}All`] = allChecked;


      }

      return {
        ...prevSelected,
        [section]: updatedSection,
      };
    });
  };

  const handleSelectAllChange = (section) => {
    setSelectedSections((prevSelected) => {
      const allChecked = !prevSelected[section].All;

      const updatedSection = Object.fromEntries(
        Object.keys(prevSelected[section]).map((action) => [action, allChecked])
      );

      return {
        ...prevSelected,
        [section]: updatedSection,
      };
    });
  };


  useEffect(() => {
    if (props.data) {
      console.log("accessData", props.data.accessData);
      setPropsValue(props.data);
      // setTitle(props.data.title);
      // setAddress(props.data.address_1);
      setFname(props.data.name);
      // setPassword(props.data.password);
      setEmail(props.data.email);
      setPhone(props.data.phone);
      let access = JSON.parse(props.data.accessData);
      console.log("dddd", access);

      setSelectedSections({
        Home: {
          HomeView: access.HomeView ? access.HomeView : false,
          HomeAdd: access.HomeAdd ? access.HomeAdd : false,
          HomeEdit: access.HomeEdit ? access.HomeEdit : false,
          HomeAll: access.HomeAll ? access.HomeAll : false,

        },

        Pages: {
          PagesView: access.PagesView ? access.PagesView : false,
          PagesAdd: access.PagesAdd ? access.PagesAdd : false,
          PagesEdit: access.PagesEdit ? access.PagesEdit : false,
          PagesAll: access.PagesAll ? access.PagesAll : false,

        },

        Products: {
          ProductsView: access.ProductsView ? access.ProductsView : false,
          ProductsAdd: access.ProductsAdd ? access.ProductsAdd : false,
          ProductsEdit: access.ProductsEdit ? access.ProductsEdit : false,
          ProductsAll: access.ProductsAll ? access.ProductsAll : false,

        },

        "Our Team": {
          OurTeamView: access.OurTeamView ? access.OurTeamView : false,
          OurTeamAdd: access.OurTeamAdd ? access.OurTeamAdd : false,
          OurTeamEdit: access.OurTeamEdit ? access.OurTeamEdit : false,
          OurTeamAll: access.OurTeamAll ? access.OurTeamAll : false,

        },

        "Fabrics Category": {
          FabricsCategoryView: access.FabricsCategoryView ? access.FabricsCategoryView : false,
          FabricsCategoryAdd: access.FabricsCategoryAdd ? access.FabricsCategoryAdd : false,
          FabricsCategoryEdit: access.FabricsCategoryEdit ? access.FabricsCategoryEdit : false,
          FabricsCategoryAll: access.FabricsCategoryAll ? access.FabricsCategoryAll : false,

        },


        Deals: {
          DealsView: access.DealsView ? access.DealsView : false,
          DealsAdd: access.DealsAdd ? access.DealsAdd : false,
          DealsEdit: access.DealsEdit ? access.DealsEdit : false,
          DealsAll: access.DealsAll ? access.DealsAll : false,

        },

        "Price Range": {
          PriceRangeView: access.PriceRangeView ? access.PriceRangeView : false,
          PriceRangeAdd: access.PriceRangeAdd ? access.PriceRangeAdd : false,
          PriceRangeEdit: access.PriceRangeEdit ? access.PriceRangeEdit : false,
          PriceRangeAll: access.PriceRangeAll ? access.PriceRangeAll : false,

        },


        Contact: {
          ContactView: access.ContactView ? access.ContactView : false,
          LocationAdd: access.LocationAdd ? access.LocationAdd : false,
          ContactEdit: access.ContactEdit ? access.ContactEdit : false,
          ContactAll: access.ContactAll ? access.ContactAll : false,

        },

        Inquiry: {
          InquiryView: access.InquiryView ? access.InquiryView : false,
        },

        Testimonials: {
          TestimonialsView: access.TestimonialsView ? access.TestimonialsView : false,
          TestimonialsAdd: access.TestimonialsAdd ? access.TestimonialsAdd : false,
          TestimonialsEdit: access.TestimonialsEdit ? access.TestimonialsEdit : false,
          TestimonialsAll: access.TestimonialsAll ? access.TestimonialsAll : false,
        },


        "Admin User Managment": {
          AdminUserManagementView: access.AdminUserManagementView ? access.AdminUserManagementView : false,
          AdminUserManagementAdd: access.AdminUserManagementAdd ? access.AdminUserManagementAdd : false,
          AdminUserManagementEdit: access.AdminUserManagementEdit ? access.AdminUserManagementEdit : false,
          AdminUserManagementAll: access.AdminUserManagementAll ? access.AdminUserManagementAll : false,
        },

        "Environment Selection": {
          EnvironmentSelectionView: access.EnvironmentSelectionView ? access.EnvironmentSelectionView : false,
          EnvironmentSelectionAdd: access.EnvironmentSelectionAdd ? access.EnvironmentSelectionAdd : false,
          EnvironmentSelectionEdit: access.EnvironmentSelectionEdit ? access.EnvironmentSelectionEdit : false,
          EnvironmentSelectionAll: access.EnvironmentSelectionAll ? access.EnvironmentSelectionAll : false,
        },

      });
    }
  }, [props]);




  // useEffect(() => {
  //   // Simulating data received from API
  //   const apiData = { HomeAdd: true, HomeEdit: false, HomeView: true, PagesAdd: false, PagesEdit: false, PagesView: true };

  //   // Update the state based on the API data
  //   setSelectedSections({
  //     "Our Team": {
  //       Add: apiData.HomeAdd,
  //       Edit: apiData.HomeEdit,
  //       List: apiData.HomeView,
  //       All: false,
  //     },

  //   });
  // }, []);






  let nameRe = /^[A-Za-z]( ?[A-Za-z] ?)*$/;
  let MobileRe = /^[0-9\b]+$/;

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  function handleEmail(e) {
    setEmail(e.target.value.trim() == "" ? "" : e.target.value);

    if (e.target.value) {
      if (!isValidEmail(e.target.value)) {
        setEmailError(true);
        setEmailError2("Please enter valid email");
      } else {
        setEmailError(false);
        setEmailError2("");
      }
    } else {
      setEmailError(false);
      setEmailError2("Please enter email");
    }
  }

  function handlePhone(e) {
    // // let data = e.target.value.trim() == "" ? "" : e.target.value;
    // let data = e.target.value.trim("") == "" ? "" : e.target.value
    // console.log("phone",data)
    // // setPhone(data && MobileRe.test(data) ? data : "");
    // setPhone(data && MobileRe.test(data) );
    // if (data) {
    //   alert("false")
    //   setPhoneError(false);
    //   setPhoneError2("");
    // } else {
    //   alert("true")
    //   setPhoneError(true);
    //   setPhoneError2("Please enter phone");
    // }

    // let data = e.target.value.trim("") == "" ? "" : e.target.value

    let data = e.target.value.trim("") == "" ? "" : e.target.value;
    if (data) {
      if (!MobileRe.test(data)) {
        setPhoneError(true);

      } else {
        setPhone(e.target.value);
        setPhoneError(false);

      }
    } else {
      setPhone("");
      setPhoneError(true);
      setPhoneError2("Please enter phone");
    }
  }



  function handleFname(e) {
    const result = /^[A-Za-z]( ?[A-Za-z] ?)*$/;

    if (e.target.value) {
      if (e.target.value === "" || result.test(e.target.value)) {
        setFname(e.target.value);
        setNameError2("");
        setNameError(false);
      } else {
        setNameError2("Plese enter valid name");
        setNameError(true);
      }
    } else {
      setFname("");
      setNameError(true);
      setNameError2("Please enter name");
    }
  }

  const handlePassword = (e) => {
    setPassword(e.target.value.trim());

    if (!e.target.value) {
      setPasswordError2("Please enter password");
    } else {
      setPasswordError2("");
    }
  };

  const handleConfirmPassword = (e) => {
    setConfirmPassword(e.target.value.trim());
    if (password) {
      if (!e.target.value) {
        setConfirmPasswordError2("Please enter confirm password");
      } else {
        if (password === e.target.value) {
          setConfirmPasswordError2("");

        }
        else {
          setConfirmPasswordError2("Password and confirm password  is not match");
        }
      }
    }
    else {

    }

  };


  function handleSubmit() {
    const payload = Object.assign({}, ...Object.entries(selectedSections).map(([section, actions]) => actions));

    const isAnyTrue = Object.values(payload).includes(true);
    console.log("selectedSections",  isAnyTrue);


    const hasSelectedCheckbox = Object.values(selectedSections)
      .flatMap((actions) => Object.values(actions).filter((action) => action !== true))
      .some((value) => value);

    if (isAnyTrue == false) {
      setError('Please select at least one checkbox.');
    } else {
      setError('');
    }


    if (fname) {
      if (fname.length >= 2) {
        // setNameError2("xa");
        if (!nameRe.test(fname)) {
          setNameError2("Please enter valid  name");
        } else {
          setNameError(false);
          setNameError2("");
        }
      } else {
        setNameError(true);
        setNameError2("Please enter valid name");
      }
    } else {
      setNameError(true);
      setNameError2("Please enter  name");
    }

    if (email) {
      if (!isValidEmail(email)) {
        setEmailError2("Please enter valid email");
      } else {
        setEmailError2("");
      }
    } else {
      setEmailError2("Please enter email");
    }

    if (phone) {
      setPhoneError2("");
    } else {
      setPhoneError2("Please enter phone");
    }

    let data = propsValue
      ? propsValue.user_id
        ? propsValue.user_id
        : ""
      : "";

    if (!data) {
      if (password) {
        setPasswordError2("");
      } else {
        setPasswordError2("Please enter password");
      }

      if (confirmPassword) {
        setConfirmPasswordError2("");
      } else {
        setConfirmPasswordError2("Please enter confirm password");
      }
    }



    if (nameError == false && emailError == false && phoneError == false && fname && email && phone && isAnyTrue == true) {
      let formData = new FormData();
      // formData.append("image", selectedFile ? selectedFile : "");
      formData.append("password", password);
      formData.append("name", fname);

      formData.append("email", email);
      formData.append("phone", phone);
      formData.append("accessData", JSON.stringify(payload));



      if (data) {
        console.log("dataaaaaaaaaaa", data);

        formData.append("user_id", data);
        localStorage.setItem("user_id", data)
        dispatch(userEdit_API(formData));
        dispatch(dispatchLoadingStatus(true));
      } else {

        if (passwordError == false && confirmPasswordError == false && confirmPassword && password) {

          dispatch(userRegister_API(formData));
          dispatch(dispatchLoadingStatus(true));
        }

      }
    }

  }



  return (
    <>
      <div className="wrapper">
        <Loader />
        <Sidebar />

        <div id="content-page" className="content-page">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="iq-edit-list-data">
                  <div className="tab-content">
                    <div
                      className="tab-pane fade active show"
                      id="personal-information"
                      role="tabpanel"
                    >
                      <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between">
                          <div className="iq-header-title">
                            <h4 className="card-title">   {propsValue ? propsValue.user_id ? "Edit User" : "Assign User" : "Assign User"}</h4>
                          </div>

                          <ul class="d-flex mt-3">
                            <li class="breadcrumb-item"><a href="/AdminUserManagementList">Admin Use Management List</a></li>
                            <li class="breadcrumb-item active" aria-current="page">{propsValue ? propsValue.user_id ? "Edit User" : "Assign User" : "Assign User"}</li>
                          </ul>
                        </div>
                        <div className="iq-card-body">
                          <div className=" row ">
                            <div className="form-group col-sm-6">
                              <label for="fname">Name:</label>
                              <input
                                type="text"
                                className="form-control"
                                value={fname}
                                placeholder="Enter Name"
                                onChange={(e) => handleFname(e)}
                              />
                              <p className="errorr">{nameError2}</p>
                            </div>
                            <div className="form-group col-sm-6">
                              <label htmlFor="title">Email:</label>
                              <input
                                type="text"
                                placeholder="Enter Email"
                                className="form-control"
                                value={email}
                                onChange={(e) => handleEmail(e)}
                              />
                              <p className="errorr">{emailError2}</p>
                            </div>

                            <div className="form-group col-sm-6">
                              <label htmlFor="title">Phone:</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Phone"
                                value={phone}
                                onChange={(e) => handlePhone(e)}
                              />
                              <p className="errorr">{phoneError2}</p>
                              
                            </div>

                            <div className="form-group col-sm-6">
                              <label htmlFor="exampleInputPassword1">
                                Password:
                              </label>

                              <div style={{ position: "relative" }}>
                                <input
                                  type={eyes ? "text" : "password"}
                                  className="form-control mb-0"
                                  id="exampleInputPassword1"
                                  placeholder="Enter Password"
                                  value={password}
                                  onChange={handlePassword}
                                />
                                <button style={{ position: 'absolute', top: '6px', right: '0' }} className="btn " type="button" id="password-addon" onClick={toggleBtn}>
                                  {
                                    eyes ? <FaEye style={{ width: "18", height: "18" }} /> : <FaEyeSlash style={{ width: "18", height: "18" }} />
                                  }
                                </button>
                              </div>
                              <p className="errorr"> {passwordError2}  </p>
                            </div>

                            <div className="form-group col-sm-6">
                              <label htmlFor="exampleInputPassword1">
                                Confirm Password:
                              </label>

                              <div style={{ position: "relative" }}>
                                <input
                                  type={eyes2 ? "text" : "password"}
                                  className="form-control mb-0"
                                  id="exampleInputPassword1"
                                  placeholder="Confirm Password"
                                  value={confirmPassword}
                                  onChange={handleConfirmPassword}
                                />
                                <button style={{ position: 'absolute', top: '6px', right: '0' }} className="btn " type="button" id="password-addon" onClick={toggleBtn2}>
                                  {
                                    eyes2 ? <FaEye style={{ width: "18", height: "18" }} /> : <FaEyeSlash style={{ width: "18", height: "18" }} />
                                  }
                                </button>
                              </div>
                              <p className="errorr"> {confirmPasswordError2} </p>
                            </div>


                            <div className="col-md-12">

                              <label
                                htmlFor="inputName"
                                className=" col-form-label color-dark fs-14 fw-500 align-center"
                              >
                                Preferred Roles *
                              </label>


                              {Object.keys(selectedSections).map((section) => (

                                <div className="form-group row align-items-center">

                                  <div className="col-md-3" key={section}>
                                    <label
                                      htmlFor="inputName"
                                      className=" col-form-label color-dark fs-14 fw-500 align-center"
                                    >
                                      {section}
                                    </label>

                                  </div>


                                  <div className="col-md-9">
                                    <div className="custom-role-labels">

                                      {Object.keys(selectedSections[section])
                                        .filter((action) => action !== 'All')

                                        .map((action) => (
                                          // <li key={`${section}-${action}`}>
                                          <label>
                                            <input
                                              type="checkbox"
                                              checked={selectedSections[section][action]}
                                              onChange={() => handleCheckboxChange(section, action)}
                                            />
                                            {` ${actionDisplayNames[action]}`}
                                          </label>
                                          // </li>
                                        ))}

                                    </div>
                                  </div>

                                </div>
                              ))}
                            <p className="errorr">{error}</p>


                            </div>

                          </div>
                          <button
                            type="submit"
                            className="btn btn-primary mr-2"
                            onClick={() => handleSubmit()}
                          >
                            Submit
                          </button>
                          <button type="reset" className="btn iq-bg-danger">
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Add;
