import React, { Component } from "react";
// import './Loader.css';
import { useDispatch, useSelector } from 'react-redux';

function Loader () {
 
    const Loding = useSelector(state => state.LoadingStatusReducer.loading);
    return(
          Loding&&
          <div id="loading">
          <div id="loading-center">
             <div class="loader">
                <div class="cube">
                   <div class="sides">
                      <div class="top"></div>
                      <div class="right"></div>
                      <div class="bottom"></div>
                      <div class="left"></div>
                      <div class="front"></div>
                      <div class="back"></div>
                   </div>
                </div>
             </div>
          </div>
       </div>

    );
}


export default Loader;