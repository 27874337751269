import React, { useState } from 'react';

import Add from './Add';
import { useLocation } from 'react-router-dom';
import Sidebar from '../../Sidebar/Sidebar';



function FebricsCategoryEdit(props) {

  const { state } = useLocation();

  console.log("props" , state);




  return (
    <>
    {/* added by parth roka */}

      <div className="wrapper">
        <Sidebar/>


        <Add data={state}/>


      </div>

    </>
  )
}

export default FebricsCategoryEdit