import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
// import { CKEditor } from 'ckeditor4-react';
import CKEditor from "react-ckeditor-component";
import { ReactComponent as CloseSvg } from "../../Assets/Images/icons8-no.svg";
import {
  contactAdd_API,
  contactEdit_API,
  countrylist_API,
  dispatchLoadingStatus,
  
  getstatecityAPI,
  
  ourTeamAdd_API,
  ourTeamEdit_API,
  pagesAdd_API,
  pagesEdit_API,
} from "../../API/Action/globalAction";
import { connect, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader/Loader";
import swal from "sweetalert";
import { ourTeamAdd_URL, ourTeamEdit_URL } from "../../OtherFiles/Constant";
import Select from "react-select";

function Add(props) {
  const [propsValue, setPropsValue] = useState();

  const dispatch = useDispatch({});
  let history = useNavigate();

  const countryi = [
    {
      countryy: "COUNTRY",
      state: ["State"],
      city: ["city"],
    },
    {
      countryy: "INDIA",
      state: ["Gujarat", "rajasthan"],
      // city:["Ahmedabad" , "rajkot"]
    },
    {
      countryy: "USA",
      state: ["New jersey", "californiya"],
      // city:["Los Angeles","Boston"]
    },
    {
      countryy: "UK",
      state: ["london", "france"],
      // city:["Birmingham","Leicester"]
    },
  ];

  const [title, setTitle] = useState("");
  const [department, setDepartment] = useState("");
  const [email, setEmail] = useState("");
  const [addressError2, setAddressError2] = useState();
  const [addressError, setAddressError] = useState(false);

  const [phone, setPhone] = useState("");

  const [content, setContent] = useState("");
  const [address, setAddress] = useState("");

  const [country, setCountry] = useState("");
  const [st, setSt] = useState("");

  const [titleError, setTitleError] = useState(false);
  const [titleError2, setTitleError2] = useState("");

  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);

  const [selectedFileError, setSelectedFileError] = useState(false);
  const [selectedFileError2, setSelectedFileError2] = useState("");

  const [contentError, setContentError] = useState(false);
  const [contentError2, setContentError2] = useState("");

  const [emailError, setEmailError] = useState(false);
  const [emailError2, setEmailError2] = useState("");

  const [phoneError, setPhoneError] = useState(false);
  const [phoneError2, setPhoneError2] = useState("");

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  function handleMessage() {
    swal({
      text: "Are you sure you want to leave this page?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        window.location.href = window.location.origin + "/LocationList";
      } else {
        console.log("Cancel press");
      }
    });
  }

  useEffect(() => {
    if (props.data) {
      setPropsValue(props.data);
      setTitle(props.data.title);
      setAddress(props.data.address_1);
      setContent(props.data.short_description);
      setEmail(props.data.email_id);
      setPhone(props.data.phone_1);
    }
  }, [props]);
  const handleChange = (e) => {
    setContent(e.target.value.trim() == "" ? "" : e.target.value);

    if (e.target.value) {
      setContentError(false);
      setContentError2("");
    } else {
      setContentError(true);
      setContentError2("Please enter description");
    }
  };

  let nameRe = /^[a-zA-Z\s]*$/;
  let MobileRe = /^[0-9\b]+$/;

  function handlePhone(e) {
    let data = e.target.value.trim() == "" ? "" : e.target.value;
    setPhone(data ? data : "");
    if (data) {
      setPhoneError(false);
      setPhoneError2("");
    } else {
      setPhoneError(true);
      setPhoneError2("Please enter phone");
    }
  }
  function handleAdd(e) {
    setAddress(e.target.value.trim() == "" ? "" : e.target.value);

    if (e.target.value) {
      setAddressError(false);
      setAddressError2("");
    } else {
      setAddressError(true);
      setAddressError2("Please enter address");
    }
  }

  function handleEmail(e) {
    setEmail(e.target.value.trim() == "" ? "" : e.target.value);

    if (e.target.value) {
      if (!isValidEmail(e.target.value)) {
        setEmailError(true);
        setEmailError2("Email not valid");
      } else {
        setEmailError(false);
        setEmailError2("");
      }
    } else {
      setEmailError(false);
      setEmailError2("Email is required");
    }
  }

  function handleTitle(e) {
    setTitle(e.target.value.trim() == "" ? "" : e.target.value);

    if (e.target.value) {
      if (e.target.value.length >= 2) {
        setTitleError(false);
        setTitleError2("");

        if (!nameRe.test(e.target.value)) {
          setTitleError(true);
          setTitleError2("Please enter valid title");
        } else {
          setTitleError(false);
          setTitleError2("");
        }
      } else {
        setTitleError(true);
        setTitleError2("Please enter valid title");
      }
    } else {
      setTitleError(true);
      setTitleError2("Please enter title");
    }
  }

  const [modelStateErr, setModelStateErr] = useState(false);
  const [FreelancerState, setFreelancerState] = useState("");
  const [modelState, setModelState] = useState({ "label": "State", "value": "" });
  
  function handleModelState(e) {

    if (e.value == "") {
        setModelState({ "label": "State", "value": "" });
        setFreelancerState("");
        setModelStateErr(true);
    }
    else {
        setModelState({ "label": e.label, "value": e.value });
        setFreelancerState(e.value);
        setModelStateErr(false);
    }
}

const [state, setstate] = useState(''); 
const [start, setStart] = useState(0);

  const [citylist, setcitylist] = useState([]);
  const [city, setciti] = useState('');
  const [state33, setstate33] = useState('');
  let getstatecityReg = useSelector(state => state.globalReducer.getstatecityReg);


  function setcity(e) {
    if (getstatecityReg) {
      console.log("getstatecityReg...",getstatecityReg);
        var filter = getstatecityReg.data.filter(i => i._id == e.value)
        setstate(e.value)
        setstate33(filter.length > 0 ? filter[0].state : [])
        setciti('')   
        if (filter && e.value) {
            setcitylist(filter[0].name);
            setStart(0);
            setciti('');
        }
        else {
            setcitylist([])
            setciti('')
        }
    }
}
useEffect(() => {
  // localStorage.setItem("projectTitle", "")
  // localStorage.setItem("projectTitle22", "Project");
  dispatch(dispatchLoadingStatus(true))
  dispatch(countrylist_API());
  // let formData = new FormData();
  // formData.append("token", localStorage.getItem('token'));
  // dispatch(getProjectTitleAPI(formData))
  // dispatch(getRolesAPI(formData))
  // dispatch(freelancerDataAPI(formData))
}, [])

const countrylistReg = useSelector(state => state.globalReducer.countrylistReg);
// console.log("countrylistReg",countrylistReg.data);


  function handleSubmit() {
    if (address) {
      if (address.length > 5) {
        setAddressError2("Please enter valid address");
      }
      setAddressError2("");
    } else {
      setAddressError2("Please enter address");
    }

    if (content) {
      setContentError2("");
    } else {
      setContentError2("Please enter address");
    }

    if (title) {
      if (title.length >= 2) {
        setTitleError2("");

        if (!nameRe.test(title)) {
          setTitleError2("Please enter valid title");
        } else {
          setTitleError(false);
          setTitleError2("");
        }
      } else {
        setTitleError2("Please enter valid title");
      }
    } else {
      setTitleError2("Please enter title");
    }

    if (email) {
      if (!isValidEmail(email)) {
        setEmailError2("Email not valid");
      } else {
        setEmailError2("");
      }
    } else {
      setEmailError2("Email is required");
    }

    if (phone) {
      setPhoneError2("");
    } else {
      setPhoneError2("Please enter phone");
    }

    if (
      titleError == false &&
      emailError == false &&
      phoneError == false &&
      addressError == false &&
      email &&
      title &&
      phone &&
      address
    ) {
      MainFun();
    }
  }

  // useEffect(()=>{
  //   MainFun();
  // },[])

  const [counry2, setCountry2] = useState(false);
  // const [city, setCity] = useState();

  // function HandleCountryChange(e) {
  //   console.log(e.target.value);
  //   setCountry(e.target.value);

  //   const filter = countryi.filter((m) => e.target.value == m.countryy);
  //   console.log(filter[0].state, "filter");
  //   setSt(filter[0].state);
  // }

  // function handleCity(e) {
  //   setCity(e.target.value);
  //   const filterCity = countryi.filter((M) => e.target.value == M.state);
  //   console.log(filterCity, "FILTER CITY");
  //   // setCity(filterCity[0].city)
  // }

  const [pincode, setpincode] = useState('');
  
  const NumValid = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
        setpincode(e.target.value)
    }
}

// useEffect(() => {
//   if (pincode.length == 6 || pincode.length == 0) {
//       setpage(1)
//       Apicall(0)
//   }
// }, [pincode])

  function MainFun() {
    let formData = new FormData();
    formData.append("title", title);
    formData.append("short_description", content);
    formData.append("email_id", email);
    formData.append("phone_1", phone);
    formData.append("address_1", address);
    formData.append("country_id", state);

    let data = propsValue
      ? propsValue.contact_id
        ? propsValue.contact_id
        : ""
      : "";

    if (data) {
      formData.append("contact_id", data);

      dispatch(dispatchLoadingStatus(true));
     
      
      dispatch(contactEdit_API(formData));
    } else {
      dispatch(dispatchLoadingStatus(true));
      dispatch(getstatecityAPI(formData));
          
      dispatch(contactAdd_API(formData));
    }
  }
  return (
    <>

    {/* added by parth roka */}

      <div className="wrapper">
        <Loader />
        <Sidebar />

        <div id="content-page" className="content-page">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="iq-edit-list-data">
                  <div className="tab-content">
                    <div
                      className="tab-pane fade active show"
                      id="personal-information"
                      email="tabpanel"
                    >
                      <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between">
                          <div className="iq-header-title">
                            <h4 className="card-title">
                              {" "}
                              {propsValue
                                ? propsValue.contact_id
                                  ? "Location Edit"
                                  : "Location Add"
                                : "Location Add"}
                            </h4>
                          </div>

                          <ul class="d-flex mt-3">
                            <li class="breadcrumb-item">
                              <a href="/OurTeamList">Location List</a>
                            </li>
                            <li
                              class="breadcrumb-item active"
                              aria-current="page"
                            >
                              {propsValue
                                ? propsValue.contact_id
                                  ? "Location Edit"
                                  : "Location Add"
                                : "Location Add"}
                            </li>
                          </ul>
                        </div>
                        <div className="iq-card-body">
                          <div className=" row">
                            <div className="form-group col-sm-6">
                              <label htmlFor="title">Title:</label>
                              <input
                                type="text"
                                maxLength={100}
                                className="form-control"
                                value={title}
                                onChange={(e) => handleTitle(e)}
                              />
                              <p className="errorr">{titleError2}</p>
                            </div>

                            <div className="form-group col-sm-6">
                              <label htmlFor="title">Email:</label>
                              <input
                                type="text"
                                maxLength={254}
                                className="form-control"
                                value={email}
                                onChange={(e) => handleEmail(e)}
                              />
                              <p className="errorr">{emailError2}</p>
                            </div>

                            <div className="form-group col-sm-6">
                              <label htmlFor="title">Phone:</label>
                              <input
                                type="text"
                                className="form-control"
                                value={phone}
                                onChange={(e) => handlePhone(e)}
                                maxLength={17}
                              />
                              <p className="errorr">{phoneError2}</p>
                            </div>

                            {/* <div className="form-group col-sm-6">
                              <label htmlFor="title">ZipCode:</label>
                              <input value={pincode} className="form-control" maxLength={10} placeholder={"Pincode"} onChange={NumValid} />
                            </div>
                            <div class="form-group col-sm-3">
                                         <Select
                                           options={countrylistReg ? [{ label: 'Country', value: '' }].concat(countrylistReg.data.map(e => ({ label: e.name, value: e.id }))) : []}
                                           placeholder={"Country"}
                                           value={state == "" ? [{ label: 'Country', value: '' }] : state ? [{ label: state33, value: state }] : [{ label: 'Country', value: '' }]}
                                            onChange={(e) => setcity(e)}
                                           />
                              <Select
                                  options={citylist ? [{ label: "City", value: "" }].concat(citylist.map((e) => ({ label: e, value: e }))) : []}
                                  isClearable={false}
                                  placeholder={"City"}
                                  value={city == "" ? [{ label: "City", value: "" }] : city ? [{ label: city, value: city }] : [{ label: "City", value: "" }]}
                                  onChange={(e) => setciti(e.value)}
                                />
                                           </div> */}

                            <div class="form-group col-sm-12">
                              <label>Address:</label>
                              <textarea
                                class="form-control"
                                rows="5"
                                maxLength={255}
                                value={address}
                                onChange={(e) => handleAdd(e)}
                                style={{ lineHeight: "22px" }}
                              ></textarea>
                              <p className="errorr">{addressError2}</p>
                            </div>
                            

                            <div class="form-group col-sm-6">
                              {/* <select  
                              onChange={HandleCountryChange}>                                  
                              {countryi && countryi.map((e)=>
                              <option value={e.countryy}>{e.countryy}</option>
                              )}
                              </select>  */}
                                             {/* <Select
                                            options={getstatecityReg ? [{ label: 'State', value: '' }].concat(getstatecityReg.data.map(e => ({ label: e.state, value: e._id }))) : []}
                                            placeholder={"State"}
                                            isClearable={false}
                                            value={modelState ? modelState : [{ label: 'State', value: '' }]}
                                            onChange={(e) => handleModelState(e)}
                                        />
                                        {modelStateErr && <span style={{ "color": "#BE312E" }}> Please select state</span>} */}
              


                                       
                                          
                                           
                                          {/* <Select
                                           options={getstatecityReg ? [{ label: 'State', value: '' }].concat(getstatecityReg.data.map(e => ({ label: e.state, value: e._id }))) : []}
                                           placeholder={"Country"}
                                           value={state == "" ? [{ label: 'State', value: '' }] : state ? [{ label: state33, value: state }] : [{ label: 'State', value: '' }]}
                                            onChange={(e) => setcity(e)}
                                           /> */}
                                          

                                    
                            {/* <select onChange={handleCity} >
                                   
                                   {st && st.map((e)=>
                                   <option>{e}</option>                                  
                                     )}

                             </select>  */}
                             
                              {/* <Select
                                defaultValue={"Country"}
                                onChange={HandleCountryChange}
                                options={countryi.map((e) => ({
                                  label: e.countryy,
                                  // value: e.countryy,
                                }))}
                              />
                              <Select
                                defaultValue={"State"}
                                onChange={handleCity}
                                options={ st&&st.map((e) => ({
                                  label: e,
                                  value: e,
                                }))}
                              /> */}


                                

                            </div>

                            {/* <div class="form-group col-sm-12">
                              <label>Description:</label>
                              <textarea class="form-control" rows="5" value={content} onChange={(e) => handleChange(e)}
                                style={{ lineHeight: '22px' }}>

                              </textarea>
                              <p className='errorr'>{contentError2}</p>

                            </div> */}
                          </div>
                          <button
                            className="btn btn-primary mr-2"
                            onClick={() => handleSubmit()}
                          >
                            Submit
                          </button>
                          <button
                            className="btn iq-bg-danger"
                            onClick={() => handleMessage()}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Add;
