import {
  pagesAddReducer, LoginReducer, pagesListReducer, pagesStatusReducer, pagesEditReducer, productListReducer,
  productAddReducer, productEditReducer, productStatusReducer, getPriceRangeReducer,
  dealsCategoryEditReducer, dealsCategoryAddReducer, getDealsReducer,
  getfabricCategoryReducer, getAllCategoriesTitleReducer, ourStoryTeamListReducer,
  ourTeamStatusReducer, ourTeamAddReducer, ourTeamEditReducer, dealsStatusReducer, febricCategoryAddReducer,
  getfebricCategoryListReducer, contactReducer, userInquiryGetReducer, contactAddReducer, contactEditReducer, contactStatusReducer,
  typeOfFabricsStatusReducer,
  fabricCategoryEditReducer, priceRangeAddReducer, InqueryListReducer, homePagesStatusReducer,
  getpriceRangeListReducer,
  pricestatusReducer,
  priceRangeEditReducer,
  homepagesAddReducer,
  homepagesEditReducer,
  getAllHomePagesReducer,
  addTestimonialsReducer,
  TestimonialslistReducer,
  editTestimonialReducer,
  TestimonialsstatusReducer,
  userRegisterReducer,
  allAdminlistReducer, ourTeamSequenceReducer, adminstatusReducer, pages_sequence_listReducer,
  environmentalSectionReducer,
  environmentalSectioneditReducer,
  forgotPasswordOtpSendInEmailReducer,
  OtpMatchReducer,
  passwordChangeReducer, productdetailsReducer, product_image_deleteReducer, product_size_editReducer, product_size_addReducer, accessDatadetailsReducer,
  downloadInquiriesReducer,
  getstatecityReg,
  countrylistReg


} from "../../OtherFiles/Constant";

let defaultState = {
  pagesReducer: null,
  LoginReducer: null,
  pagesListReducer: null,
  pagesStatusReducer: null,
  pagesEditReducer: null,
  productListReducer: null,
  productAddReducer: null,
  productEditReducer: null,
  productStatusReducer: null,
  getAllCategoriesTitleReducer: null,
  getfabricCategoryReducer: null,
  getPriceRangeReducer: null,
  ourStoryTeamListReducer: null,
  ourTeamStatusReducer: null,
  ourTeamAddReducer: null,
  ourTeamEditReducer: null,
  getDealsReducer: null,
  dealsCategoryAddReducer: null,
  dealsCategoryEditReducer: null,
  dealsStatusReducer: null,
  febricCategoryAddReducer: null,
  getfebricCategoryListReducer: null,
  typeOfFabricsStatusReducer: null,
  fabricCategoryEditReducer: null,
  contactReducer: null,
  userInquiryGetReducer: null,
  contactAddReducer: null,
  contactEditReducer: null,
  contactStatusReducer: null,
  priceRangeAddReducer: null,
  getpriceRangeListReducer: null,
  pricestatusReducer: null,
  priceRangeEditReducer: null,
  homepagesAddReducer: null,
  homepagesEditReducer: null,
  getAllHomePagesReducer: null,
  InqueryListReducer: null,
  homePagesStatusReducer: null,
  addTestimonialsReducer: null,
  TestimonialslistReducer: null,
  editTestimonialReducer: null,
  userRegisterReducer: null,
  allAdminlistReducer: null,
  TestimonialsstatusReducer: null,
  ourTeamSequenceReducer: null,
  adminstatusReducer: null,
  pages_sequence_listReducer: null,
  TestimonialsstatusReducer: null,
  ourTeamSequenceReducer: null,
  adminstatusReducer: null,
  environmentalSectionReducer: null,
  environmentalSectioneditReducer: null,
  forgotPasswordOtpSendInEmailReducer: null,
  OtpMatchReducer: null,
  passwordChangeReducer: null,
  productdetailsReducer: null,
  product_image_deleteReducer: null, product_size_editReducer: null, product_size_addReducer: null, accessDatadetailsReducer: null, downloadInquiriesReducer: null,
  //added by mansi
  getstatecityReg:null





};

const globalReducer = (state = defaultState, action) => {
  switch (action.type) {
    case pagesAddReducer:
      return Object.assign({}, state, {
        pagesAddReducer: action.payload,
      });
    case LoginReducer:
      return Object.assign({}, state, {
        LoginReducer: action.payload,
      });
    case pagesListReducer:
      return Object.assign({}, state, {
        pagesListReducer: action.payload,
      });
    case pagesStatusReducer:
      return Object.assign({}, state, {
        pagesStatusReducer: action.payload,
      });

    case pagesEditReducer:
      return Object.assign({}, state, {
        pagesEditReducer: action.payload,
      });

    case productListReducer:
      return Object.assign({}, state, {
        productListReducer: action.payload,
      });
    case productAddReducer:
      return Object.assign({}, state, {
        productAddReducer: action.payload,
      });
    case productEditReducer:
      return Object.assign({}, state, {
        productEditReducer: action.payload,
      });
    case productStatusReducer:
      return Object.assign({}, state, {
        productStatusReducer: action.payload,
      });
    case getAllCategoriesTitleReducer:
      return Object.assign({}, state, {
        getAllCategoriesTitleReducer: action.payload,
      });
    case getfabricCategoryReducer:
      return Object.assign({}, state, {
        getfabricCategoryReducer: action.payload,
      });
    case getDealsReducer:
      return Object.assign({}, state, {
        getDealsReducer: action.payload,
      });
    case getPriceRangeReducer:
      return Object.assign({}, state, {
        getPriceRangeReducer: action.payload,
      });
    case ourStoryTeamListReducer:
      return Object.assign({}, state, {
        ourStoryTeamListReducer: action.payload,
      });

    case ourTeamStatusReducer:
      return Object.assign({}, state, {
        ourTeamStatusReducer: action.payload,
      });

    case ourTeamAddReducer:
      return Object.assign({}, state, {
        ourTeamAddReducer: action.payload,
      });
    case ourTeamEditReducer:
      return Object.assign({}, state, {
        ourTeamEditReducer: action.payload,
      });

    case getDealsReducer:
      return Object.assign({}, state, {
        getDealsReducer: action.payload,
      });

    case dealsCategoryAddReducer:
      return Object.assign({}, state, {
        dealsCategoryAddReducer: action.payload,
      });


    case dealsCategoryEditReducer:
      return Object.assign({}, state, {
        dealsCategoryEditReducer: action.payload,
      });

    case dealsStatusReducer:
      return Object.assign({}, state, {
        dealsStatusReducer: action.payload,
      });


    //addedd by mansi vaghela
    case febricCategoryAddReducer:
      return Object.assign({}, state, {
        febricCategoryAddReducer: action.payload,
      });
    //addedd by mansi vaghela
    case getfebricCategoryListReducer:
      return Object.assign({}, state, {
        getfebricCategoryListReducer: action.payload,
      });

    //addedd by mansi vaghela
    case typeOfFabricsStatusReducer:
      return Object.assign({}, state, {
        typeOfFabricsStatusReducer: action.payload,
      });

    //addedd by mansi vaghela
    case fabricCategoryEditReducer:
      return Object.assign({}, state, {
        fabricCategoryEditReducer: action.payload,
      });

    case contactReducer:
      return Object.assign({}, state, {
        contactReducer: action.payload,
      });

    case userInquiryGetReducer:
      return Object.assign({}, state, {
        userInquiryGetReducer: action.payload,
      });
    case contactAddReducer:
      return Object.assign({}, state, {
        contactAddReducer: action.payload,
      });
    case contactEditReducer:
      return Object.assign({}, state, {
        contactEditReducer: action.payload,
      });
    case contactStatusReducer:
      return Object.assign({}, state, {
        contactStatusReducer: action.payload,
      });

    //addedd by mansi vaghela
    case priceRangeAddReducer:
      return Object.assign({}, state, {
        priceRangeAddReducer: action.payload,
      });

    //addedd by mansi vaghela
    case getpriceRangeListReducer:
      return Object.assign({}, state, {
        getpriceRangeListReducer: action.payload,
      });

    //addedd by mansi vaghela
    case pricestatusReducer:
      return Object.assign({}, state, {
        pricestatusReducer: action.payload,
      });

    //addedd by mansi vaghela
    case priceRangeEditReducer:
      return Object.assign({}, state, {
        priceRangeEditReducer: action.payload,
      });

    //addedd by mansi vaghela
    case homepagesAddReducer:
      return Object.assign({}, state, {
        homepagesAddReducer: action.payload,
      });

    //addedd by mansi vaghela
    case homepagesEditReducer:
      return Object.assign({}, state, {
        homepagesEditReducer: action.payload,
      });

    //addedd by mansi vaghela
    case getAllHomePagesReducer:
      return Object.assign({}, state, {
        getAllHomePagesReducer: action.payload,
      });

    case InqueryListReducer:
      return Object.assign({}, state, {
        InqueryListReducer: action.payload,
      });
    case homePagesStatusReducer:
      return Object.assign({}, state, {
        homePagesStatusReducer: action.payload,
      });



    //addedd by mansi vaghela 3/1/24
    case addTestimonialsReducer:
      return Object.assign({}, state, {
        addTestimonialsReducer: action.payload,
      });
    //addedd by mansi vaghela
    case TestimonialslistReducer:
      return Object.assign({}, state, {
        TestimonialslistReducer: action.payload,
      });

    //addedd by mansi vaghela
    case editTestimonialReducer:
      return Object.assign({}, state, {
        editTestimonialReducer: action.payload,
      });

    //addedd by mansi vaghela
    case TestimonialsstatusReducer:
      return Object.assign({}, state, {
        TestimonialsstatusReducer: action.payload,
      });



    //addedd by mansi vaghela 4/1/24
    case userRegisterReducer:
      return Object.assign({}, state, {
        userRegisterReducer: action.payload,
      });


    //addedd by mansi vaghela 5/1/24
    case allAdminlistReducer:
      return Object.assign({}, state, {
        allAdminlistReducer: action.payload,
      });

    //addedd by mansi vaghela
    case TestimonialsstatusReducer:
      return Object.assign({}, state, {
        TestimonialsstatusReducer: action.payload,
      });

    case ourTeamSequenceReducer:
      return Object.assign({}, state, {
        ourTeamSequenceReducer: action.payload,
      });

    //addedd by mansi vaghela
    case adminstatusReducer:
      return Object.assign({}, state, {
        adminstatusReducer: action.payload,
      });

    case pages_sequence_listReducer:
      return Object.assign({}, state, {
        pages_sequence_listReducer: action.payload,
      });

    //addedd by mansi vaghela 5/1/24
    case environmentalSectionReducer:
      return Object.assign({}, state, {
        environmentalSectionReducer: action.payload,
      });

    //addedd by mansi vaghela 5/1/24
    case environmentalSectioneditReducer:
      return Object.assign({}, state, {
        environmentalSectioneditReducer: action.payload,
      });

    //addedd by mansi vaghela 5/1/24
    case forgotPasswordOtpSendInEmailReducer:
      return Object.assign({}, state, {
        forgotPasswordOtpSendInEmailReducer: action.payload,
      });

    //addedd by mansi vaghela 9/1/24
    case OtpMatchReducer:
      return Object.assign({}, state, {
        OtpMatchReducer: action.payload,
      });


    //addedd by mansi vaghela 9/1/24
    case passwordChangeReducer:
      return Object.assign({}, state, {
        passwordChangeReducer: action.payload,
      });

    case productdetailsReducer:
      return Object.assign({}, state, {
        productdetailsReducer: action.payload,
      });

    case product_image_deleteReducer:
      return Object.assign({}, state, {
        product_image_deleteReducer: action.payload,
      });

    case product_size_editReducer:
      return Object.assign({}, state, {
        product_size_editReducer: action.payload,
      });

    case product_size_addReducer:
      return Object.assign({}, state, {
        product_size_addReducer: action.payload,
      });
    //addedd by mansi vaghela 12/1/24
    case accessDatadetailsReducer:
      return Object.assign({}, state, {
        accessDatadetailsReducer: action.payload,
      });

      case downloadInquiriesReducer:
      return Object.assign({}, state, {
        downloadInquiriesReducer: action.payload,
      });





      //added by mansi
      case countrylistReg:
        return Object.assign({}, state, {
          countrylistReg: action.payload
        });

      case getstatecityReg:
        return Object.assign({}, state, {
          getstatecityReg: action.payload
        });




    default:
      return state;
  }
};

export default globalReducer;
