import React, { useState, useEffect } from "react";

// import {
//   dispatchLoadingStatus,
//   ourStoryTeamList_API,
//   ourTeamStatus_API,
//   pagesList_API,
//   pagesStatus_API,
// } from "../../API/Action/globalAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";

import moment from "moment";
import Sidebar from "../../Sidebar/Sidebar";
import Loader from "../../Loader/Loader";
import { accessDatadetails_API, dispatchLoadingStatus, displaySwal, getpriceRangeList_API, pricestatus_API } from "../../../API/Action/globalAction";

function PriceRangeList() {
  let pagesListReducer = useSelector(
    (state) => state.globalReducer.pagesListReducer
  );

  let pagesStatusReducer = useSelector(
    (state) => state.globalReducer.pagesStatusReducer
  );
  let getpriceRangeListReducer = useSelector(
    (state) => state.globalReducer.getpriceRangeListReducer
  );
  let pricestatusReducer = useSelector(
    (state) => state.globalReducer.pricestatusReducer
  );

  // console.log("ourStoryTeamListReducer" , ourStoryTeamListReducer);

  let token = localStorage.getItem("token");
  const [page, setpage] = useState(0);
  const [indexCh, setIndexCh] = useState(0);
  const totalCount = getpriceRangeListReducer
    ? getpriceRangeListReducer.count
    : "";
  const itemsPerPage = 5;
  const totalPages = Math.ceil(totalCount / itemsPerPage);

  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page, count) => {
    setCurrentPage(page);
    Apicall((page - 1) * itemsPerPage);

    // You can implement fetching data for the new page here if needed
  };

  const renderPagination = () => {
    const pagesToShow = 5;
    const startPage = Math.max(1, currentPage - Math.floor(pagesToShow / 2));
    const endPage = Math.min(totalPages, startPage + pagesToShow - 1);

    const paginationItems = [];
    for (let i = startPage; i <= endPage; i++) {
      paginationItems.push(
        <li
          key={i}
          className={`page-item ${currentPage === i ? "active" : ""}`}
        >
          <a className="page-link point" onClick={() => handlePageChange(i)}>
            {i}
          </a>
        </li>
      );
    }

    return paginationItems.length > 0 ? (
      paginationItems
    ) : (
      <li className="page-item disabled">
        <span className="page-link">No pages</span>
      </li>
    );
  };

  let history = useNavigate();
  const dispatch = useDispatch({});

  function Apicall(start) {
    let formData = new FormData();

    // formData.append("token", token ? token : "");
    formData.append("start", start);
    formData.append("limit", 5);

    dispatch(dispatchLoadingStatus(true));
    dispatch(getpriceRangeList_API(formData));
  }

  let accessDatadetailsReducer = useSelector(
    (state) => state.globalReducer.accessDatadetailsReducer
  );

  const Login_Parse =  JSON.parse(accessDatadetailsReducer && accessDatadetailsReducer.data[0].accessData)
  console.log("Login_Parse_MY",Login_Parse);


  useEffect(() => {
    dispatch(dispatchLoadingStatus(true));
    let formData = new FormData();
    let formData2 = new FormData();
    const access_id = localStorage.getItem("user_idd")

    // formData.append("token", token ? token : "");
    formData.append("start", 0);
    formData.append("limit", 5);
    formData2.append("user_id",access_id)
    dispatch(accessDatadetails_API(formData2))
    dispatch(getpriceRangeList_API(formData));
  }, []);

  useEffect(() => {
    if (pagesStatusReducer) {
      // Apicall(0);
      Apicall((currentPage - 1) * itemsPerPage);
    }
  }, [pagesStatusReducer]);

  function toggleSwitch(e, i) {
    console.log("check", e.target.checked);

    var vall = i.status == 0 ? "active" : "inactive";
    {Login_Parse && Login_Parse?.PriceRangeEdit == true ? 
    swal({
      text: "Are you sure you want to " + vall + " this price range?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        let formData = new FormData();
        formData.append("status", e.target.checked == true ? "0" : "1");
        formData.append("pricerange_id", i.pricerange_id);
        dispatch(dispatchLoadingStatus(true));
        dispatch(pricestatus_API(formData, i.status));
      }
    }):displaySwal()}
  }

  useEffect(() => {
    if (pricestatusReducer) {
      // Apicall(0);
      Apicall((currentPage - 1) * itemsPerPage);

    }
  }, [pricestatusReducer]);

  return (
    <>
      <div className="wrapper">
        <Sidebar />
        <Loader />

        <div id="content-page" className="content-page">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="iq-card">
                  <div className="iq-card-header d-flex justify-content-between">
                    <div className="iq-header-title">
                      <h4 className="card-title">Price Range List</h4>
                    </div>
                    {/* <ul class="d-flex mt-3">
                        <li class="breadcrumb-item"><a href="#">Home</a></li>
                        <li class="breadcrumb-item active" aria-current="page">User List</li>
                      </ul> */}
                  </div>
                  <div className="iq-card-body">
                    <div className="table-responsive">
                      <div className="row justify-content-between">
                        <div className="col-sm-12 col-md-6">
                          <div
                            id="user_list_datatable_info"
                            className="dataTables_filter"
                          >
                            <form className="mr-3 position-relative">
                              <div className="form-group mb-0">
                                {/* <input type="search" className="form-control" id="exampleInputSearch" placeholder="Search" aria-controls="user-list-table" /> */}
                              </div>
                            </form>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                          <div className="user-list-files d-flex float-right">
                            <span className="table-add float-right mb-3 mr-2">
                              {/* <button
                                className="btn btn-sm iq-bg-success"
                                onClick={() => history("/PriceRangeAdd")}
                              >
                                <i className="ri-add-fill">
                                  <span className="pl-1">Add New</span>
                                </i>
                              </button> */}




  	                      {Login_Parse?.PriceRangeAdd == true ? 
                              <button className="btn btn-sm iq-bg-success" onClick={() => history('/PriceRangeAdd') }><i
                                className="ri-add-fill"><span className="pl-1">Add New</span></i>
                              </button>
                                  :
                                  <button className="btn btn-sm iq-bg-success" onClick={() => displaySwal() }><i
                                className="ri-add-fill"><span className="pl-1">Add New</span></i>
                              </button>
                                }   
                            </span>
                          </div>
                        </div>
                      </div>
                      <table
                        id="user-list-table"
                        className="table table-striped table-bordered mt-4"
                        role="grid"
                        aria-describedby="user-list-page-info"
                      >
                        <thead>
                          <tr>
                           
                            
                            
                            <th>Price</th>
                            <th>Status</th>
                            <th>Create at</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {getpriceRangeListReducer &&
                          getpriceRangeListReducer.data.length > 0 ? (
                            getpriceRangeListReducer.data.map((i) => (
                              <tr>
                                {/* <td className="text-center">
                                  <img
                                    className="rounded-circle img-fluid avatar-40"
                                    src={i.image}
                                    alt="profile"
                                  />
                                </td> */}
                                
                             <td>{i.range_of_price}</td>
                               
                                <td>
                                  <div className="switch-container">
                                    <label className="switch-label">
                                      <input
                                        type="checkbox"
                                        key={i.id}
                                        className="switch-input"
                                        checked={i.status}
                                        defaultChecked={i.status}
                                        onClick={(e) => toggleSwitch(e, i)}
                                      />
                                      <span className="switch-slider"></span>
                                    </label>
                                  </div>
                                </td>

                                {/* <td> Admin</td> */}
                                <td>
                                  {i.created_at
                                    ? moment(i.created_at).format("MM/DD/YYYY")
                                    : ""}
                                </td>

                                <td>
                                  <div className="flex align-items-center list-user-action">
                                  {Login_Parse?.PriceRangeEdit == true ? 
                                  <a data-toggle="tooltip" data-placement="top" title="" data-original-title="Edit" onClick={() => history('/PriceRangeAdd', { state: i })}><i className="ri-pencil-line point"></i></a>
                                  :
                                  <a data-toggle="tooltip" data-placement="top" title="" data-original-title="Edit" onClick={() => displaySwal()}><i className="ri-pencil-line point"></i></a>
                                  }
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : <tr className='bg-white border-0'> <td colSpan={4} className='border-0'> <div className='col-md-12 w-100 mt-4'><p className='text-center'>No Data Found</p> </div></td></tr>}
                        </tbody>
                      </table>
                    </div>

                    {/* {(() => {
                                const pages = [];

                                for (let i = 1; i < (pagesListReducer.count / 20) + 1; i++) {
                                  pages.push(<li className={page == i ? "page-item active" : "page-item"} onClick={() => { Apicall((i - 1) * 20); setpage(i); setIndexCh(indexCh + 1) }}><a className="page-link" >{i}  </a>  </li>)
                                }

                                return pages;
                              })()} */}
                    {/* {pagesListReducer ? pagesListReducer.count > 0 ? */}

                    <div className="row justify-content-between mt-3">
                      <div id="user-list-page-info" className="col-md-6">
                        {getpriceRangeListReducer &&
                        getpriceRangeListReducer.count ? (
                          <span>
                            Showing {page <= 1 ? 1 : (page - 1) * 5 + 1} to{" "}
                            {page * 5 <= getpriceRangeListReducer &&
                            getpriceRangeListReducer.count
                              ? page * 5
                              : getpriceRangeListReducer &&
                                getpriceRangeListReducer.count}{" "}
                            of{" "}
                            {getpriceRangeListReducer
                              ? getpriceRangeListReducer.count
                              : ""}{" "}
                            entries
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                      {/* <div className="col-md-6">
                          <nav aria-label="Page navigation example">
                            <ul className="pagination justify-content-end mb-0">
                              <li className="page-item disabled">
                                <a className="page-link" href="#" tabindex="-1" aria-disabled="true">Previous</a>
                              </li>
                          
                              <li className={"page-item active"} ><a className="page-link" >1  </a>  </li>
                             

                              <li className="page-item">
                                <a className="page-link" href="#">Next</a>
                              </li>
                            </ul>
                          </nav>
                        </div> */}

                      {/* {pagesListReducer && pagesListReducer.length > 5 ? */}
                      <div className="col-md-6">
                        <nav aria-label="Page navigation example">
                          <ul className="pagination justify-content-end mb-0">
                            <li
                              className={`page-item point ${
                                currentPage === 1 ? "disabled" : ""
                              }`}
                            >
                              <a
                                className="page-link"
                                onClick={() =>
                                  handlePageChange(currentPage - 1, "less")
                                }
                                tabIndex="-1"
                                aria-disabled="true"
                              >
                                Previous
                              </a>
                            </li>

                            {renderPagination()}

                            <li
                              className={`page-item point ${
                                currentPage === totalPages ? "disabled" : ""
                              }`}
                            >
                              <a
                                className="page-link"
                                onClick={() =>
                                  handlePageChange(currentPage + 1)
                                }
                                disabled={currentPage === totalPages}
                              >
                                Next
                              </a>
                            </li>
                          </ul>
                        </nav>
                      </div>

                      {/* : ""} */}
                    </div>
                    {/* : null : null} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PriceRangeList;
