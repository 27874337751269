//Staging URL 
export const baseURL = "https://stagingapi.thesustainablefabrics.com/api/";

//LIVE URL 
// export const baseURL = "";


export const IS_LOADING = "IS_LOADING";

export const pagesAdd_URL = "pages_Add";
export const pagesAddReducer = "pagesAddReducer";

export const pagesEdit_URL = "pages_Edit";
export const pagesEditReducer = "pagesEditReducer";

export const Login_URL = "loginUser";
export const LoginReducer = "LoginReducer";

export const pagesList_URL = "getAllPages";
export const pagesListReducer = "pagesListReducer";

export const pagesStatus_URL = "pages_status";
export const pagesStatusReducer = "pagesStatusReducer";


export const productList_URL = "getAllProducts";
export const productListReducer = "productListReducer";

export const productAdd_URL = "product_add";
export const productAddReducer = "productAddReducer";

export const productEdit_URL = "ProductsEdit";
export const productEditReducer = "productEditReducer";

export const productStatus_URL = "product_status";
export const productStatusReducer = "productStatusReducer";

export const getAllCategoriesTitle_URL = "getAllCategoriesTitle";
export const getAllCategoriesTitleReducer = "getAllCategoriesTitleReducer";

export const getfabricCategory_URL = "fabricCategory_Get";
export const getfabricCategoryReducer = "getfabricCategoryReducer";


export const getPriceRange_URL = "priceRange_Get";
export const getPriceRangeReducer = "getPriceRangeReducer";

export const ourStoryTeamList_URL = "ourTeam_Get";
export const ourStoryTeamListReducer = "ourStoryTeamListReducer";

export const ourTeamStatus_URL = "ourTeam_status";
export const ourTeamStatusReducer = "ourTeamStatusReducer";

export const ourTeamEdit_URL = "ourTeam_Edit";
export const ourTeamEditReducer = "ourTeamEditReducer";

export const ourTeamAdd_URL = "ourTeam_Add";
export const ourTeamAddReducer = "ourTeamAddReducer";

export const getDeals_URL = "pricedeals_Get";
export const getDealsReducer = "getDealsReducer";

export const dealsCategoryAdd_URL = "pricedeals_Add";
export const dealsCategoryAddReducer = "dealsCategoryAddReducer";

export const dealsCategoryEdit_URL = "pricedeals_Edit";
export const dealsCategoryEditReducer = "dealsCategoryEditReducer";

export const dealsStatus_URL = "deals_status";
export const dealsStatusReducer = "dealsStatusReducer";

export const homePagesStatus_URL = "homepages_status";
export const homePagesStatusReducer = "homePagesStatusReducer";



//added by mansi vaghela
export const febricCategoryAdd_URL = "fabricCategory_Add";
export const febricCategoryAddReducer = "febricCategoryAddReducer";

//added by mansi vaghela
export const getfebricCategoryList_URL = "fabricCategory_Get";
export const getfebricCategoryListReducer = "getfebricCategoryListReducer";

//added by mansi vaghela
export const typeOfFabricsStatus_URL = "typeOfFabrics_status";
export const typeOfFabricsStatusReducer = "typeOfFabricsStatusReducer";

//added by mansi vaghela
export const fabricCategoryEdit_URL = "fabricCategory_Edit";
export const fabricCategoryEditReducer = "fabricCategoryEditReducer";



export const contactList_URL = "contactdetail_Get";
export const contactReducer = "contactReducer";

export const userInquiryGet_URL = "userInquiryGet";
export const userInquiryGetReducer = "userInquiryGetReducer";

export const contactAdd_URL = "contactdetail_Add";
export const contactAddReducer = "contactAddReducer";

export const contactEdit_URL = "contactdetail_Edit";
export const contactEditReducer = "contactEditReducer";

export const contactStatus_URL = "contactdetail_status";
export const contactStatusReducer = "contactStatusReducer";




//added by mansi vaghela
export const priceRangeAdd_URL = "priceRange_Add";
export const priceRangeAddReducer = "priceRangeAddReducer";

//added by mansi vaghela
export const getpriceRangeList_URL = "priceRange_Get";
export const getpriceRangeListReducer = "getpriceRangeListReducer";

//added by mansi vaghela
export const pricestatus_URL = "price_status";
export const pricestatusReducer = "pricestatusReducer";

//added by mansi vaghela
export const priceRangeEdit_URL = "priceRange_Edit";
export const priceRangeEditReducer = "priceRangeEditReducer";

//added by mansi vaghela
export const homepagesAdd_URL = "homepages_add";
export const homepagesAddReducer = "homepagesAddReducer";

//added by mansi vaghela
export const homepagesEdit_URL = "homepages_Edit";
export const homepagesEditReducer = "homepagesEditReducer";

//added by mansi vaghela
export const getAllHomePages_URL = "getAllHomePages";
export const getAllHomePagesReducer = "getAllHomePagesReducer";

export const InqueryList_URL = "user_InquiryGet";
export const InqueryListReducer = "InqueryListReducer";



//added by mansi vaghela 3/1/23
export const addTestimonials_URL = "addTestimonials";
export const addTestimonialsReducer = "addTestimonialsReducer";

//added by mansi vaghela 3/1/23
export const Testimonialslist_URL = "Testimonials_list";
export const TestimonialslistReducer = "TestimonialslistReducer";

//added by mansi vaghela
export const editTestimonial_URL = "editTestimonial";
export const editTestimonialReducer = "editTestimonialReducer";

//added by mansi vaghela
export const Testimonialsstatus_URL = "Testimonials_status";
export const TestimonialsstatusReducer = "TestimonialsstatusReducer";




//added by mansi vaghela 4/1/24
export const userRegister_URL = "userRegister";
export const userRegisterReducer = "userRegisterReducer";

//added by mansi vaghela 5/1/24
export const allAdminlist_URL = "allAdminlist";
export const allAdminlistReducer = "allAdminlistReducer";

export const ourTeamSequenceURL = "ourTeam_sequence_list";
export const ourTeamSequenceReducer = "ourTeamSequenceReducer";

export const userEdit_URL = "userEdit";
export const userEditReducer = "userEditReducer";

//added by mansi vaghela
export const adminstatus_URL= "admin_status";
export const adminstatusReducer = "adminstatusReducer";

export const pages_sequence_list_URL = "pages_sequence_list";
export const pages_sequence_listReducer = "pages_sequence_listReducer";


//added by mansi vaghela 08/01/24
export const environmentalSectionedit_URL = "environmentalSection_edit";
export const environmentalSectioneditReducer = "environmentalSectioneditReducer";

//added by mansi vaghela 5/1/24
export const environmentalSectionlist_URL = "environmentalSection_list";
export const environmentalSectionReducer = "environmentalSectionReducer";

export const forgotPasswordOtpSendInEmail_URL = "forgotPasswordOtpSendInEmail";
export const forgotPasswordOtpSendInEmailReducer = "forgotPasswordOtpSendInEmailReducer";

export const OtpMatch_URL = "OtpMatch";
export const OtpMatchReducer = "OtpMatchReducer";

export const passwordChange_URL = "passwordChange";
export const passwordChangeReducer = "passwordChangeReducer";

export const productdetails_URL = "productdetails";
export const productdetailsReducer = "productdetailsReducer";

export const product_image_delete_URL = "product_image_delete";
export const product_image_deleteReducer = "product_image_deleteReducer";


export const product_size_add_URL = "product_size_add";
export const product_size_addReducer = "product_size_addReducer";


export const product_size_edit_URL = "product_size_edit";
export const product_size_editReducer = "product_size_editReducer";

export const accessDatadetails_URL = "accessDatadetails";
export const accessDatadetailsReducer = "accessDatadetailsReducer";

export const downloadInquiries_URL = "downloadInquiries";
export const downloadInquiriesReducer = "downloadInquiriesReducer";

//added by mansi

export const countrylist_URL = "country_list"
export const countrylistReg = "countrylistReg";


export const getstate_URL = "state_list"
export const getstatecityReg = "getstatecityReg";
