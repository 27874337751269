import React, { useEffect, useState } from "react";

// import { CKEditor } from 'ckeditor4-react';
import CKEditor from "react-ckeditor-component";
import { ReactComponent as CloseSvg } from "../../Assets/Images/icons8-no.svg";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import swal from "sweetalert";
import Loader from "../Loader/Loader";
import Sidebar from "../Sidebar/Sidebar";
import {
  addTestimonials_API,
  dispatchLoadingStatus,
  editTestimonial_API,
  homepagesAdd_API,
  homepagesEdit_API,
  pagesEdit_API,
} from "../../API/Action/globalAction";

function PagesAdd(props) {
  const [propsValue, setPropsValue] = useState();

  const dispatch = useDispatch({});
  let history = useNavigate();

  // let statelist = useSelector(state => state.globalReducer.getstatecityReg);

  let htmlin = `<h3>The Sustainable Fabrics is not just a company, it is a mindset - <b> to create a Sustainable Future. </b> We are committed to building the most customer-centric sustainable solution for all fabric needs. <b> Join us in this movement and help us take you in the right direction!” </b></h3>`;

  const [fname, setFname] = useState("");

  const [content, setContent] = useState(htmlin);

  const [link, setLink] = useState();
  const [role, setRole] = useState("");

  const [roleError, setRoleError] = useState(false);
  const [roleError2, setRoleError2] = useState("");

  const [linkError, setLinkError] = useState(false);
  const [linkError2, setLinkError2] = useState("");

  const [nameError, setNameError] = useState(false);
  const [nameError2, setNameError2] = useState("");

  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);

  const [selectedFileError, setSelectedFileError] = useState(false);
  const [selectedFileError2, setSelectedFileError2] = useState("");

  const [contentError, setContentError] = useState(false);
  const [contentError2, setContentError2] = useState("");

  function handleMessage() {
    swal({
      text: "Are you sure you want to leave this page?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        window.location.href = window.location.origin + "/TestimonialList";
      } else {
        console.log("Cancel press");
      }
    });
  }

  useEffect(() => {
    if (props.data) {
      console.log("myprops", props.data);
      setPropsValue(props.data);
      setFname(props.data.name);
      setSelectedFile(props.data.image);
      setContent(props.data.short_description);
      setRole(props.data.role);
    }
  }, [props]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    setSelectedFile2(null);

    if (file) {
      if (
        event.target.files[0].name.toLowerCase().includes("jpg") ||
        event.target.files[0].name.toLowerCase().includes("png") ||
        event.target.files[0].name.toLowerCase().includes("jpeg")
      ) {
        setSelectedFile(file);
        setSelectedFileError2("");
      } else {
        setSelectedFileError2(
          "The image must be a file of type: jpg, png, jpeg"
        );
      }
    } else {
      setSelectedFileError2("");
    }
  };

  const handleRemoveFile = () => {
    document.getElementById("image").value = "";

    setSelectedFile(null);
    setSelectedFile2(null);
  };

  let nameRe = /^[A-Za-z]( ?[A-Za-z] ?)*$/;


  function handleFname(e) {
    const result = /^[A-Za-z]( ?[A-Za-z] ?)*$/;

    if (e.target.value) {
      if (e.target.value === "" || result.test(e.target.value)) {
        setFname(e.target.value);
        setNameError2("");
        setNameError(false);
      } else {
        setNameError2("pls enter Name");
        setNameError(true);
      }
    } else {
      setFname("");
      setNameError(true);
      setNameError2("pls enter");
    }
  }

  function handleRole(e) {


    const result = /^[A-Za-z]( ?[A-Za-z] ?)*$/;

    if (e.target.value) {
      if (e.target.value === "" || result.test(e.target.value)) {
        setRole(e.target.value);
        setRoleError2("");
        setRoleError(false);
      } else {
        setRoleError2("pls enter Role");
        setRoleError(true);
      }
    } else {
      setRole("");
      setRoleError(true);
      setRoleError2("pls enter Role");
    }
  }


  const handleChange = (evt) => {
    const newContent = evt.editor.getData();

    setContent(newContent);

    if (newContent) {
      setContentError(false);
      setContentError2("");
    } else {
      setContentError(true);
      setContentError2("Please enter description");
    }
  };

  // const handleChange = (evt) => {
  //   setContent(evt.target.value.trim("") == "" ? "" : evt.target.value);
  //   //  setContent(evt);

  //   if (evt) {
  //     setContentError(false);
  //     setContentError2("");
  //   } else {
  //     setContentError(true);
  //     setContentError2("Please enter description");
  //   }
  // };

  function handleSubmit() {
    if (fname) {
      if (fname.length >= 2) {
        // setNameError2("xa");
        if (!nameRe.test(fname)) {
          setNameError2("Please enter valid  name");
        } else {
          setNameError(false);
          setNameError2("");
        }
      } else {
        setNameError(true);
        setNameError2("Please enter valid  name");
      }
    } else {
      setNameError(true);
      setNameError2("Please enter  name");
    }



    if (content) {
      setContentError2("");
    } else {
      setContentError2("Please enter description");
    }

   
    if (selectedFile) {
      setSelectedFileError2("");
    } else {
      setSelectedFileError2("Please select image");
    }

    if (role) {
      if (role.length >= 2) {
        // setNameError2("xa");
        if (!nameRe.test(role)) {
          setRoleError2("Please enter valid role");
        } else {
          setRoleError(false);
          setRoleError2("");
        }
      } else {
        setRoleError(true);
        setRoleError2("Please enter valid role");
      }
    } else {
      setRoleError(true);
      setRoleError2("Please enter  role");
    }

    if (
      nameError == false &&
      selectedFileError == false &&
      contentError == false &&
      roleError == false &&
      fname &&
      content &&
      role &&
      selectedFile
    ) {
      let formData = new FormData();
      formData.append("image", selectedFile ? selectedFile : "");
      formData.append("short_description", content ? content : "");
      formData.append("name", fname);
      formData.append("role", role);

      let data = propsValue
        ? propsValue.testimonials_id
          ? propsValue.testimonials_id
          : ""
        : "";
      if (data) {
        console.log("dataaaaaaaaaaa", data);

        formData.append("testimonials_id", data);

        dispatch(editTestimonial_API(formData));
        dispatch(dispatchLoadingStatus(true));
      } else {
        dispatch(addTestimonials_API(formData));
        dispatch(dispatchLoadingStatus(true));
      }
    }
  }
  return (
    <>
      <div className="wrapper">
        <Loader />
        <Sidebar />

        <div id="content-page" className="content-page">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="iq-edit-list-data">
                  <div className="tab-content">
                    <div
                      className="tab-pane fade active show"
                      id="personal-information"
                      role="tabpanel"
                    >
                      <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between">
                          <div className="iq-header-title">
                            <h4 className="card-title">
                              {" "}
                              {propsValue
                                ? propsValue.testimonials_id
                                  ? "Testimonial Edit"
                                  : "Testimonial Add"
                                : "Testimonial Add"}
                            </h4>
                          </div>

                          <ul class="d-flex mt-3">
                            <li class="breadcrumb-item">
                              <a href="/TestimonialList">Testimonial List</a>
                            </li>
                            <li
                              class="breadcrumb-item active"
                              aria-current="page"
                            >
                              {propsValue
                                ? propsValue.testimonials_id
                                  ? "Testimonial Edit"
                                  : "Testimonial Add"
                                : "Testimonial Add"}
                            </li>
                          </ul>
                        </div>
                        <div className="iq-card-body">
                          <div className=" row">
                            <div className="form-group col-sm-6">
                              <label htmlFor="fname">Name:</label>
                              <input
                                type="text"
                                className="form-control"
                                value={fname}
                                maxLength={100}
                                onChange={(e) => handleFname(e)}
                              />
                              <p className="errorr">{nameError2}</p>
                            </div>

                            <div className="form-group col-sm-6">
                              <div className="wrap">
                                <label className="form-label">
                                  Upload Image:
                                </label>
                                <form>
                                  <div className="file">
                                    <div
                                      className="file__input"
                                      id="file__input"
                                    >
                                      <input
                                        className="file__input--file point"
                                        type="file"
                                        id="image"
                                        onChange={handleFileChange}
                                      />
                                      <label
                                        className="file__input--label"
                                        accept=".png, .jpg .jpeg"
                                        htmlFor="customFile"
                                        data-text-btn="Choose Files"
                                      >
                                        Add image:
                                      </label>
                                    </div>
                                  </div>
                                </form>
                                <p className="errorr">{selectedFileError2}</p>

                                {selectedFile && (
                                  <div
                                    className="choosed-files"
                                    style={{ position: "relative" }}
                                  >
                                    {/* <p>Selected File: {selectedFile.name}</p> */}
                                    <img
                                      src={
                                        typeof selectedFile === "string"
                                          ? selectedFile
                                          : URL.createObjectURL(selectedFile)
                                      }
                                      alt="Selected Preview"
                                      style={{
                                        maxWidth: "100%",
                                        maxHeight: "200px",
                                      }}
                                    />

                                    <CloseSvg
                                      className="point"
                                      width={18}
                                      height={18}
                                      onClick={() => handleRemoveFile()}
                                      style={{
                                        zIndex: "99",
                                        position: "absolute",
                                        right: "8px",
                                        top: "8px",
                                      }}
                                    />
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="form-group col-sm-6">
                              <label htmlFor="fname">Role:</label>
                              <input
                                type="text"
                                className="form-control"
                                value={role}
                                maxLength={50}
                                onChange={(e) => handleRole(e)}
                              />
                              <p className="errorr">{roleError2}</p>
                            </div>

                            {/* <div class="form-group col-sm-12">
                              <label>Description:</label>
                              <textarea
                                class="form-control"
                                rows="5"
                                value={content}
                                onChange={(e) => handleChange(e)}
                                style={{ lineHeight: "22px" }}
                              ></textarea>
                              <p className="errorr">{contentError2}</p>
                            </div> */}



<div className="form-group col-sm-12">
                              <label>Long Description:</label>
                              <CKEditor
                                activeclassName="p10"
                                content={content}
                                events={{
                                  change: handleChange,
                                }}
                                config={{ allowedContent: true }}
                              />

                              <p className="errorr">{contentError2}</p>

                              {/* <textarea className="form-control" name="address" rows="5" value={longDes} onChange={(e) => {console.log("textarea:-" , e.target.value);  setLongDes(e.target.value) }} style={{lineHeight : '22px'}}/> */}
                            </div>

                          </div>
                          <button
                            className="btn btn-primary mr-2"
                            onClick={() => handleSubmit()}
                          >
                            Submit
                          </button>
                          <button
                            className="btn iq-bg-danger"
                            onClick={() => handleMessage()}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PagesAdd;
