import React, { useState, useEffect } from 'react';
import Sidebar from '../Sidebar/Sidebar';
import { ProductList_API, dispatchLoadingStatus, getDeals_API, getPriceRange_API, getfabricCategory_API, pagesList_API, pagesStatus_API, productStatus_API } from '../../API/Action/globalAction';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import swal from 'sweetalert';
import Loader from '../Loader/Loader';
import Select from 'react-select';
import { productAddReducer, productEditReducer } from '../../OtherFiles/Constant';

function List() {
  let range = [{ label: "5", value: "5" }, { label: "10", value: "10" }, { label: "25", value: "25" }, { label: "50", value: "50" }, { label: "100", value: "100" },];
  const [sendRange22, setSendRange22] = useState({ label: "5", value: "5" });

  
  const [sendFebric, setSendFebric] = useState();
  const [sendDeals, setSendDeals] = useState();
  const [sendRange, setSendRange] = useState();

  const [febricCategory, setFebricCategory] = useState();
  const [dealCategory, setDealCategory] = useState();
  const [rangeCategory, setRangeCategory] = useState();

  let getfabricCategoryReducer = useSelector(state => state.globalReducer.getfabricCategoryReducer);
  let getDealsReducer = useSelector(state => state.globalReducer.getDealsReducer);
  let getPriceRangeReducer = useSelector(state => state.globalReducer.getPriceRangeReducer);

  useEffect(() => {
    if (getfabricCategoryReducer) {
      if (getfabricCategoryReducer.errorcode == 0) {
        setFebricCategory(getfabricCategoryReducer.data);

      }
      else {
        setFebricCategory([]);

      }

    }

  }, [getfabricCategoryReducer])

  let fabricCat = febricCategory && febricCategory.filter((i) => i.status == 1)

  let dealCat = dealCategory && dealCategory.filter((i) => i.status == 1)

	let rangeCat = rangeCategory && rangeCategory.filter((i) => i.status == 1)

  useEffect(() => {
    if (getPriceRangeReducer) {
      if (getPriceRangeReducer.errorcode == 0) {


        setRangeCategory(getPriceRangeReducer.data);

      }
      else {
        setRangeCategory([]);

      }

    }

  }, [getPriceRangeReducer])

  useEffect(() => {
    if (getDealsReducer) {
      if (getDealsReducer.errorcode == 0) {
        setDealCategory(getDealsReducer.data);

      }
      else {
        setDealCategory([]);

      }

    }

  }, [getDealsReducer])
  let productListReducer = useSelector(state => state.globalReducer.productListReducer);
  console.log("productListReducer" , productListReducer);

  let productStatusReducer = useSelector(state => state.globalReducer.productStatusReducer);
  let token = localStorage.getItem("token");
  const [page, setpage] = useState(0);
  const [indexCh, setIndexCh] = useState(0);
  const totalCount = productListReducer ? productListReducer.count : "";
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const totalPages = Math.ceil(totalCount / itemsPerPage);
  console.log("itemsPerPage" , itemsPerPage && itemsPerPage);

  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page, count) => {
    setCurrentPage(page);

    Apicall((page - 1) * itemsPerPage, sendRange22 && sendRange22.value)


    // You can implement fetching data for the new page here if needed
  };

  function handleRange(e) {
    let data = e.value;
    setSendRange22(e);

    if (data) {
      Apicall(0, data);
      setItemsPerPage(data)
      setCurrentPage(1);

    }


  }
  const renderPagination = () => {
    const pagesToShow = 5;
    const startPage = Math.max(1, currentPage - Math.floor(pagesToShow / 2));
    const endPage = Math.min(totalPages, startPage + pagesToShow - 1);

    const paginationItems = [];
    for (let i = startPage; i <= endPage; i++) {
      paginationItems.push(
        <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
          <a className="page-link point" onClick={() => handlePageChange(i)}>
            {i}
          </a>
        </li>
      );
    }

    return paginationItems.length > 0 ? paginationItems : <li className="page-item disabled"><span className="page-link">No pages</span></li>;
  };




  let history = useNavigate();
  const dispatch = useDispatch({

  });


useEffect(()=> {
  Apicall(0)

},[sendDeals])
useEffect(()=> {
  Apicall(0)

},[sendFebric])
useEffect(()=> {
  Apicall(0)

},[sendRange])

  function Apicall(start, data) {
    let formData = new FormData();

    formData.append('token', token ? token : "");
    formData.append("start", start);
    formData.append("limit", data ? data : "5");
    // formData.append("fabricCategory_id", sendFebric ? sendFebric.value : "");
    formData.append("deals_id", sendDeals ? sendDeals.value : "");
    formData.append("fabricCategory_id", sendFebric ? sendFebric.value : "");
    formData.append("price", sendRange ? sendRange.label : "");

    

    dispatch(dispatchLoadingStatus(true))
    dispatch(ProductList_API(formData));


  }
  useEffect(() => {
    dispatch(dispatchLoadingStatus(true))
    let formData = new FormData();

    // formData.append('token', token ? token : "");
    formData.append("start", 0);
    formData.append("limit", sendRange22 && sendRange22.value);
    formData.append("deals_id", "");
    formData.append("fabricCategory_id", "");
    formData.append("pricerange_id", "");


    dispatch(ProductList_API(formData))
    dispatch(getfabricCategory_API());
    dispatch(getDeals_API());
    dispatch(getPriceRange_API());

  }, [])



  useEffect(() => {
    if (sendFebric) {
      Apicall(0);
    }
  }, [sendFebric])


  function toggleSwitch(e, i , k) {

    console.log("check", e.target.checked);

    var vall = k.status == 0 ? "active" : "inactive";

    swal({
      text: "Are you sure you want to " + vall + " this product?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          let formData = new FormData();
          formData.append("status", e.target.checked == true ? "0" : "1");
          formData.append("product_id", i);
          dispatch(dispatchLoadingStatus(true));
          dispatch(productStatus_API(formData, k.status));

        }
      });


  }

  useEffect(() => {
    if (productStatusReducer) {
      Apicall((page - 1) * itemsPerPage, sendRange22 && sendRange22.value)
    }
  }, [productStatusReducer])



  return (
    <>
      <div className="wrapper">

        <Sidebar />
        <Loader />

        <div id="content-page" className="content-page">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="iq-card">
                  <div className="iq-card-header d-flex justify-content-between">
                    <div className="iq-header-title">
                      <h4 className="card-title">Products List</h4>
                    </div>
                    {/* <ul class="d-flex mt-3">
                        <li class="breadcrumb-item"><a href="#">Home</a></li>
                        <li class="breadcrumb-item active" aria-current="page">User List</li>
                      </ul> */}
                  </div>
                  <div className="iq-card-body">
                    <div className="table-responsive">
                      <div className="row justify-content-between">
                      <div className="col-sm-12">
                          <div className="user-list-files d-flex float-right">
                            <span className="table-add float-right mb-3 mr-2">
                              <button className="btn btn-sm iq-bg-success" onClick={() => window.location.href = window.location.origin + '/ProductAdd' }><i
                                className="ri-add-fill"><span className="pl-1">Add New Product</span></i>
                              </button>
                            </span>
                          </div>
                        </div>
                        <div className='col-md-3'>
                        <div className="form-group mb-0">
                                <label htmlFor="fname">Type of Fabric</label>
                                <Select className='w-100'
                                  options={fabricCat ? fabricCat.map((dataItem) => ({
                                    value: dataItem.fabricCategory_id,
                                    label: dataItem.type_of_category,
                                  })) : [{ label: "Select", value: "" }]}
                                  placeholder="Select"
                                  isClearable={false}
                                value={sendFebric}
                                onChange={(e) => setSendFebric(e)}
                                />
                              </div>
                        </div>
                        <div className='col-md-3'>
                        <div className="form-group mb-0">
                        <label htmlFor="fname">Deals</label>
                              <Select
                                options={dealCat ? dealCat.map((dataItem) => ({
                                  value: dataItem.deals_id,
                                  label: dataItem.type_of_price,
                                })) : [{ value: "", label: "Select" }]}
                                placeholder="Select"
                                isClearable={false}
                              value={sendDeals}
                              onChange={(e) => setSendDeals(e)}
                              />
                        </div>
                        </div>
                        <div className='col-md-3'>
                        <div className="form-group mb-0">
                        <label htmlFor="fname">Range</label>
                                <Select
                                  options={rangeCat ? rangeCat.map((dataItem) => ({
                                    value: dataItem.pricerange_id,
                                    label: dataItem.range_of_price,
                                  })) : [{ value: "", label: "Select" }]}
                                  placeholder="Select"
                                value={sendRange}
                                onChange={(e) => setSendRange(e)}
                                />
                        </div>
                        </div>
                        <div className='col-md-3'>
                        <div className="form-group mb-0">
                          <label htmlFor="fname">Showing Products</label>
                          <Select className='w-100'
                            options={range ? range.map((dataItem) => ({
                              value: dataItem.value,
                              label: dataItem.label,
                            })) : [{ label: "Select", value: "" }]}
                            placeholder="Select"
                            isClearable={false}
                            value={sendRange22}
                            onChange={(e) => handleRange(e)}
                          />
                        </div>
                      </div>
                       
                     

                        
                      </div>
                      <table id="user-list-table" className="table table-striped table-bordered mt-4" role="grid" aria-describedby="user-list-page-info">
                        <thead>
                          <tr>
                            <th>Image</th>
                            <th>Product Name</th>
                            <th>Description</th>
                            <th>Discount</th>
                            <th>Price</th>
                            <th>Status</th>
                            {/* <th>Create by</th> */}
                            <th>Create at</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>

                          {productListReducer && productListReducer.data.length > 0 ? productListReducer.data.map((i) =>
                            <tr>
                              <td className="text-center"><img className="rounded-circle img-fluid avatar-40" src={i.images[0] ? i.images[0].image : null} alt="profile" /></td>
                              <td>{i.product_name}</td>
                              <td>{i.description}</td>
                              <td>{i.discount ? i.discount : "-"}</td>
                              <td>{i.price}</td>
                              <td>
                                <div className="switch-container">
                                  <label className="switch-label">
                                    <input
                                      type="checkbox"
                                      key={i.id}
                                      className="switch-input"
                                      checked={i.status}
                                      defaultChecked={i.status}
                                      onClick={(e) => toggleSwitch(e, i.product_id , i)}
                                    />
                                    <span className="switch-slider"></span>
                                  </label>
                                </div>

                              </td>
                              {/* <td> Admin</td> */}
                              <td>{i.created_at}</td>

                              <td>
                                <div className="flex align-items-center list-user-action">
                                  <a data-toggle="tooltip" data-placement="top" title="" data-original-title="Edit" onClick={() => history('/ProductEdit', { state: i })}><i className="ri-pencil-line point"></i></a>
                                </div>
                              </td>

                             
                            </tr>

                            

                          ) : <tr className='bg-white border-0'> <td colSpan={8} className='border-0'> <div className='col-md-12 w-100 mt-4'><p className='text-center'>No Data Found</p> </div></td></tr>}


                        </tbody>
                      </table>
                    </div>

                    {/* {(() => {
                                const pages = [];

                                for (let i = 1; i < (pagesListReducer.count / 20) + 1; i++) {
                                  pages.push(<li className={page == i ? "page-item active" : "page-item"} onClick={() => { Apicall((i - 1) * 20); setpage(i); setIndexCh(indexCh + 1) }}><a className="page-link" >{i}  </a>  </li>)
                                }

                                return pages;
                              })()} */}
                    {/* {pagesListReducer ? pagesListReducer.count > 0 ? */}

                    <div className="row justify-content-between mt-3">
                      <div id="user-list-page-info" className="col-md-6">
                        {
                          productListReducer && productListReducer.count ?
                        <span>Showing {page <= 1 ? 1 : (page - 1) * 5 + 1} to {page * 5 <= productListReducer && productListReducer.count ? page * 5 : productListReducer && productListReducer.count}  of {productListReducer ? productListReducer.count : ""} entries</span>

                        : "" }
                      </div>
             

                      {/* {productListReducer && productListReducer.data.length > 0 ? */}
                        <div className="col-md-6">
                          <nav aria-label="Page navigation example">
                            <ul className="pagination justify-content-end mb-0">
                              <li className={`page-item point ${currentPage === 1 ? 'disabled' : ''}`}>
                                <a className="page-link" onClick={() => handlePageChange(currentPage - 1, "less")} tabIndex="-1" aria-disabled="true">
                                  Previous
                                </a>
                              </li>

                              {renderPagination()}

                              <li className={`page-item point ${currentPage === totalPages ? 'disabled' : ''}`}>
                                <a className="page-link" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                                  Next
                                </a>
                              </li>
                            </ul>
                          </nav>
                        </div>

                        {/* : ""} */}


                    </div>
                    {/* : null : null} */}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>



    </>
  )
}

export default List