import React, { useEffect, useState } from 'react';
import Sidebar from '../../Sidebar/Sidebar';
// import { CKEditor } from 'ckeditor4-react';
import CKEditor from 'react-ckeditor-component';
import { ReactComponent as CloseSvg } from '../../../Assets/Images/icons8-no.svg';
import { dealsCategoryAdd_API, dealsCategoryEdit_API, dispatchLoadingStatus, ourTeamAdd_API, ourTeamEdit_API, pagesAdd_API, pagesEdit_API } from '../../../API/Action/globalAction';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom"
import Loader from '../../Loader/Loader';
import swal from 'sweetalert';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

function Add(props) {

  const [propsValue, setPropsValue] = useState();
  const [startDate, setStartdate] = useState();
  const [endDate, setEndDate] = useState();
  const [startDateError, setStartdateError] = useState(false);
  const [endDateError, setEndDateError] = useState(false);

  const dispatch = useDispatch({

  });
  let history = useNavigate();

  // let statelist = useSelector(state => state.globalReducer.getstatecityReg);

  let htmlin = `<h1 className="fw-bold text-black">Our Story</h1>
  <p className="text-black mt-3 mb-4">Experience the ultimate luxury and comfort with our premium
    linen bedsheet. Crafted from 100% pure linen, it's the perfect addition to elevate your
    sleep experience.Experience the ultimate luxury and comfort with our premium linen bedsheet.
  </p>
  <p className="text-black mt-3 mb-4">Experience the ultimate luxury and comfort with our premium
    linen bedsheet. Crafted from 100% pure linen, it's the perfect addition to elevate your
    sleep experience.Experience the ultimate luxury and comfort with our premium linen bedsheet.
  </p>
  <p className="text-black mt-3 mb-4">Experience the ultimate luxury and comfort with our premium
    linen bedsheet. Crafted from 100% pure linen, it's the perfect addition to elevate your
    sleep experience.Experience the ultimate luxury and comfort with our premium linen bedsheet.
  </p>`;


  const [fname, setFname] = useState('');
  const [department, setDepartment] = useState('');
  const [role, setRole] = useState('');


  const [content, setContent] = useState("");

  const [nameError, setNameError] = useState(false);
  const [nameError2, setNameError2] = useState("");

  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);

  const [selectedFileError, setSelectedFileError] = useState(false);
  const [selectedFileError2, setSelectedFileError2] = useState("");

  const [contentError, setContentError] = useState(false);
  const [contentError2, setContentError2] = useState("");

  const [roleError, setRoleError] = useState(false);
  const [roleError2, setRoleError2] = useState("");

  const [departmentError, setDepartmentError] = useState(false);
  const [departmentError2, setDepartmentError2] = useState("");

  const handleChange = (evt) => {

    let data2 = evt.target.value.trim() == "" ? "" : evt.target.value;
    setContent(data2);

    if (data2) {
      setContentError(false);
      setContentError2("");

    }
    else {
      setContentError(true);
      setContentError2("Please enter description");


    }
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
   
    setSelectedFile2(null);

    if (file) {
      if (
        event.target.files[0].name.toLowerCase().includes("jpg") ||
        event.target.files[0].name.toLowerCase().includes("png") ||
        event.target.files[0].name.toLowerCase().includes("jpeg")) {

          setSelectedFile(file);
          setSelectedFileError2("");
      }
      else {
        setSelectedFileError2("The image must be a file of type: jpg, png, jpeg");

      }
    }
    else {
      setSelectedFileError2("");
    }


  };


  const handleRemoveFile = () => {
    document.getElementById("image").value = "";

    setSelectedFile(null);
    setSelectedFile2(null);
  };
  function handleMessage() {
    swal({
      text: "Are you sure you want to leave this page?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {

          window.location.href = window.location.origin + "/DealsList";

        } else {
          console.log('Cancel press')


        }
      });

  }


  useEffect(() => {
    if (props.data) {
      setPropsValue(props.data);
      setFname(props.data.type_of_price);
      setStartdate(  props.data.start_date ?  new Date(props.data.start_date) : ""  );
      setEndDate(props.data.end_date ?  new Date( props.data.end_date) : "");
      setContent(props.data.description ? props.data.description : "" )
      setSelectedFile(props.data.image ? props.data.image : "")


    }
  }, [props])



  let nameRe = /^[a-zA-Z\s]*$/;

  function handleFname(e) {

    let data2 = e.target.value.trim() == "" ? "" : e.target.value;

    setFname(data2);

    if (data2) {

      if (data2.length >= 2) {
        setNameError(false);
       
      }
      else {
        setNameError(true);
      }
    }
    else {
      setNameError(true);
      setNameError2("Please enter Deal");


    }

  }


  function handleSubmit() {

    if (fname) {

      if (fname.length >= 2) {
        setNameError2("");
       
      }
      else {
        setNameError2("Please enter valid deal");

      }
    }
    else {
      setNameError2("Please enter deal");


    }
    

  //  if(startDate){
  //   setStartdateError("")
  //  }
  //  else{
  //   setStartdateError("Please select start date");

  //  }

  //  if(endDate){
  //   setEndDateError("")
  //  }
  //  else{
  //   setEndDateError("Please select end date");

  //  }




    if (nameError == false &&  fname ) {


      let formData = new FormData();
      formData.append('type_of_price', fname);
      formData.append('start_date', startDate ?  moment(startDate).format('MM/DD/YYYY') : "");
      formData.append('end_date', endDate ? moment(endDate).format('MM/DD/YYYY') : "" );
      formData.append('pricedeals_image', selectedFile ? selectedFile : "" );

      formData.append('description', content ? content : "" );


      let data = propsValue ? propsValue.deals_id ? propsValue.deals_id : "" : ""

      if (data) {
        formData.append('deals_id', data);

        dispatch(dealsCategoryEdit_API(formData));
        dispatch(dispatchLoadingStatus(true));

      }

      else {
        dispatch(dealsCategoryAdd_API(formData));
        dispatch(dispatchLoadingStatus(true));
      }




    }
  }
  return (
    <>
    {/* added by parth roka */}

      <div className="wrapper">
        <Loader />
        <Sidebar />


        <div id="content-page" className="content-page">
          <div className="container-fluid">
            <div className="row">

              <div className="col-lg-12">
                <div className="iq-edit-list-data">
                  <div className="tab-content">
                    <div className="tab-pane fade active show" id="personal-information" role="tabpanel">
                      <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between">
                          <div className="iq-header-title">
                            <h4 className="card-title"> {propsValue ? propsValue.deals_id ? "Deal Edit" : "Deal Add" : "Deal Add"}</h4>
                          </div>

                          <ul class="d-flex mt-3">
                            <li class="breadcrumb-item"><a href="/OurTeamList">Deal List</a></li>
                            <li class="breadcrumb-item active" aria-current="page">{propsValue ? propsValue.deals_id ? "Deal Edit" : "Deal Add" : "Deal Add"}</li>
                          </ul>
                        </div>
                        <div className="iq-card-body">

                          <div className=" row">


                            <div className="form-group col-sm-6">
                              <label htmlFor="fname">Deal :</label>
                              <input type="text" className="form-control" value={fname}  onChange={(e) => handleFname(e)} />
                              <p className='errorr'>{nameError2}</p>
                            </div>
                            <div className="form-group col-sm-3">
                              <label htmlFor="fname">Start Date :</label>
                              {/* <input type="text" className="form-control" value={department} disabled={props && props.data ? props.data.page_title ? true : false : false} onChange={(e) => handleDepartment(e.target.value)} /> */}

                              <DatePicker
                                className="form-control w-100"
                                dateFormat="MM/dd/yyyy"
                                onChange={(value) => { setStartdate(value); setStartdateError(false) }}
                                selected={startDate ? startDate : null}
                                placeholderText={"MM/DD/YYYY"}
                                showMonthDropdown
                                showYearDropdown
                                scrollableYearDropdown
                                yearDropdownItemNumber={moment().year() - 1900}
                                minDate={moment().toDate()}
                                maxDate={endDate}


                              />
                              {/* {startDateError && <span style={{ "color": "#BE312E" }}>Please select start date</span>} */}

                            </div>
                            <div className="form-group col-sm-3">
                              <label htmlFor="fname">End Date:</label>
                              <DatePicker
                                className="form-control w-100"
                                dateFormat="MM/dd/yyyy"
                                onChange={(value) => { setEndDate(value); console.log("end" , value); ;setEndDateError(false) }}
                                selected={endDate ? endDate : null}
                                placeholderText={"MM/DD/YYYY"}
                                showMonthDropdown
                                showYearDropdown
                                scrollableYearDropdown
                                yearDropdownItemNumber={moment().year() - 1900}
                                minDate={startDate ? startDate : moment().toDate()}

                              />
                              {/* {endDateError && <span style={{ "color": "#BE312E" }}>Please select end date</span>} */}
                            </div>


                            <div className="form-group col-sm-6">
                              <div className="wrap">
                                <label className="form-label">Upload Image:</label>
                                <form >
                                  <div className="file">
                                    <div className="file__input" id="file__input">
                                      <input className="file__input--file point"
                                        type="file"
                                        id="image"
                                        onChange={handleFileChange}
                                      />
                                      <label className="file__input--label" accept='.png, .jpg .jpeg' htmlFor="customFile" data-text-btn="Choose Files">Add image:</label>
                                    </div>
                                  </div>

                                </form>
                                {/* <p className='errorr'>{selectedFileError2}</p> */}


                                {selectedFile && (
                                  <div className="choosed-files" style={{position:'relative'}}>
                                    {/* <p>Selected File: {selectedFile.name}</p> */}
                                    <img
                                      src={typeof selectedFile === 'string' ? selectedFile :  URL.createObjectURL(selectedFile)  }
                                      alt="Selected Preview"
                                      style={{ maxWidth: '100%', maxHeight: '200px' }}
                                    />

                                    <CloseSvg className='point' width={18} height={18} onClick={() => handleRemoveFile()}  style={{zIndex:'99', position:'absolute', right:'8px', top:'8px'}}/>

                                  </div>
                                )}

                              </div>

                            </div>

                            <div class="form-group col-sm-12">
                              <label>Description:</label>
                              <textarea class="form-control" rows="5" value={content} onChange={(e) => handleChange(e)}
                                style={{ lineHeight: '22px' }}>

                              </textarea>
                              {/* <p className='errorr'>{contentError2}</p> */}

                            </div>







                          </div>
                          <button className="btn btn-primary mr-2" onClick={() => handleSubmit()} >Submit</button>
                          <button className="btn iq-bg-danger" onClick={() => handleMessage()}>Cancel</button>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>

    </>
  )
}

export default Add