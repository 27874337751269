import React from 'react'

function Reset() {
  return (
    <>
      <section class="main-forget-pw sign-in-page bg-white">
    <div class="login-container">
      <form class="login-form">
        <h1>Reset Password</h1>
        <p>Please Fill the details to forget your password</p>
        <div class="input-group">
          <label class="label">Password</label>
          <input type="text" id="email" name="email" placeholder="Enter Password" required/>
        </div>
        <div class="input-group">
          <label class="label">Confirm Password</label>
          <input type="text" id="email" name="email" placeholder="Enter  Confirm  Password" required/>
        </div>

        <button type="submit" class="btn btn-primary float-right">Submit</button>
        <div class="bottom-text">
          <p>Don't have an account? <a href="#">Sign Up</a></p>

        </div>
      </form>
    </div>
  </section>
    
    </>
  )
}

export default Reset