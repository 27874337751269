import React from 'react'

export default function Otp() {
    return (

        <>
  <section class="main-forget-pw sign-in-page bg-white">
    <div class="login-container">
      <form class="login-form">
        <h1>OTP</h1>
        <p>Enter Verification Code that we have sent</p>
        <div class="input-group">
          <div class="d-flex input-otp">
            <input type="text" class="text-center" id="email" name="email" placeholder="-" required/>
            <input type="text" class="text-center" id="email" name="email" placeholder="-" required/>
            <input type="text" class="text-center" id="email" name="email" placeholder="-" required/>
            <input type="text" class="text-center" id="email" name="email" placeholder="-" required/>
          </div>
        </div>

        <button type="submit" class="btn btn-primary float-right">Submit</button>
        <div class="bottom-text">
          <p><a href="#">Resend</a></p>

        </div>
      </form>
    </div>

  </section>

        </>
    )
}
