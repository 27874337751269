import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../Assets/Images/Logo.png";
import {
  Login_API,
  dispatchLoadingStatus,
} from "../../API/Action/globalAction";
import checkImg from "../../Assets/Images/checked.svg";
import Loader from "../Loader/Loader";
import { FaEye, FaEyeSlash } from "react-icons/fa";

function Login() {
  
  let userRem = localStorage.getItem("sendEmail");
  let passwordRem = localStorage.getItem("password");
  console.log("get", passwordRem);
  const [email, setEmail] = useState(userRem);
  const [password, setPassword] = useState(passwordRem);
  const [emailError, setEmailError] = useState("");

  const [passwordError, setPasswordError] = useState("");

  const [eyes, setEyes] = useState(false);
  const [remember, setRemember] = useState(false);

  const toggleBtn = () => {
    setEyes((preState) => !preState);
  };

  const dispatch = useDispatch({});

  let history = useNavigate();

  let AdminLoginReg = useSelector((state) => state.globalReducer.LoginReducer);
const ids = AdminLoginReg
console.log("ids",ids);
  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }


 
  // const handleEmail = (e) => {
  //   setEmail(e.target.value.trim() == "" ? "" : e.target.value);
  //   if (e.target.value) {
  //     if (!isValidEmail(email)) {
  //       setEmailError(<p className="errorr">Email not valid</p>);
  //     } else {
  //       setEmailError("");
  //     }
  //   } else {
  //     setEmailError(<p className="errorr">Email is required</p>);
  //   }
  // };


  const handleEmail = (e) => {
    setEmail(e.target.value);
    if (e.target.value) {
        if(!isValidEmail(e.target.value)){
            setEmailError(<p className='errorr'>Email not valid</p>)
        }
        else{
            setEmailError("")
        }
    }
    else{
        setEmailError(<p className='errorr'>Email is required</p>);
    }
}

  const handlePassword = (e) => {
    setPassword(e.target.value);

    if (!e.target.value) {
      setPasswordError(<p className="error">Password is required</p>);
    } else {
      setPasswordError("");
    }
  };


  

  function handleSubmit() {
    
   
    if (email) {
      if (!isValidEmail(email)) {
        setEmailError(<p className="errorr">Email not valid</p>);
      }
      else {
        setEmailError("");
      }
    }
    else {
      // alert("hii")
      setEmailError(<p className="errorr">Email is required</p>);
    }

    if (!password) {
      setPasswordError(<p className="errorr">Password is required</p>);
    } else {
      setPasswordError("");
    }

    if (email && password) {
      // history("/PagesList")

      localStorage.setItem("sendEmail", remember ? email : "");
      localStorage.setItem("password", remember ? password : "");
      localStorage.setItem("isremembersus", remember);
      
      let formData = new FormData();

      formData.append("email", email);
      formData.append("password", password);
      
      dispatch(dispatchLoadingStatus(true));
      dispatch(Login_API(formData, email));
    }
  }

  useEffect(() => {
    localStorage.removeItem("resendValue");
    
    localStorage.removeItem("emailAdmin");
    localStorage.removeItem("adminemail");
    localStorage.removeItem("otp_id");
    localStorage.removeItem("admin_id");
    localStorage.removeItem("token");

    const token = localStorage.getItem("token");

    if (token) {
      if (!window.location.href.includes("Freelancers")) {
        window.location.href = window.location.origin + "/Freelancers";
      }
    } else {
      if (!window.location.href.includes("/Login")) {
        window.location.href = window.location.origin + "/Login";
      }
      var isremembersus = localStorage.getItem("isremembersus");
      if (isremembersus) {
        setEmail(localStorage.getItem("sendEmail"));
        setPassword(localStorage.getItem("password"));
      } else {
        setEmail("");
        setPassword("");
      }
      setRemember(
        localStorage.getItem("isremembersus") == "true" ? true : false
      );
    }
  }, []);
  useEffect(() => {
    if (AdminLoginReg) {

      history("/HomeList");
    }
  }, [AdminLoginReg]);

  return (

    //added by parth roka 
    <section className="sign-in-page bg-white">
      <Loader />
      <div className="container-fluid p-0">
        <div className="row no-gutters">
          <div className="col-sm-6 align-self-center">
            <div className="sign-in-from">
              <h1 className="mb-0">Sign in</h1>
              <p>
                Enter your email address and password to access admin panel.
              </p>
              <div className="mt-4">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Email address</label>
                  <input
                    type="email"
                    value={email}
                    onChange={handleEmail}
                    className="form-control mb-0"
                    id="exampleInputEmail1"
                    placeholder="Enter email"
                  />
                  {emailError}
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputPassword1">Password</label>
                  <a href="/ForgetPassword" className="float-right">
                    Forgot password?
                  </a>
                  <div style={{ position: "relative" }}>
                    <input
                      type={eyes ? "text" : "password"}
                      className="form-control mb-0"
                      id="exampleInputPassword1"
                      placeholder="Password"
                      value={password}
                      onChange={handlePassword}
                    />
                    <button
                      style={{ position: "absolute", top: "6px", right: "0" }}
                      className="btn "
                      type="button"
                      id="password-addon"
                      onClick={toggleBtn}
                    >
                      {eyes ? (
                        <FaEye style={{ width: "18", height: "18" }} />
                      ) : (
                        <FaEyeSlash style={{ width: "18", height: "18" }} />
                      )}
                    </button>
                  </div>
                  {passwordError}
                </div>
                <div className="d-inline-block w-100">
                  <div className="custom-control custom-checkbox d-inline-block mt-2 pt-1">
                    {remember ? (
                      <img
                        onClick={() => setRemember(!remember)}
                        className="form-check-input"
                        src="images/checked.svg"
                        style={{ height: 15, width: 15 }}
                      />
                    ) : (
                      <img
                        onClick={() => setRemember(!remember)}
                        className="form-check-input"
                        src="images/checkbox-empty.svg"
                        style={{ height: 15, width: 15 }}
                      />
                    )}
                    <label
                      onClick={() => setRemember(!remember)}
                      htmlFor="customCheck1"
                    >
                      Remember Me
                    </label>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary float-right"
                    onClick={() => handleSubmit()}
                  >
                    Sign in
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 text-center">
            <div
              className="sign-in-detail text-white"
              style={{
                background: "url(images/login/2.jpg) no-repeat 0 0",
                backgroundSize: "cover",
              }}
            >
              {/* <a className="sign-in-logo mb-5" href="#"><img src="images/logo-white.png" className="img-fluid" alt="logo"/></a> */}
              <div
                className=""
                data-autoplay="true"
                data-loop="true"
                data-nav="false"
                data-dots="true"
                data-items="1"
                data-items-laptop="1"
                data-items-tab="1"
                data-items-mobile="1"
                data-items-mobile-sm="1"
                data-margin="0"
              >
                <div className="item">
                  <img
                    src="images/front main.png"
                    className="img-fluid mb-4"
                    alt="logo"
                  />
                  <h4 className="text-white" style={{ marginBottom: "10px" }}>
                    {" "}
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  </h4>
                  <p>
                    {" "}
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  </p>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Login;
