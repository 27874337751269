import React, { useEffect, useState } from 'react';
import Sidebar from '../Sidebar/Sidebar';
// import { CKEditor } from 'ckeditor4-react';
import CKEditor from 'react-ckeditor-component';
import { ReactComponent as CloseSvg } from '../../Assets/Images/icons8-no.svg';
import { dispatchLoadingStatus, pagesAdd_API, productAdd_API, getAllCategoriesTitle_API, getfabricCategory_API, getDeals_API, getPriceRange_API, productEdit_API, productdetails_API, product_image_deleteReducer_API, product_size_add_API, product_size_edit_API } from '../../API/Action/globalAction';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom"
import Loader from '../Loader/Loader';
import swal from 'sweetalert';
import Select from 'react-select';
import { product_size_add_URL } from '../../OtherFiles/Constant';
import Deleteicon from "../../Assets/Images/ic-delete.svg"
import Group57 from "../../Assets/Images/group-57.svg"
import imagesAdd from '../../Assets/Images/profile.jpg'

function Add(props) {

    let productdetailsReducer = useSelector(state => state.globalReducer.productdetailsReducer);
    let productEditReducer = useSelector(state => state.globalReducer.productEditReducer);
    console.log("productEditReducer ", productEditReducer && productEditReducer);


    // useEffect(() => {
    //     if(productEditReducer){
    //         if(productEditReducer.errorcode === 3){
    //             swal({
    //                 text: productEditReducer.message,
    //                 icon: "warning",
    //                 buttons: true,
    //                 dangerMode: true,
    //             })
    //                 .then((willDelete) => {
    //                     if (willDelete) {
    //                         setRemember(true);
    //                         handleSubmit();

    //                     }
    //                     else{
    //                         setRemember(false);
    //                     }
    //                 });

    //         }
    //     }

    // },[productEditReducer])

    function handleTry() {
        swal({
            text: "Are you sure you want to leave this page?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    setRemember(true);
                    handleSubmit();


                }
                else {
                    setRemember(false);
                }
            });
    }
    const [fields, setFields] = useState([{ colorName: '', colorCode: '', base64: null, id: "" }]);


    useEffect(() => {
        console.log("field.fileobj", fields);

    }, [fields])

    console.log("fields", fields && fields);
    const [errors, setErrors] = useState([{ colorName: '', colorCode: '', base64: '' }]);

    const addField = () => {
        setFields([...fields, { colorName: '', colorCode: '', base64: null }]);
        setErrors([...errors, { colorName: '', colorCode: '', base64: '' }]);
    };


    const [totalImage, setTotalImage] = useState([{

        color_code
            :
            "#FFFFFF",
        color_name
            :
            "DefaultColor",
        image
            :
            "https://thumbs.dreamstime.com/b/chain-link-moray-11095813.jpg?w=768",
        productimage_id
            :
            225
    }, {
        color_code
            :
            "#FFFFFF",
        color_name
            :
            "DefaultColor",
        image
            :
            "https://thumbs.dreamstime.com/b/teamwork-bees-bridge-gap-bee-swarm-making-chain-to-combine-two-parts-one-metaphor-business-community-55767925.jpg?w=768",
        productimage_id
            :
            226
    }]);

    const removeField = (index) => {
        const newFields = [...fields];
        const removedField = newFields.splice(index, 1)[0];
        setFields(newFields);

        // Update totalImage by removing the corresponding entry
        const newTotalImage = totalImage.filter((item) => item.colorCode !== removedField.colorCode);
        setTotalImage(newTotalImage);
    };

    const removeImage = (index, field) => {
        const newFields = [...fields];
        newFields[index]['base64'] = null;
        newFields[index]['image'] = null;

        setFields(newFields);

        // Update totalImage by removing the image from the corresponding entry
        // const newTotalImage = totalImage.map((item) => {
        //     if (item.colorCode === field.colorCode) {
        //         return { ...item, base64: null, image: null };
        //     }
        //     return item;
        // });
        // setTotalImage(newTotalImage);
    };
    const handleInputChange = (index, key, value) => {
        // Simple validation for colorName and colorCode (non-empty, no spaces)
        const newErrors = [...errors];
        if (key === 'colorName' || key === 'colorCode') {
            if (value.trim() === '') {
                newErrors[index][key] = `${key.charAt(0).toUpperCase() + key.slice(1)} cannot be empty`;
            } else if (/\s/.test(value)) {
                newErrors[index][key] = `${key.charAt(0).toUpperCase() + key.slice(1)} cannot contain spaces`;
            } else {
                newErrors[index][key] = '';
            }
        }

        setErrors(newErrors);

        const newFields = [...fields];
        newFields[index][key] = value;
        setFields(newFields);
    };

    const handleFileChange = (index, file) => {
        // Validate image file format
        const allowedFormats = ['.png', '.jpg', '.jpeg'];
        const fileFormat = file.name.slice(((file.name.lastIndexOf(".") - 1) >>> 0) + 2);

        const newErrors = [...errors];
        if (!allowedFormats.includes('.' + fileFormat.toLowerCase())) {
            newErrors[index]['base64'] = 'Invalid image format. Please select a .png, .jpg, or .jpeg file.';
            setErrors(newErrors);

            // Do not update state if validation fails
            return;
        }

        newErrors[index] = newErrors[index] || {};
        newErrors[index]['base64'] = '';

        setErrors(newErrors);

        const newFields = [...fields];
        newFields[index] = newFields[index] || {};
        newFields[index]['fileobj'] = file;

        debugger

        getBase64(file, (base64Img) => {
            newFields[index]['base64'] = base64Img;
        });


        setFields(newFields);
    };

    function getBase64(file, callback) {
        var reader = new FileReader();
        reader.onloadend = function () {
            callback(reader.result.split(",")[1])
        }
        reader.readAsDataURL(file);
    }

    // const handleSubmit = () => {

    //   // Validate all fields on submit
    //   const newErrors = fields.map((field, index) => {
    //     const errorsForField = {};

    //     if (field.colorName.trim() === '') {
    //       errorsForField['colorName'] = 'Color Name cannot be empty';
    //     } else if (/\s/.test(field.colorName)) {
    //       errorsForField['colorName'] = 'Color Name cannot contain spaces';
    //     } else {
    //       errorsForField['colorName'] = '';
    //     }

    //     if (field.colorCode.trim() === '') {
    //       errorsForField['colorCode'] = 'Color Code cannot be empty';
    //     } else if (/\s/.test(field.colorCode)) {
    //       errorsForField['colorCode'] = 'Color Code cannot contain spaces';
    //     } else {
    //       errorsForField['colorCode'] = '';
    //     }

    //     if (field.image) {
    //       const allowedFormats = ['.png', '.jpg', '.jpeg'];
    //       const fileFormat = field.image.name.slice(((field.image.name.lastIndexOf(".") - 1) >>> 0) + 2);

    //       if (!allowedFormats.includes('.' + fileFormat.toLowerCase())) {
    //         errorsForField['image'] = 'Invalid image format. Please select a .png, .jpg, or .jpeg file.';
    //       } else {
    //         errorsForField['image'] = '';
    //       }
    //     } else {
    //       errorsForField['image'] = 'Please select an image';
    //     }

    //     return errorsForField;
    //   });

    //   setErrors(newErrors);

    //   // If there are no errors, you can proceed with further actions
    //   if (newErrors.every((errorsForField) => Object.values(errorsForField).every((error) => error === ''))) {
    //     // Do something with the valid data
    //     console.log('Form is valid. Proceed with further actions.');
    //   }
    // };

    useEffect(() => {
        if (productdetailsReducer) {

            let data3 = productdetailsReducer.data[0].images.map(({ color_code, color_name, image, productimage_id }) => ({
                colorCode: color_code || '',
                colorName: color_name || '',
                image: image || null,
                productimage_id: productimage_id || ''
            }));

            // Check if totalImage has changed before updating state
            if (JSON.stringify(data3) !== JSON.stringify(fields)) {
                setFields(data3);
            }

            console.log("split(',')", productdetailsReducer && productdetailsReducer.data[0].amazon_link.split(','));

            let data7 = productdetailsReducer && productdetailsReducer.data[0].amazon_link.split(',');

            debugger
            let data4 = data7 && data7.map(i => ({
                Link: i || ''
            }));

            console.log("data4", data4 && data4);
            if (data4) {
                setSendLink(data4);

            }

        }
    }, [productdetailsReducer]);


    // ///////////////////////
    const [remember, setRemember] = useState(false);

    const [propsValue, setPropsValue] = useState();


    const dispatch = useDispatch({

    });
    let history = useNavigate();
    const [allCategory, setAllCategory] = useState();
    const [febricCategory, setFebricCategory] = useState();
    const [dealCategory, setDealCategory] = useState();
    const [rangeCategory, setRangeCategory] = useState();
    const [productDetails, setProductDetails] = useState();


    console.log("productDetails", productDetails && productDetails);
    const [productName, setProductName] = useState();
    const staticText = "$";
    const [price, setPrice] = useState("");
    const [link, setLink] = useState();
    const [sendFebric, setSendFebric] = useState();
    const [sendDeals, setSendDeals] = useState();
    const [sendRange, setSendRange] = useState();
    const [sendDes, setSendDes] = useState();

    console.log("sendDeals", sendDeals ? sendDeals : "");


    const [fname, setFname] = useState('');
    const [content, setContent] = useState("");
    const [profuctError, setProductError] = useState(false);
    const [productError2, setProductError2] = useState("");

    const [selectedFile, setSelectedFile] = useState([]);

    const [sendImages, setSendImages] = useState([]);

    console.log("imaghes", selectedFile && selectedFile);
    const [selectedFile2, setSelectedFile2] = useState(null);

    const [selectedFileError, setSelectedFileError] = useState(false);
    const [selectedFileError2, setSelectedFileError2] = useState("");

    const [priceError, setPriceError] = useState(false);
    const [priceError2, setPriceError2] = useState("");

    const [linkError, setLinkError] = useState(false);
    const [linkError2, setLinkError2] = useState("");

    const [febricError, setFebricError] = useState(false);
    const [febricError2, setFebricError2] = useState("");

    const [dealError, setDealError] = useState(false);
    const [dealError2, setDealError2] = useState("");

    const [sendSize, setSize] = useState("");
    console.log("sendSize ", sendSize && sendSize);

    const [sendSize2, setSize2] = useState("");


    const [sizeError, setSizeError] = useState(false);
    const [sizeError2, setSizeError2] = useState("");

    const [sizeError3, setSizeError3] = useState(false);
    const [sizeError4, setSizeError4] = useState("");



    const [rangeError, setRangeError] = useState(false);
    const [rangeError2, setRangeError2] = useState("");

    const [desError, setDesError] = useState(false);
    const [desError2, setDesError2] = useState("");

    let getAllCategoriesTitleReducer = useSelector(state => state.globalReducer.getAllCategoriesTitleReducer);
    let getfabricCategoryReducer = useSelector(state => state.globalReducer.getfabricCategoryReducer);
    let getDealsReducer = useSelector(state => state.globalReducer.getDealsReducer);
    let getPriceRangeReducer = useSelector(state => state.globalReducer.getPriceRangeReducer);
    let product_image_deleteReducer = useSelector(state => state.globalReducer.product_image_deleteReducer);
    let product_size_addReducer = useSelector(state => state.globalReducer.product_size_addReducer);
    let product_size_editReducer = useSelector(state => state.globalReducer.product_size_editReducer);

    const [imagePreview, setImagePreview] = useState([]);

    const [fileNames, setFileNames] = useState([]);
    const [filePreviews, setFilePreviews] = useState([]);

    const [val, setVal] = useState([{ colorName: "", colorCode: "", image: null }]);
    const [sendLink, setSendLink] = useState([{ Link: "" }]);

    console.log("sendLink", sendLink && JSON.stringify(sendLink));

    const handleLinkInputChange = (e, index) => {

        const { name, value } = e.target;
        const list = [...sendLink];

        list[index][name] = value;


        if (name === 'Link') {
            list[index]['Linkerr'] = value ? "" : "Please enter color name";

        }

        setSendLink(list);

    }

    const handleLinkRemove = (index) => {
        const list = [...sendLink];
        if (index > -1) {
            list.splice(index, 1);
        }
        setSendLink(list);
    }

    const handleLinkClick = () => {

        setSendLink([...sendLink, { Link: "" }]);


    }

    const hasValidationErrors = () => {
        return sendLink.some((link) => !link.Link);
    };
    useEffect(() => {
        if (val) {
            if (val.length == 0) {
                setVal([{ colorName: "", colorCode: "" }])
            }
        }
    }, [val])

    console.log("valval", val ? val : "nooo");



    const handleRemove = (index) => {
        const list = [...val];
        if (index > -1) {
            list.splice(index, 1);
        }
        setVal(list);
    }

    const handleClick = () => {

        setVal([...val, { colorName: "", colorCode: "", image: null }]);


    }


    // let data = allCategory ? allCategory : [];

    // let data2 = febricCategory ? febricCategory : [];

    // const indexOfTypeOfFabric = data.findIndex(
    //   (item) => item.categoryTitle === "Type of Fabric"
    // );

    // if (indexOfTypeOfFabric !== -1) {
    //   data[indexOfTypeOfFabric].data2 = data2;
    // }

    // console.log(data);


    useEffect(() => {
        if (getAllCategoriesTitleReducer) {
            if (getAllCategoriesTitleReducer.success == true) {
                setAllCategory(getAllCategoriesTitleReducer.data);

            }
            else {
                setAllCategory([]);
            }

        }
    }, [getAllCategoriesTitleReducer])


    useEffect(() => {
        if (getfabricCategoryReducer) {
            if (getfabricCategoryReducer.errorcode == 0) {
                setFebricCategory(getfabricCategoryReducer.data);

            }
            else {
                setFebricCategory([]);

            }

        }

    }, [getfabricCategoryReducer])

    useEffect(() => {
        if (getPriceRangeReducer) {
            if (getPriceRangeReducer.errorcode == 0) {


                setRangeCategory(getPriceRangeReducer.data);

            }
            else {
                setRangeCategory([]);

            }

        }

    }, [getPriceRangeReducer])

    useEffect(() => {
        if (getDealsReducer) {
            if (getDealsReducer.errorcode == 0) {
                setDealCategory(getDealsReducer.data);

            }
            else {
                setDealCategory([]);

            }

        }

    }, [getDealsReducer])

    useEffect(() => {
        if (product_image_deleteReducer) {
            if (product_image_deleteReducer.errorcode == 0) {

                APICall()
            }


        }

    }, [product_image_deleteReducer])

    useEffect(() => {
        if (product_size_editReducer) {
            if (product_size_editReducer.errorcode == 0) {
                setSize([{ label: "Select", value: "" }]);
                setLabelValue(false);
                dispatch(product_size_add_API());
            }


        }

    }, [product_size_editReducer])

    useEffect(() => {
        if (productdetailsReducer) {
            let data = getfabricCategoryReducer ? getfabricCategoryReducer.data.filter((i) => i.fabricCategory_id == productdetailsReducer.data[0].fabricCategory_id) : "";

            let data2 = getDealsReducer ? getDealsReducer.data.filter((i) => i.deals_id == productdetailsReducer.data[0].deals_id) : "";
            setProductName(productdetailsReducer.data[0].product_name);
            setPrice(productdetailsReducer.data[0].price.replace('$', ''));
            setLink(productdetailsReducer.data[0].amazon_link);
            setSendFebric([{ label: data ? data[0].type_of_category : "", value: data ? data[0].fabricCategory_id : "" }]);
            setSendDeals([{ label: data2 ? data2[0].type_of_price : "", value: data2 ? data2[0].deals_id : "" }]);
            setSendDes(productdetailsReducer.data[0].description);
            let data3 = productdetailsReducer.data[0].images.map(({ color_code, color_name }) => ({
                colorCode: color_code,
                colorName: color_name,
            }));
            setVal(data3 ? data3 : val)
            let data5 = product_size_addReducer ? product_size_addReducer.data.filter((i) => i.product_size_id == productdetailsReducer.data[0].product_size) : "";

            let data6 = productdetailsReducer ? productdetailsReducer.data[0].product_size : "";
            let data7 = data6 && data6.split(',').map(Number)
            let options = product_size_addReducer ? product_size_addReducer.data.filter(i => data7.includes(i.product_size_id)) : ""
            console.log("data5", options && options);
            setRemember(productdetailsReducer.data[0].features == null ? false : true);

            setSize(options && options.map((i) => ({ label: i.product_size, value: i.product_size_id })))

            setProductDetails(productdetailsReducer.data);

        }
        else {
            setProductDetails([]);

        }

    }, [productdetailsReducer])




    // useEffect(() => {
    //   if (props) {
    //     if (props.data) {
    //       setSelectedFile(props.data.image);
    //       setProductName(props.data.product_name)
    //       setPrice(props.data.price);
    //       setLink(props.data.amazon_link);
    //       setRemember(props.data.features == null ? false : true);

    //       setSendDes(props.data.description)

    //       let data = getfabricCategoryReducer ? getfabricCategoryReducer.data.filter((i) => i.fabricCategory_id == props.data.fabricCategory_id) : "";

    //       let data2 = getDealsReducer ? getDealsReducer.data.filter((i) => i.deals_id == props.data.deals_id) : "";
    //       let data3 = getPriceRangeReducer ? getPriceRangeReducer.data.filter((i) => i.pricerange_id == props.data.pricerange_id) : "";
    //       console.log("ddd", data);

    //       setSendFebric([{ label: data ? data[0].type_of_category : "", value: data ? data[0].fabricCategory_id : "" }]);

    //       setSendDeals([{ label: data2 ? data2[0].type_of_price : "", value: data2 ? data2[0].deals_id : "" }]);

    //     }
    //   }

    // }, [props])


    function handleMessage() {
        swal({
            text: "Are you sure you want to leave this page?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {

                    window.location.href = window.location.origin + "/ProductList";

                }
            });

    }


    useEffect(() => {

        dispatch(getAllCategoriesTitle_API());
        dispatch(getfabricCategory_API());
        dispatch(getDeals_API());
        dispatch(getPriceRange_API());
        dispatch(product_size_add_API());

    }, [])
    useEffect(() => {
        if (props.data) {
            let formData = new FormData();
            setPropsValue(props.data);

            formData.append('product_id', props ? props.data.product_id : "");
            dispatch(dispatchLoadingStatus(true));
            dispatch(productdetails_API(formData));
        }
    }, [props])

    function APICall() {
        let formData = new FormData();

        formData.append('product_id', props ? props.data.product_id : "");
        dispatch(dispatchLoadingStatus(true));
        dispatch(productdetails_API(formData));
    }

    function handleAddSize() {

        if (sendSize2) {
            let formData = new FormData();

            formData.append('product_size', sendSize2);
            dispatch(dispatchLoadingStatus(true));
            dispatch(product_size_edit_API(formData));
        }

    }


    // useEffect(() => {
    //   if (totalImage ) {
    //     if(totalImage.length > 0 && !selectedFile.length){
    //          // Map totalImage data to the format expected by setSelectedFile
    //     const preFilledFiles = totalImage.map(item => ({
    //       name: item.color_name,
    //       preview: item.image,
    //       id: item.productimage_id, // Add an identifier if needed
    //     }));
    //     debugger

    //     setSelectedFile(preFilledFiles);
    //     }

    //   }
    // }, [totalImage, selectedFile]);


    const handleRemoveFile = (idToRemove) => {
        const updatedFiles = selectedFile && selectedFile.filter((_, index) => index !== idToRemove);
        setSelectedFile(updatedFiles);
    };

    const handleRemoveFile2 = (indexToRemove) => {
        let formData = new FormData();

        formData.append('productimage_id', indexToRemove);
        dispatch(dispatchLoadingStatus(true));

        dispatch((product_image_deleteReducer_API(formData)))
    };


    let nameRe = /^[a-zA-Z\s]*$/;
    // let phoneRe = /^[0-9\b]+$/;
    const phoneRe = /^[0-9$]+$/;
    const productNameRegex = /^[a-zA-Z0-9\s]+$/;

    function handleProductName(e) {
        let data = e.target.value;
        let data2 = e.target.value.trim() == "" ? "" : e.target.value;
        setProductName(productNameRegex.test(data2) ? data2 : "");

        if (data) {

            if (data.length >= 2) {
                setProductError(false);
                setProductError2("");
                if (!productNameRegex.test(data)) {
                    setProductError(true);
                    setProductError2("Please enter valid product name");

                }
                else {
                    setProductError(false);
                    setProductError2("");

                }
            }
            else {
                setProductError(true);
                setProductError2("Please enter valid product name");

            }
        }
        else {
            setProductError(true);
            setProductError2("Please enter product name");
        }

    }

    function handleDeal(e) {
        setSendDeals(e);


        if (e) {
            setDealError(false);
        }
        else {
            setDealError(true);

        }
    }

    const [labelValue, setLabelValue] = useState(false);
    console.log("labelValue", labelValue && labelValue);
    function handleSize(e) {

        const selectedValues = Array.isArray(e) ? e : [e];

        // Check if 'Add' is among the selected values
        const isAddSelected = selectedValues.some(item => item.value === 'Add');

        // Update labelValue based on 'Add' selection
        setLabelValue(isAddSelected);

        // If 'Add' is selected, clear the size and return
        if (isAddSelected) {
            setSize([{ label: 'Add Size', value: 'Add' }]);
            setSizeError(false);
            return;
        }
        else {
            setSize(e);

        }





        if (e && e.length > 0) {

            setSizeError(false);
        }
        else {
            setSizeError(true);

        }
    }

    function handleSize2(e) {
        setSize2(e.target.value);


        if (e.target.value) {
            setSizeError3(false);
        }
        else {
            setSizeError3(true);

        }
    }


    function handleDes(e) {
        let data = e.target.value;
        setSendDes(e.target.value.trim() == "" ? "" : e.target.value);

        if (data) {
            setDesError(false);
        }
        else {
            setDesError(true);

        }
    }

    function handleFebric(e) {
        setSendFebric(e);


        if (e) {
            setFebricError(false);
        }
        else {
            setFebricError(true);

        }
    }

    function handlePrice(e) {
        let data = e.target.value;
        let data2 = e.target.value.trim() == "" ? "" : e.target.value;
        let data3 = phoneRe.test(data2) ? data2 : "";

        setPrice(data3.replace(/^\$/, ''));

        if (data) {


            if (!phoneRe.test(data)) {
                setPriceError(true);
                setPriceError2("Please enter valid price");


            }
            else {
                setPriceError(false);
                setPriceError2("");

            }

        }
        else {
            setPriceError(true);
            setPriceError2("Please enter price");

        }
    }
    function handleRang(e) {
        setSendRange(e);


        if (e) {
            setRangeError(false);
        }
        else {
            setRangeError(true);

        }
    }

    function handleLink(e) {
        let data = e.target.value;
        setLink(e.target.value.trim() == "" ? "" : e.target.value);

        if (data) {
            setLinkError(false);
        }
        else {
            setLinkError(true);

        }
    }


    // function handleSubmit() {

    //   const newErrors = fields.map((field, index) => {
    //     const errorsForField = {};

    //     if (field.colorName.trim() === '') {
    //       errorsForField['colorName'] = 'Color Name cannot be empty';
    //     } else if (/\s/.test(field.colorName)) {
    //       errorsForField['colorName'] = 'Color Name cannot contain spaces';
    //     } else {
    //       errorsForField['colorName'] = '';
    //     }

    //     if (field.colorCode.trim() === '') {
    //       errorsForField['colorCode'] = 'Color Code cannot be empty';
    //     } else if (/\s/.test(field.colorCode)) {
    //       errorsForField['colorCode'] = 'Color Code cannot contain spaces';
    //     } else {
    //       errorsForField['colorCode'] = '';
    //     }

    //     if (field.image) {
    //       const allowedFormats = ['.png', '.jpg', '.jpeg'];
    //       const fileFormat = field.image.name.slice(((field.image.name.lastIndexOf(".") - 1) >>> 0) + 2);

    //       if (!allowedFormats.includes('.' + fileFormat.toLowerCase())) {
    //         errorsForField['image'] = 'Invalid image format. Please select a .png, .jpg, or .jpeg file.';
    //       } else {
    //         errorsForField['image'] = '';
    //       }
    //     } else {
    //       errorsForField['image'] = 'Please select an image';
    //     }

    //     return errorsForField;
    //   });

    //   setErrors(newErrors);

    //   if (productName) {

    //     if (productName.length >= 2) {
    //       setProductError2("");
    //       if (!nameRe.test(productName)) {
    //         setProductError2("Please enter valid product name");

    //       }
    //       else {
    //         setProductError2("");

    //       }
    //     }
    //     else {
    //       setProductError2("Please enter product name");

    //     }
    //   }
    //   else {
    //     setProductError2("Please enter product");
    //   }

    //   if (price) {

    //     setPriceError2("");

    //     if (!phoneRe.test(price)) {
    //       setPriceError2("Please enter valid price");

    //     }
    //     else {
    //       setPriceError2("");

    //     }


    //   }
    //   else {
    //     setPriceError2("Please enter price");
    //   }

    //   if (selectedFile) {
    //     setSelectedFileError2("");

    //   }
    //   else {
    //     setSelectedFileError2("Please select image");


    //   }



    //   if (sendDeals) {
    //     setDealError2("");
    //   }
    //   else {
    //     setDealError2("Please select deals");

    //   }

    //   if (sendFebric) {
    //     setFebricError2("");
    //   }
    //   else {
    //     setFebricError2("Please select type of fabric");

    //   }

    //   if (sendDes) {
    //     setDesError2("");
    //   }
    //   else {
    //     setDesError2("Please enter description");

    //   }

    //   if (sendRange) {
    //     setRangeError2("");
    //   }
    //   else {
    //     setRangeError2("Please select range");

    //   }

    //   if (sendDeals) {
    //     setFebricError2("");
    //   }
    //   else {
    //     setFebricError2("Please select type of fabric");

    //   }

    //   if (sendSize) {
    //     setSizeError2("");
    //   }
    //   else {
    //     setSizeError2("Please select size");

    //   }

    //   if (sendSize2) {
    //     setSizeError4("");
    //   }
    //   else {
    //     setSizeError4("Please enter size");

    //   }

    //   const spaceRegex = /^[A-Za-z]( ?[A-Za-z] ?)*$/;
    //   const colorCodeRegex = /#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})\b/g;

    //   const color = {
    //     color: '#BE312E'
    //   }


    //   const list = [...sendLink];
    //   const formError = hasValidationErrors()
    //     ? 'Please fix validation errors before submitting.'
    //     : '';

    //   list.forEach((link) => {
    //     link.Linkerr = link.Link ? '' : 'Please enter a value for Link';
    //   });

    //   setSendLink(list);
    //   console.log("finalll", selectedFile && selectedFile.map((i) => i.File))




    //   if (!profuctError && !priceError && !linkError && !febricError && !dealError && !desError && !selectedFileError &&

    //     productName && price && sendDeals && sendDes && sendFebric && selectedFile && !formError ) {

    //     if (sendSize && sendSize.value == "Add") {
    //       if (!sizeError3 && sendSize2) {
    //         APICall2();
    //       }
    //     }
    //     else {
    //       if (!sizeError && sendSize) {
    //         APICall2();

    //       }

    //     }

    //   }
    // }


    function handleSubmit() {

        const newErrors = fields.map((field, index) => {
            const errorsForField = {};

            if (field.colorName.trim() === '') {
                errorsForField['colorName'] = 'Please enter color name';
            } else if (/\s/.test(field.colorName)) {
                errorsForField['colorName'] = 'Color Name cannot contain spaces';
            } else {
                errorsForField['colorName'] = '';
            }

            if (field.colorCode.trim() === '') {
                errorsForField['colorCode'] = 'Please enter color code ';
            } else if (/\s/.test(field.colorCode)) {
                errorsForField['colorCode'] = 'Color Code cannot contain spaces';
            } else {
                errorsForField['colorCode'] = '';
            }

            console.log("base64", field.base64);

            if (field.base64) {
                errorsForField['base64'] = '';

            }
            else if (field.image) {
                console.log("field.image", field.image);
            }

            else {
                errorsForField['base64'] = 'Please select an image';
            }

            return errorsForField;
        });

        setErrors(newErrors);
        const list = [...sendLink];
        const formError = hasValidationErrors()
            ? 'Please fix validation errors before submitting.'
            : '';

        list.forEach((link) => {
            link.Linkerr = link.Link ? '' : 'Please enter a value for Link';
        });

        setSendLink(list);
        console.log("finalll", selectedFile && selectedFile.map((i) => i.File))


        if (productName) {

            if (productName.length >= 2) {
                setProductError2("");
                if (!nameRe.test(productName)) {
                    setProductError2("Please enter valid product name");

                }
                else {
                    setProductError2("");

                }
            }
            else {
                setProductError2("Please enter product name");

            }
        }
        else {
            setProductError2("Please enter product");
        }

        if (price) {

            setPriceError2("");

            if (!phoneRe.test(price)) {
                setPriceError2("Please enter valid price");

            }
            else {
                setPriceError2("");

            }


        }
        else {
            setPriceError2("Please enter price");
        }

        if (selectedFile) {
            setSelectedFileError2("");

        }
        else {
            setSelectedFileError2("Please select image");


        }

        if (link) {
            setLinkError2("");
        }
        else {
            setLinkError2("Please enter link");
        }

        if (sendDeals) {
            setDealError2("");
        }
        else {
            setDealError2("Please select deals");

        }

        if (sendFebric) {
            setFebricError2("");
        }
        else {
            setFebricError2("Please select type of fabric");

        }

        if (sendDes) {
            setDesError2("");
        }
        else {
            setDesError2("Please enter description");

        }

        if (sendRange) {
            setRangeError2("");
        }
        else {
            setRangeError2("Please select range");

        }

        if (sendDeals) {
            setFebricError2("");
        }
        else {
            setFebricError2("Please select type of fabric");

        }

        if (sendSize) {
            setSizeError2("");
        }
        else {
            setSizeError2("Please select size");

        }

        if (sendSize2) {
            setSizeError4("");
        }
        else {
            setSizeError4("Please enter size");

        }

        let data = fields

        if (!profuctError && !priceError && !linkError && !febricError && !dealError && !desError && !selectedFileError &&

            productName && price && sendDeals && sendFebric && !formError && newErrors.every((errorsForField) => Object.values(errorsForField).every((error) => error === ''))) {

            if (labelValue == true) {
                if (!sizeError3 && sendSize2) {
                    APICall2();
                }
            }
            else {
                if (!sizeError && sendSize) {
                    APICall2();

                }

            }

        }
        else {
            debugger
        }
    }

    function APICall2() {
        let formData = new FormData();


        console.log("image", fields && JSON.stringify(fields && fields));

        formData.append('description', sendDes);
        formData.append('product_name', productName);
        formData.append('price', staticText + price);
        formData.append('amazon_link', sendLink && sendLink.map((i) => i.Link));
        formData.append('deals_id', sendDeals ? sendDeals.value : "");
        formData.append('fabricCategory_id', sendFebric ? sendFebric.value : "");
        formData.append('features', remember ? remember == true ? 1 : "" : "");
        // formData2.append('features2', remember ? remember == true ? 1 : "" : "");

        formData.append('colorInfo', fields && JSON.stringify(fields && fields));
        formData.append('product_size', sendSize ? sendSize.map((i) => i.value) : "");


        if (propsValue) {

            if (propsValue.fabricCategory_id) {
                let formData2 = new FormData();

                formData2.append('description', sendDes);
                formData2.append('product_name', productName);
                formData2.append('price', staticText + price);
                formData2.append('amazon_link', sendLink && sendLink.map((i) => i.Link));
                formData2.append('deals_id', sendDeals.value ? sendDeals.value : sendDeals[0].value);
                formData2.append('fabricCategory_id', sendFebric.value ? sendFebric.value : sendFebric[0].value);
                formData2.append('product_id', propsValue ? propsValue.product_id : "");
                formData2.append('features', remember ? remember == true ? 1 : "" : "");
                // formData2.append('features2', remember ? remember == true ? 1 : "" : "");
                formData2.append('colorInfo', fields && JSON.stringify(fields && fields));
                formData2.append('product_size', sendSize ? sendSize.map((i) => i.value) : "");



                let dataLink = sendLink && sendLink.map((i) => i.Link)
                let dataMain = sendSize ? sendSize.map((i) => i.value) : ""
                let sendAPI2 = {
                    "description": sendDes,
                    "product_name": productName,
                    "price": staticText + price,
                    "amazon_link": dataLink && dataLink.join(','),
                    "deals_id": sendDeals ? sendDeals.value : "",
                    "fabricCategory_id": sendFebric ? sendFebric.value : "",
                    "colorInfo": fields ,
                    "product_size": dataMain && dataMain.join(','),
                    "features": remember ? remember == true ? 1 : "" : "",
                    "product_id": propsValue ? propsValue.product_id : ""
                }


                dispatch(productEdit_API(sendAPI2));
                dispatch(dispatchLoadingStatus(true));

            }
            else {
                dispatch(productAdd_API(formData));
                dispatch(dispatchLoadingStatus(true));

            }
        }
        else {

            let dataLink = sendLink && sendLink.map((i) => i.Link);
            let dataMain  = sendSize ? sendSize.map((i) => i.value) : ""

            let sendAPI = {
                "description": sendDes,
                "product_name": productName,
                "price": staticText + price,
                "amazon_link": dataLink && dataLink.join(','),
                "deals_id": sendDeals ? sendDeals.value : "",
                "fabricCategory_id": sendFebric ? sendFebric.value : "",
                "colorInfo": fields ,
                "product_size": dataMain && dataMain.join(','),
                "features": remember ? remember == true ? 1 : "" : ""
            }
            dispatch(productAdd_API(sendAPI));
            dispatch(dispatchLoadingStatus(true));

        }
    }

    return (
        <>
            <div className="wrapper">
                <Loader />
                <Sidebar />


                <div id="content-page" className="content-page">
                    <div className="container-fluid">
                        <div className="row">

                            <div className="col-lg-12">
                                <div className="iq-edit-list-data">
                                    <div className="tab-content">
                                        <div className="tab-pane fade active show" id="personal-information" role="tabpanel">
                                            <div className="iq-card">
                                                <div className="iq-card-header d-flex justify-content-between">
                                                    <div className="iq-header-title">
                                                        <h4 className="card-title"> {propsValue ? propsValue.fabricCategory_id ? "Product Edit" : "Product Add" : "Product Add"}</h4>
                                                    </div>

                                                    <ul class="d-flex mt-3">
                                                        <li class="breadcrumb-item"><a href="/ProductList">Product List</a></li>
                                                        <li class="breadcrumb-item active" aria-current="page">{propsValue ? propsValue.fabricCategory_id ? "Product Edit" : "Product Add" : "Product Add"}</li>
                                                    </ul>
                                                </div>
                                                <div className="iq-card-body">

                                                    <div className="row">
                                                        <div className="form-group col-sm-6">
                                                            <label htmlFor="fname">Product Name:</label>
                                                            <input type="text" className="form-control" maxLength={100} placeholder='Enter  Product Name' value={productName} onChange={(e) => handleProductName(e)} minLength={3} />
                                                            <p className='errorr'>{productError2}</p>
                                                        </div>

                                                        <div className="form-group col-sm-6">
                                                            <label htmlFor="fname">Price:</label>
                                                            <input type="text" className="form-control" maxLength={12} value={staticText + price} onChange={(e) => handlePrice(e)} />
                                                            <p className='errorr'>{priceError2}</p>
                                                        </div>


                                                        <div className="form-group col-sm-6">
                                                            <label htmlFor="fname">Type of Fabric:</label>
                                                            <Select
                                                                options={febricCategory ? febricCategory.map((dataItem) => ({
                                                                    value: dataItem.fabricCategory_id,
                                                                    label: dataItem.type_of_category,
                                                                })) : [{ label: "Select", value: "" }]}
                                                                placeholder="Select"
                                                                isClearable={false}
                                                                value={sendFebric}
                                                                onChange={(e) => handleFebric(e)}
                                                            />
                                                            <p className='errorr'>{febricError2}</p>
                                                        </div>

                                                        <div className="form-group col-sm-6">
                                                            <label htmlFor="fname">Deals:</label>
                                                            <Select
                                                                options={dealCategory ? dealCategory.map((dataItem) => ({
                                                                    value: dataItem.deals_id,
                                                                    label: dataItem.type_of_price,
                                                                })) : [{ value: "", label: "Select" }]}
                                                                placeholder="Select"
                                                                isClearable={false}
                                                                value={sendDeals}
                                                                onChange={(e) => handleDeal(e)}
                                                            />



                                                            <p className='errorr'>{dealError2}</p>

                                                        </div>

                                                        <div className="form-group col-sm-6">
                                                            <label htmlFor="fname">Size:</label>
                                                            <Select
                                                                isMulti={labelValue && labelValue === true ? false : true}
                                                                // [{ label: 'Select', value: '' }].concat(
                                                                options={product_size_addReducer ? product_size_addReducer.data.map((dataItem) => ({
                                                                    value: dataItem.product_size_id,
                                                                    label: dataItem.product_size,
                                                                })).concat([{ label: 'Add Size', value: 'Add' }]) : [{ value: "", label: "Select" }]}
                                                                placeholder="Select"
                                                                isClearable={false}
                                                                value={sendSize}
                                                                onChange={(e) => handleSize(e)}
                                                            />
                                                            <p className='errorr'>{sizeError2}</p>

                                                            {labelValue && labelValue === true ?
                                                                <>
                                                                    <div className='my-3'>
                                                                        <input type="text" className="form-control" value={sendSize2} onChange={(e) => handleSize2(e)} />
                                                                        <p className='errorr'>{sizeError4}</p>
                                                                    </div>
                                                                    <div class="btn-div-more">
                                                                        <button className="btn btn-primary d-block" onClick={() => handleAddSize()}  >
                                                                            Add Size
                                                                        </button>
                                                                    </div>
                                                                </>
                                                                : ""}

                                                        </div>

                                                        <div className="form-group col-sm-6">
                                                            <label htmlFor="fname">Store Link:</label>

                                                            {sendLink.map((i, index) => {
                                                                return (
                                                                    <>
                                                                        <div className='d-flex mb-3'>
                                                                            <input type="text" style={{width : '86%'}} className="form-control " placeholder='Enter Store Link' name='Link' value={i.Link} onChange={(e) => { handleLinkInputChange(e, index) }} maxLength={2048}/>


                                                                            {sendLink.length > 1 &&

                                                                                <img className='delete-icn delete-icons' style={{ cursor: 'pointer' }}
                                                                                    src={Deleteicon}
                                                                                    alt="ic-delete"
                                                                                    onClick={() => handleLinkRemove(index)}

                                                                                />

                                                                            }

                                                                            {sendLink.length - 1 === index &&


                                                                                <img className='ml-1 delete-icn' onClick={handleLinkClick} style={{ cursor: 'pointer' }}
                                                                                    src={Group57}
                                                                                    alt="ic-delete"
                                                                                />
                                                                            }
                                                                        </div>
                                                                        {i.Linkerr && <p className='errorr'>{i.Linkerr}</p>}

                                                                    </>
                                                                )
                                                            })}

                                                        </div>



                                                    </div>

                                                    {fields.map((field, index) => (
                                                        <div className='row'>
                                                            {field.image || field.fileobj ? <div className='col-md-2'>

                                                                {field.fileobj ? (
                                                                    <div className='profile-image mb-3 '>

                                                                        <img className='avatar-130 img-fluid' src={URL.createObjectURL(field.fileobj)} alt="Selected" style={{ maxWidth: '100px' }} />
                                                                        <a className='cross-btn' onClick={() => removeImage(index, field)} style={{ cursor: 'pointer' }} >
                                                                            <i className='fa fa-close'></i>
                                                                        </a>
                                                                    </div>
                                                                ) : field.image ? (
                                                                    <div className='profile-image mb-3 '>
                                                                        <img className='avatar-130 img-fluid' src={field.image} alt="Selected" style={{ maxWidth: '100px' }} />
                                                                        <a className='cross-btn' onClick={() => removeImage(index, field)} style={{ cursor: 'pointer' }}>
                                                                            <i className='fa fa-close'></i>
                                                                        </a>
                                                                    </div>
                                                                )
                                                                    :

                                                                    "sdvsfs"}

                                                            </div>

                                                                : <div className="col-md-2">
                                                                    <div className="">
                                                                        <label htmlFor="institute" className="form-label">Choose Product*</label>
                                                                        <input
                                                                            className='file-input'
                                                                            name='base64'
                                                                            type="file"
                                                                            accept=".png, .jpg, .jpeg"
                                                                            onChange={(e) => handleFileChange(index, e.target.files[0])}
                                                                        />

                                                                    </div>
                                                                    {errors[index] && errors[index]['base64'] && <div className='errorr'>{errors[index]['base64']}</div>}

                                                                </div>}



                                                            <div className="col-md-2">
                                                                <div className="mb-3">
                                                                    <label htmlFor="institute" className="form-label">Color Name *</label>
                                                                    <input
                                                                        className='form-control'
                                                                        type="text"
                                                                        placeholder="Enter Color Name"
                                                                        value={field.colorName || ''}
                                                                        onChange={(e) => handleInputChange(index, 'colorName', e.target.value)}
                                                                    />
                                                                    {errors[index] && errors[index]['colorName'] && <div className='errorr'>{errors[index]['colorName']}</div>}

                                                                </div>
                                                            </div>


                                                            <div className="col-md-2">
                                                                <div className="mb-3">
                                                                    <label className="form-label">Color Code *</label>

                                                                    <input
                                                                        className='form-control'
                                                                        type="text"
                                                                        placeholder="Enter Color Code"
                                                                        value={field.colorCode || ''}
                                                                        onChange={(e) => handleInputChange(index, 'colorCode', e.target.value)}
                                                                    />
                                                                    {errors[index] && errors[index]['colorCode'] && <div className='errorr'>{errors[index]['colorCode']}</div>}

                                                                </div>
                                                            </div>

                                                            {fields.length > 1 &&

                                                                <div className="mt25 col-md-2">
                                                                    <button className="btn  w-100 btn-danger" type="button" onClick={() => removeField(index)}>
                                                                        <i class="ri-close-line"></i>   Remove
                                                                    </button>

                                                                </div>
                                                            }
                                                            <div className="mt40 col-md-2">
                                                                {fields.length - 1 === index &&
                                                                    <button type="button" className='btn w-100 btn-primary' onClick={addField}>
                                                                        <i class="ri-add-line"></i> Add Color
                                                                    </button>
                                                                }
                                                            </div>
                                                        </div>
                                                    ))}


                                                    <div className="form-group col-sm-12">
                                                        <label>Description:</label>

                                                        <textarea className="form-control" name="address" onChange={handleDes} value={sendDes} rows="5" maxLength={255} placeholder='Enter Description' />
                                                        {desError2 && <p className='errorr'>{desError2}</p>}



                                                    </div>

                                                    <div className="form-group col-sm-12">
                                                        <label ></label>

                                                        {
                                                            remember ?
                                                                <img onClick={() => setRemember(!remember)} className="form-check-input" src="images/checked.svg" style={{ height: 15, width: 15, marginLeft: '0', cursor: 'pointer' }} /> :
                                                                <img onClick={() => setRemember(!remember)} className="form-check-input" src="images/checkbox-empty.svg" style={{ height: 15, width: 15, marginLeft: '0', cursor: 'pointer' }} />
                                                        }
                                                        <label onClick={() => setRemember(!remember)} style={{ marginLeft: '20px' }}>Product as featured ?</label>
                                                    </div>

                                                    <button className="btn btn-primary mr-2" onClick={() => handleSubmit()} >Submit</button>
                                                    <button className="btn iq-bg-danger" onClick={() => handleMessage()}>Cancel</button>
                                                    {/* <button className="btn iq-bg-danger" onClick={()=> handleTry()} >Tryyy</button> */}

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

        </>
    )
}

export default Add