import React, { useState, useRef, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import Login from './Componets/Authentication/Login';
import Inquiry from './Componets/Inquery/List';

import HomeAdd from './Componets/Home/Add';
import HomeEdit from './Componets/Home/Edit';
import HomeList from './Componets/Home/List';

import PagesAdd from './Componets/Our Story/Add';
import PagesList from './Componets/Our Story/List';
import PagesEdit from './Componets/Our Story/Edit';

import ProductAdd from './Componets/Products/Add';
import ProductEdit from './Componets/Products/Edit';
import ProductList from './Componets/Products/List';
import Add2 from './Componets/Products/Add2';



import OurTeamAdd from './Componets/Our Story Team/Add';
import OurTeamList from './Componets/Our Story Team/List';
import OurTeamEdit from './Componets/Our Story Team/Edit';

import LocationAdd from './Componets/Contact/Add';
import ContactEdit from './Componets/Contact/Edit';
import LocationList from './Componets/Contact/List';

import FebricsCategoryAdd from './Componets/Bedsheet/Febrics Category/Add';
import FebricsCategoryEdit from './Componets/Bedsheet/Febrics Category/Edit';
import FebricsCategoryList from './Componets/Bedsheet/Febrics Category/List';

import DealsAdd from './Componets/Bedsheet/Deals/Add';
import DealsEdit from './Componets/Bedsheet/Deals/Edit';
import DealsList from './Componets/Bedsheet/Deals/List';

import PriceRangeAdd from './Componets/Bedsheet/Price Range/Add';
import PriceRangeEdit from './Componets/Bedsheet/Price Range/Edit';
import PriceRangeList from './Componets/Bedsheet/Price Range/List';

import AdminUserManagementAdd from './Componets/Admin User Management/Add';
import AdminUserManagementEdit from './Componets/Admin User Management/Edit';
import AdminUserManagementList from './Componets/Admin User Management/List';

import TestimonialAdd from './Componets/Testimonial/Add';
import TestimonialEdit from './Componets/Testimonial/Edit';
import TestimonialList from './Componets/Testimonial/List';

import HomePageHighlightsList from './Componets/Environment Selection/List'
import HomePageHighlightsEdit from './Componets/Environment Selection/Edit'

import ForgotPassword from './Componets/Authentication/Forgot'
import Otp from './Componets/Authentication/Otp'
import ResetPassword from './Componets/Authentication/ResetPassword';

import Otpp from '../src/Otp';
import Resett from '../src/Reset';
import ForgetPasswordd from '../src/ForgetPassword';




function App() {

  useEffect(() => {

    if (window.location.href == window.location.origin + '/') {
      if (localStorage.getItem('token')) {
        window.location.href = window.location.origin + '/HomeList'
      } else {
        window.location.href = window.location.origin + '/Login'
      }
    }
  }, []);
  return (
    <div className="App">
      <Router>
        <Routes>

          {/* <Route exact path="/Home" element={<Sidebar />} /> */}
          <Route exact path="/Login" element={<Login />} />

          <Route exact path="/HomeAdd" element={<HomeAdd />} />
          <Route exact path="/HomeEdit" element={<HomeEdit />} />
          <Route exact path="/HomeList" element={<HomeList />} />

          <Route exact path="/PagesAdd" element={<PagesAdd />} />
          <Route exact path="/PagesList" element={<PagesList />} />
          <Route exact path="/PagesEdit" element={<PagesEdit />} />

          <Route exact path="/ProductAdd" element={<Add2 />} />
          <Route exact path="/ProductList" element={<ProductList />} />
          <Route exact path="/ProductEdit" element={<ProductEdit />} />


          <Route exact path="/OurTeamAdd" element={<OurTeamAdd />} />
          <Route exact path="/OurTeamList" element={<OurTeamList />} />
          <Route exact path="/OurTeamEdit" element={<OurTeamEdit />} />


          <Route exact path="/LocationAdd" element={< LocationAdd />} />
          <Route exact path="/ContactEdit" element={<ContactEdit />} />
          <Route exact path="/LocationList" element={<LocationList />} />

          <Route exact path="/FabricsCategoryAdd" element={< FebricsCategoryAdd />} />
          <Route exact path="/FabricsCategoryEdit" element={<FebricsCategoryEdit />} />
          <Route exact path="/FabricsCategoryList" element={<FebricsCategoryList />} />

          <Route exact path="/DealsAdd" element={< DealsAdd />} />
          <Route exact path="/DealsEdit" element={<DealsEdit />} />
          <Route exact path="/DealsList" element={<DealsList />} />

          <Route exact path="/PriceRangeAdd" element={< PriceRangeAdd />} />
          <Route exact path="/PriceRangeEdit" element={<PriceRangeEdit />} />
          <Route exact path="/PriceRangeList" element={<PriceRangeList />} />

          <Route exact path="/Inquiry" element={<Inquiry />} />

          {/* Next relese */}
          <Route exact path="/AdminUserManagementAdd" element={< AdminUserManagementAdd />} />
          <Route exact path="/AdminUserManagementEdit" element={<AdminUserManagementEdit />} />
          <Route exact path="/AdminUserManagementList" element={<AdminUserManagementList />} />


          <Route exact path="/TestimonialAdd" element={< TestimonialAdd />} />
          <Route exact path="/TestimonialEdit" element={<TestimonialEdit />} />
          <Route exact path="/TestimonialList" element={<TestimonialList />} />

          <Route exact path="/ForgetPassword" element={<ForgotPassword />} />
          <Route exact path="/Otp" element={<Otp />} />
          <Route exact path="/ResetPassword" element={<ResetPassword />} />
{/* added by mansi */}
         
          <Route exact path="/HomePageHighlightsEdit" element={<HomePageHighlightsEdit />} />
          <Route exact path="/HomePageHighlightsList" element={<HomePageHighlightsList />} />

          <Route exact path="/Otpp" element={<Otpp />} />
          <Route exact path="/Resett" element={<Resett />} />
          <Route exact path="/ForgetPasswordd" element={<ForgetPasswordd />} />

          <Route exact path="/Add2" element={<Add2 />} />




        </Routes>
      </Router>
    </div>
  );
}

export default App;
